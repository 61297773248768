import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class manageService {

    headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');

    public userData: any;
    userId: any;

    constructor(private http: HttpClient) {
        let user = localStorage.getItem('userDetails');
        if (user != null) {
            // let userDetails = JSON.parse(user);
            // this.userData = userDetails;
            // let userId = userDetails.userId.toString();
            // this.headers = this.headers.append('userId', userId);

            this.userData = jwtDecode(user)
            this.userId = this.userData.UserId
            this.headers = this.headers.append('userId', this.userId);
        }
    }

    insertLanguage(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertLanguage`, data, { headers: this.headers })
    }

    PostJobRoles(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/PostJobRoles`, data, { headers: this.headers })
    }

    insertDocument(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDocument`, data, { headers: this.headers })
    }

    insertExperience(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertExpereince`, data, { headers: this.headers })
    }

    InsertPostOffer(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertPostOffer`, data, { headers: this.headers })
    }

    insertQualification(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDegree`, data, { headers: this.headers })
    }

    insertStage(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertStage`, params, { headers: this.headers })
    }

    InsertMaster_StageValue(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertMaster_StageValue`, params, { headers: this.headers })
    }

    insertStatus(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertStatus`, params, { headers: this.headers })
    }

    insertEducation(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertEducation`, params, { headers: this.headers })
    }

    insertjobPost(params) {
        return this.http.post(`${environment.apiUrl}/api/master/InsertJobPost`, params, { headers: this.headers })
    }

    insertJobCategory(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertJobCategory`, params, { headers: this.headers })
    }

    insertlevelofEducation(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertLevelofEducation`, params, { headers: this.headers })
    }

    insertDepartment(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDepartment`, params, { headers: this.headers })
    }

    insertHiringType(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertHiringType`, params, { headers: this.headers })
    }

    insertgrade(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertGrade`, params, { headers: this.headers })
    }

    insertJob(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertJobNames`, params, { headers: this.headers })
    }

    deleteSetting(id, settingName, isActive) {
        return this.http.get(`${environment.apiUrl}/api/Master/DeleteMasterdata?id=${id}&name=${settingName}&isActive=${isActive}`, { headers: this.headers });
    }

    ValidateLanguage(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateLanguage?name=${name}&id=${id}`, { headers: this.headers });
    }
    ValidateDocument(documentData,country,name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateDocumentDetails?docId=${documentData}&countryId=${country}&docName=${name}&id=${id}`, { headers: this.headers });
    }
    ValidateExperience(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateExperience?name=${name}&id=${id}`, { headers: this.headers });
    }

    ValidateEducation(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateEducation?name=${name}&id=${id}`, { headers: this.headers });
    }

    ValidateDegree(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateDegree?name=${name}&id=${id}`, { headers: this.headers });
    }
    ValidateHiringstage(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateHiringstage?name=${name}&id=${id}`, { headers: this.headers });
    }

    validateJobPost(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateJobPost?name=${name}&id=${id}`, { headers: this.headers });
    }

    validateJobCategory(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateJobCategory?name=${name}&id=${id}`, { headers: this.headers });
    }

    InsertHiring(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertHiring`, params, { headers: this.headers })
    }

    ValidateHiring(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateHiring?HiringName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertEmployment(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertEmployment`, params, { headers: this.headers })
    }

    ValidateEmployment(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateEmployment?EmploymentName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertAppointment(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertAppointment`, params, { headers: this.headers })
    }

    ValidateAppointment(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateAppointment?AppointmentName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertIndustry(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertIndustry`, params, { headers: this.headers })
    }

    ValidateIndustry(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateIndustry?IndustryName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertDomain(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDomain`, params, { headers: this.headers })
    }

    ValidateDomain(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateDomain?DomainName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertWorkPermitType(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertWorkPermitType`, params, { headers: this.headers })
    }

    ValidateWorkPermitType(country,name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateWorkPermitType?countryId=${country}&WorkPermitName=${name}&id=${id}`, { headers: this.headers });
    }

    InsertDocumentData(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDocument`, params, { headers: this.headers })
    }

    InsertCheckListType(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertCheckListType`, params, { headers: this.headers })
    }
    InsertDocumentTypeDetail(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertDocumentDetails`, params, { headers: this.headers })
    }
    InsertHiringStage(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertHiringStage`, params, { headers: this.headers })
    }

    InsertJobNames(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertJobNames`, params, { headers: this.headers })
    }

    InsertNationality(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertNationality`, params, { headers: this.headers })
    }

    InsertSkills_Category(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertSkills_Category`, params, { headers: this.headers })
    }

    Insertintervew(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/InsertInterviewQuestionTemplate`, params, { headers: this.headers })
    }

    ValidateNationality(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateNationality?name=${name}&id=${id}`, { headers: this.headers });
    }

    skill_validate(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateSkills_Category?name=${name}&id=${id}`, { headers: this.headers });
    }

    ValidateSkills_Categoy(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateCategoryType?name=${name}&id=${id}`, { headers: this.headers });
    }
    QuestionTemplate(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateInterviewQuestionTemplate?interview=${name}&id=${id}`, { headers: this.headers });
    }

    CreateCategoryType(params) {
        return this.http.post(`${environment.apiUrl}/api/Master/CreateCategoryType`, params, { headers: this.headers })
    }

    getInterviewtempltebyid(status) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewById?id=${status}`, { headers: this.headers })
    }

    GetInterviewQuestionTemplateBy(status) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewQuestionTemplateById?id=${status}`, { headers: this.headers })
    }

    ValidateStatus(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateStatus?statusname=${name}&id=${id}`, { headers: this.headers });
    }

    GetJobsLevelsData(){
        return this.http.get(`${environment.apiUrl}/api/Master/GetJobsLevelsData`,{ headers: this.headers })
    }
    ValidateMapJobTitle(jobid, hid ) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateMapJobTitle?jobtitle=${jobid}&hiringstage=${hid}`, { headers: this.headers });
    }   
    AddHiringStageSubStatus(params:any){
        return this.http.post(`${environment.apiUrl}/api/Master/AddHiringStageSubStatus`, params, { headers: this.headers })
    }
    GetHiringStageSubStatus(){
        return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStageSubStatus`,{ headers: this.headers })
    }

    ValidateJobRole(name, id = 0) {
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateJobRoles?enterJobRoles=${name}&id=${id}`, { headers: this.headers });
    }
    Brandname(data) {
        return this.http.post(`${environment.apiUrl}/api/Master/Brandname/Brandname`, data, { headers: this.headers })
    }
    validateEntityName(brand:any, country:any, campus:any, entityName:any){
        return this.http.get(`${environment.apiUrl}/api/Master/ValidateEntity?brand=${brand}&country=${country}&campus=${campus}&entityname=${entityName}`, { headers: this.headers })
    }
}