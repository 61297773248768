import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { IJPService } from 'src/app/services/IJP.service';
import { ReportsService } from 'src/app/services/reports.service';
import { jwtDecode } from 'jwt-decode';
import { MessageService } from 'primeng/api';
import { jobService } from '../../services/jobservice';



@Component({
  selector: 'app-job-details-page',
  templateUrl: './job-details-page.component.html',
  styleUrls: ['./job-details-page.component.scss'],
  providers: [MessageService],
})
export class JobDetailsPageComponent {
  lastSegment: any;
  trDetails: any;
  schoolDetail: any;
  jobDetail: any;
  countryDetail: any;
  campusDetail: any;
  departmentDetail: any;
  ijpBasicInformation: any;
  nopositionsDetail: any;
  jobcategoryDetail: any;
  priorityDetail: any;
  typeofhiringDetail: any;
  rationalDetail: any;
  typeofemployementDetail: any;
  durationDetail: any;
  joiningdateDetail: any;
  typeofappoinmentDetail: any;
  postjobtoDetail: any = [];
  industryDetail: any = [];
  salaryofferrangeDetail: any;
  publishstartdateDetail: any;
  hiringManagerDetail: any;
  recruitmentmanagername: any;
  trName: any;
  brandName: any;
  titleName: any;
  countryName: any;
  campusName: any;
  departmentName: any;
  numberOfPositions: any;
  jobCategoryname: any;
  priority: any;
  typeofhiringname: any;
  typesofemployment: any;
  employmentDuration: any;
  expectedDateOfJoining: any;
  typeofappointmentname: any;
  rationalForHiringNonBudgetedPosition: any;
  salaryOfferRangeFrom: any;
  totalExperience: any;
  domaindetail: any = [];
  currentSalaryFrom: any;
  preferredHigherEducationname: any;
  preferredQualificationsname: any;
  languageName: any;
  preferredGender: any;
  noticePeriodFrom: any;
  noticePeriodTo: any;
  timeNeededToRelocate: any;
  preferredAgeBarFrom: any;
  preferredAgeBarTo: any;
  documentTypedeatil: any = [];
  hiringmangername: any;
  businesspartnername: any;
  workPermitCountrys: any;
  workPermitname: any;
  workPermitTypes: any;
  ijpJobDescription: any;
  responsibility: any;
  overview: any;
  skillsDescription: any;
  disclaimer: any;
  websiteShortDescription: any;
  ijpjobskill: any[] = [];
  skillCategory: any;
  skillCategories: string;
  skills: string;
  skilldeatil: any = [];
  approverdetail: any = [];
  hiringdetail: any = [];
  targetdetail: any = [];
  targetBrandName: any;
  preferredNationalityname: any;
  levels: any;
  department: any;
  salaryOfferRangeTo: any;
  currentSalaryTo: any;
  salaryOfferRangeSymbol: any;
  currentSalarySymbol: any;
  userDetails: any;
  userId: any;
  status: any; enableReactivate: boolean = false
  ijpJobApprovedStatus: any;
  rejectTextarea: any
  domainExperienceTo: any;
  domainExperienceFrom: any;
  prefferedIndustryExperienceFrom: any;
  prefferedIndustryExperienceTo: any;
  totalExperienceFrom: any;
  totalExperienceTo: any;
  preferredAgeSelecttWo: any;
  preferredAgeSelect: any;



  commaSeperated(data: string): string[] {
    if (data) {
      return data.split(',');
    }
    return [];
  }
  constructor(
    private IJPService: IJPService,
    private router: Router,
    public reportsService: ReportsService,
    private messageService: MessageService,
    public jobService: jobService
  ) { }

  tabsStoredValue: any;
  isIjpEmployeeValue: any;
  routerSubscription: Subscription;

  ngOnInit(): void {
    window.scrollTo(0, 0)
    var url = window.location.href;
    this.lastSegment = url.split('/').pop();
    this.getJobdetails(this.lastSegment);
    this.getJobDetailsById(this.lastSegment)
    let userData = this.reportsService.getUserId();
    this.userDetails = jwtDecode(userData);
    this.userId = this.userDetails.UserId;
    this.tabsStoredValue = localStorage.getItem('selectedTabb');
    // console.log(this.tabsStoredValue, "oooooooooooooooooooooooooooo");
    const isIjpEmployee = localStorage.getItem('isIjpEmployee');
    if (isIjpEmployee) {
      this.isIjpEmployeeValue = isIjpEmployee;
      // this.selectedJobsArray = JSON.parse(isIjpEmployee);
      // console.log(isIjpEmployee, 'jjjjjjjjjjjjjjjjjjjjjjjjj');
    }

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        localStorage.removeItem('isIjpEmployee');
      }
    });

  }

  ngOnDestroy() {
    // Unsubscribe from router events when component is destroyed
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  basicInfoData: any
  getJobDetailsById(ijpJobId: any) {
    this.jobService.GetJobDetailNameById(ijpJobId).subscribe((res: any) => {
      this.basicInfoData = res[0]
      this.trName = this.basicInfoData.trfIdName
      this.brand_IJPJobId = this.basicInfoData.jobIdWithBrand
      this.brandName = this.basicInfoData.brand
      this.titleName = this.basicInfoData.jobTitle
      this.countryName = this.basicInfoData.country
      this.campusName = this.basicInfoData.campusName
      this.department = this.basicInfoData.department
      this.recruitmentmanagername = this.basicInfoData.hiringManagerId
      this.jobCategoryname = this.basicInfoData.category
      this.numberOfPositions = this.basicInfoData.category
      this.typesofemployment = this.basicInfoData.typeofEmployment
      this.priority = this.basicInfoData.priority
      this.jobCategoryname = this.basicInfoData.category
      this.jobCategoryname = this.basicInfoData.category
      this.jobCategoryname = this.basicInfoData.category
      this.jobCategoryname = this.basicInfoData.category

    })
  }


  createdUserId: any;
  enableApproveReject: boolean = false;
  ijpJobStatus: any;
  brand_IJPJobId: any;
  getJobdetails(id: any) {
    this.IJPService.EditIJP(id).subscribe((res) => {

      this.targetdetail = res?.targetAudience
      this.approverdetail = res?.jobapprover

      console.log(res,'ssssssssssssssssssssssssssssss')


      //     if(res!='Reporting Manager not found')
      //     {
      //       // console.log("111111111111",res);
      //       this.ijpJobStatus=res?.ijpBasicInformation?.ijpJobStatus
      //       this.trName=res.ijpBasicInformation.trName
      //       this.brand_IJPJobId=res.ijpBasicInformation.brand_IJPJobId
      //       this.brandName=res.ijpBasicInformation.brandName
      //       this.titleName=res.ijpBasicInformation.titleName
      //       this.countryName=res.ijpBasicInformation.countryName
      //       this.campusName=res.ijpBasicInformation.campusName
      //       this.department=res.ijpBasicInformation.department
      //       this.numberOfPositions=res.ijpBasicInformation.numberOfPositions
      //       this.jobCategoryname=res.ijpBasicInformation.jobCategoryname
      //       this.priority=res.ijpBasicInformation.priority
      //       this.typeofhiringname=res.ijpBasicInformation.typeofhiringname
      //       this.hiringmangername=res.ijpBasicInformation.hiringmangername
      //       this.rationalForHiringNonBudgetedPosition=res.ijpBasicInformation.rationalForHiringNonBudgetedPosition
      //       this.recruitmentmanagername=res.ijpBasicInformation.recruitmentmanagername
      //       this.businesspartnername=res.ijpBasicInformation.businesspartnername
      //       this.typesofemployment=res.ijpBasicInformation.typesofemployment
      //       this.employmentDuration=res.ijpBasicInformation.employmentDuration
      //       this.expectedDateOfJoining=res.ijpBasicInformation.expectedDateOfJoining
      //       this.typeofappointmentname=res.ijpBasicInformation.typeofappointmentname
      //       this.salaryOfferRangeSymbol=res.ijpBasicInformation.salaryOfferRangeSymbol
      //       this.salaryOfferRangeFrom=res.ijpBasicInformation.salaryOfferRangeFrom
      //       this.salaryOfferRangeTo=res.ijpBasicInformation.salaryOfferRangeTo
      //       this.postjobtoDetail=res.ijpBasicInformation.ijpJobPostedData
      //       this.industryDetail=res.ijpBasicInformation.ijpindustry
      //       this.totalExperience=res.ijpBasicInformation.totalExperience
      //       this.currentSalarySymbol=res.ijpBasicInformation.currentSalarySymbol
      //       this.currentSalaryFrom=res.ijpBasicInformation.currentSalaryFrom
      //       this.currentSalaryTo=res.ijpBasicInformation.currentSalaryTo
      //       this.preferredHigherEducationname=res.ijpBasicInformation.preferredHigherEducationname
      //       this.preferredQualificationsname=res.ijpBasicInformation.preferredQualificationsname
      //       this.preferredNationalityname=res.ijpBasicInformation.preferredNationalityname
      //       this.languageName=res.ijpBasicInformation.languageName
      //       this.preferredGender=res.ijpBasicInformation.preferredGender
      //       this.noticePeriodFrom=res.ijpBasicInformation.noticePeriodFrom
      //       this.noticePeriodTo=res.ijpBasicInformation.noticePeriodTo
      //       this.totalExperienceFrom=res.ijpBasicInformation.totalExperienceTo
      //       this.totalExperienceTo=res.ijpBasicInformation.totalExperienceFrom
      //       this.timeNeededToRelocate=res.ijpBasicInformation.timeNeededToRelocate
      //       this.preferredAgeBarFrom=res.ijpBasicInformation.preferredAgeBarFrom
      //       this.preferredAgeBarTo=res.ijpBasicInformation.preferredAgeBarTo
      //       this.documentTypedeatil=res.ijpBasicInformation.ijpdocument
      //       this.domaindetail=res.ijpBasicInformation.ijpdomain
      //       this.domainExperienceFrom=res.ijpBasicInformation.domainExperienceFrom
      //       this.domainExperienceTo=res.ijpBasicInformation.domainExperienceTo
      //       this.prefferedIndustryExperienceFrom=res.ijpBasicInformation.prefferedIndustryExperienceFrom
      //       this.prefferedIndustryExperienceTo=res.ijpBasicInformation.prefferedIndustryExperienceTo
      //       this.workPermitname=res.ijpBasicInformation.workPermitname
      //       this.workPermitCountrys=res.ijpBasicInformation.workPermitCountrys
      //       this.workPermitTypes=res.ijpBasicInformation.workPermitTypes
      //       this.overview=res.ijpJobDescription?.overview
      //       this.skillsDescription=res?.ijpJobDescription?.skillsDescription
      //       this.disclaimer=res?.ijpJobDescription?.disclaimer
      //       this.websiteShortDescription=res?.ijpJobDescription?.websiteShortDescription
      //       this.responsibility=res?.ijpJobDescription?.responsibility
      //       this.ijpjobskill = res?.ijpJobDescription?.ijpjobskill;
      //       this.skillCategories = this.ijpjobskill?.map(skill => skill?.skillCategoryName).join(', ');
      //       this.skills = this.ijpjobskill?.map(skill => skill?.skillsName).join(', ');
      //       this.createdUserId = parseInt(res?.ijpBasicInformation.userId)
      //       this.approverdetail=res.reportingManager?.iJPJobApprover



      //     this.hiringdetail = res?.ijpEmploymentCtcApprover?.iJPHiringTeam;
      //     // console.log(this.documentTypedeatil, 'mmmmmnbvcvbbbbbbbbbbbbbbbb.........................');


          for (let k = 0; k < this.approverdetail?.length; k++) {

            this.ijpJobStatus=this.approverdetail[k]?.jobStatus
            this.ijpJobApprovedStatus=this.approverdetail[k]?.ijpJobApprovedStatus
           const approveMemberId= this.approverdetail[k].approveMemberId


           const approveMemberIdnew=  approveMemberId.split(',');

           for (let i = 0; i < approveMemberIdnew?.length; i++) {
            const currentValue = parseInt(approveMemberIdnew[i]);

            if (
              (currentValue == this.userId &&
                this.approverdetail[k].approvedStatus != 'Approved' &&
                this.ijpJobStatus != '99') ||
              (currentValue == this.userId &&
                this.ijpJobStatus != '99' &&
                this.ijpJobStatus != '97' &&
                this.ijpJobStatus != '96' &&
                this.ijpJobStatus != '0' &&
                this.ijpJobStatus != '95'
              )
            ) {

              this.enableApproveReject = true;
              this.enableReactivate = false;
              // console.log();
            } else if (
              currentValue== this.userId &&
              this.ijpJobStatus == '99' && this.approverdetail[k]?.approvedStatus == 'Rejected'
            ) {
              this.enableApproveReject = false;
              this.enableReactivate = true;
            }
          }

          }

    });

  }

  approvejobpost(lSegment: any) {
    this.IJPService.GetIJPApproval(
      lSegment,
      this.ijpJobApprovedStatus,
      '97'
    ).subscribe((res: any) => {
      // console.log(
      //   lSegment,
      //   this.ijpJobApprovedStatus,
      //   '97',
      //   '...approvejobpost'
      // );
    });
  }
  handleApprove(lastSegment: any) {
    this.showApproveToast();
    this.approvejobpost(lastSegment);
    setTimeout(() => {
      this.router.navigateByUrl('job-listing');
    }, 3000);
  }

  handleReject(lastSegment: any) {
    this.showRejectToast();
    this.visible = false;
    this.rejectjobpost(lastSegment);
    setTimeout(() => {
      this.router.navigateByUrl('job-listing');
    }, 3000);
  }

  rejectjobpost(lastSegment: any) {
    this.IJPService.GetIJPReject(lastSegment, 'Reason', '99').subscribe(
      (res: any) => {
        // console.log(lastSegment, '99', '...rejectjobpost');
      }
    );
  }

  reactivatejob(lastSegment: any) {
    this.IJPService.Reactivate(lastSegment, 'Reactivate', '98').subscribe(
      (res: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'IJP has been Reactivated',
          life: 3000,
        });
        setTimeout(() => {
          this.router.navigateByUrl('job-listing');
          // console.log(lastSegment, '98', '...Reactivate');
        }, 3000);
      }
    );
  }
  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  showRejectToast() {
    this.messageService.add({
      severity: 'error',
      summary: 'Rejected',
      detail: ' IJP has been rejected',
      life: 3000,
    });
  }
  showApproveToast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Approved',
      detail: 'IJP has been approved',
      life: 3000,
    });
  }

  // skills: any;
  skill_list: any[] = [
    { name: 'India', key: 'IN' },
    { name: 'Singapore', key: 'SN' },
    { name: 'OWIS', key: 'OW' },
    { name: 'UAE', key: 'UAE' },
    { name: 'Malaysia', key: 'ML' },
    { name: 'Japan', key: 'JA' },
    { name: 'Cambodia', key: 'CM' },
    { name: 'Dwight', key: 'DW' },
    { name: 'Regent', key: 'RE' },
    { name: 'OWIS Riyadh', key: 'OWR' },
    { name: 'Philippines', key: 'PH' },
    { name: 'OWIS India', key: 'OWI' },
    { name: 'Glendale', key: 'GD' },
  ];
  TRFInfoData: any = [];

  backToListingsPage() {
    window.history.back();
  }

  sortColumn: string = '';
  sortDirection: boolean = true;
  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = true;
    }

    this.hiringdetail.sort((a: any, b: any) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let result = 0;
      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      }
      return this.sortDirection ? result : -result;
    });
  }

  sortColumnn: string = '';
  sortDirectionn: boolean = true;
  sortTablee(column: string) {
    if (this.sortColumnn === column) {
      this.sortDirectionn = !this.sortDirectionn;
    } else {
      this.sortColumnn = column;
      this.sortDirectionn = true;
    }

    this.approverdetail.sort((a: any, b: any) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let result = 0;
      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      }
      return this.sortDirectionn ? result : -result;
    });
  }
}
