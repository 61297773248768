import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild, Injector, OnInit } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonService } from '../services/commonService';
import { candidateService } from '../services/candidateService';
import { jobService } from '../services/jobservice';
import { NgForm } from '@angular/forms';
import { TrfService } from '../services/trf.service';
import { AppComponentBase } from '../shared/app.component-base';
import { NotificationService } from '../services/notification.service';

// import { CKEditor5, ChangeEvent, FocusEvent, BlurEvent } from '@ckeditor/ckeditor5-angular';
@Component({
  selector: 'app-trf-form-two',
  templateUrl: './trf-form-two.component.html',
  styleUrls: ['./trf-form-two.component.scss'],
})
export class TrfFormTwoComponent extends AppComponentBase implements OnInit {
  @ViewChild('basicInfo', { static: false }) basicInfo: NgForm;

  @ViewChild('descriptionForm', { static: false }) descriptionForm: NgForm;

  rafJobTitle = true;
  rafJobDescription = false;
  rafBasicCheck = false;
  rafBasicActive = true;
  rafDescCheck = false;
  rafDescActive = false;
  trfJobTitleMOdal = false;

  viewDisable: boolean = false;

  public Editor = ClassicEditor;
  rftOverView: any;
  trfRequestedDate: any;
  trfNumberPositions: any;
  trfcttc: any;
  trftICc: any;
  trfPriorityOne: any;
  trfPriorityTwo: any;
  TRFInfoData: any = {};

  addTalentReqArray: any = [];
  trfCountriess: any = [];
  trfCampuuss: any = [];
  trfJobTitleArray: any = [];
  trfSkkillsCategory: any = [];
  trfJobTitleArrayy: any = [];
  trfSkillsss: any = [];
  trfrequesterArray: any = [];

  selectedtrfRequester: any;
  selectedtrfBrand: any;
  selectedtrfCountry: any;
  skillCategory: any;
  skills: any;
  selectedtrfJobTitlee: any;
  selectedtrfAppointmentType: any;
  selectedtrfPriority: any;
  selectedtrfCampus: any;
  minStartDate = new Date();

  selectedtrfBudgeted: number;
  trfBudgeted = [
    { id: 1, name: 'Budgeted' },
    { id: 2, name: 'Non-Budgeted' },
  ];
  selectedtrfEmploymentType: number;
  trfEmploymentType = [
    { id: 1, name: 'Full Time' },
    { id: 2, name: 'Part Time' },
    { id: 3, name: 'Contract' },
    { id: 4, name: 'Freelancing' },
  ];
  selectedtrfApprovedBy: number;
  trfApprovedBy = [
    { id: 1, name: 'Approver 1' },
    { id: 2, name: 'Approver 2' },
    { id: 3, name: 'Approver 3' },
    { id: 4, name: 'Approver 4' },
  ];
  selectedtrfBBrand: number;
  trfrequesterBrand = [
    { id: 1, name: 'Brand 1' },
    { id: 2, name: 'Brand 2' },
    { id: 3, name: 'Brand 3' },
    { id: 4, name: 'Brand 4' },
  ];
  Caategory: number;
  trfCaategory = [
    { id: 1, name: 'Academic' },
    { id: 2, name: 'Non-Academic' },
  ];
  talentRequisition: any = {};
  trfJobDescription: any = {};
  id: any;
  sub: any;

  trfViewbuttons: boolean = false;
  trfNormalButtons: boolean = true;
  titleError: string;
  title: any;
  validateTitle: any;
  jahId: any;
  jahIds: any;
  degree: any;
  Education_details: Object;
  disableEdit: boolean = false;
  Appointment_data: any;

  constructor(
    private myService: commonService,
    private router: Router,
    public route: ActivatedRoute,
    private candService: candidateService,
    public trfservice: TrfService,
    private jobService: jobService,
    public commonService: commonService,
    private notifyService: NotificationService,
    injector: Injector
  ) {
    super(injector);
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.TRFInfoData.jobskill = [
      {
        skillCategory: null,
        skills: null,
        skill_list: [],
      },
    ];
    this.talentRequisition = {};
    this.trfJobDescription = {};

    let view = this.route.snapshot.routeConfig.path;
    if (view == 'view-trf/:id') {
      this.viewDisable = true;
      this.trfViewbuttons = true;
      this.trfNormalButtons = false;
    }
  }
  ngOnInit() {
    this.trfBrandd();
    this.trfCountries();
    this.trfCampuss();
    this.trfJobTitleData();
    this.trfSkillsCategorry();
    this.trfskills();
    this.trfRequesteerr();
    this.getDegree();
    this.getLevelOfEducation();
    let date = new Date();
    this.GetCategoryType();
    this.getCategories();
    this.gethyringType();
    this.getEmployementType();
    this.brandDetails();
    setTimeout(() => {
      if (parseInt(this.id) > 0) {
        this.edittrfInfo();
      }
    }, 500);
    setTimeout(() => {
      if (parseInt(this.id) > 0) {
        this.viewtrfInfo();
      }
    }, 500);
    this.getCurrencyCodes()
    this.getAppointmentType()
  }
  jadId: any;
  viewtrfInfo() {
    this.trfservice
      .GetTalentRequisitionById(parseInt(this.id))
      .subscribe((res: any) => {
        // this.trfservice.GetBrandDetailsByUserId(res.requester).subscribe((res) => {
        //   this.brandsnewarray = []
        //   this.resVal = res
        //   this.brandsnewarray.push(this.resVal);
        // });
        if (res) {
          if (this.id) {
            // var countries = this.brands.filter(
            //   (x) => x.brandId == res.talentRequisition.brand
            // );
            // if (countries && countries[0]?.country?.length > 0) {
            this.getcountries(res.talentRequisition.brand);
            // }

            // var getCampus = this.trf_Country.filter(
            //   (x) => x.countryId == res.talentRequisition.country
            // );
            // if (
            //   getCampus &&
            //   getCampus[0]?.countryId &&
            //   getCampus[0]?.campus.length > 0
            // ) {
            //   this.getCampus(getCampus[0].countryId);
            // }
            this.getCampus(res.talentRequisition.country);

            this.TRFInfoData.jobskill = res.trfJobDescription.trfSkills;
            if (this.TRFInfoData.jobskill) {
              let skills = this.TRFInfoData.jobskill;
              for (let i = 0; i < skills.length; i++) {
                this.GetSkillNameById(skills[i].skillCategory, i);
                let skillName = skills[i].skills;
                if (skillName.indexOf(',') > -1) {
                  let arraydoc = skillName.split(',');
                  let skillnumarray = arraydoc.map(Number);
                  skills[i].skills = skillnumarray;
                } else {
                  let singleDoc = skillName.split(' ');
                  let skillnumarray = singleDoc.map(Number);
                  skills[i].skills = skillnumarray;
                }
              }
            }
            setTimeout(() => {
              var campusIds = [];
              var campuses = res.talentRequisition.campus?.split(',');

              if (campuses?.length > 0) {
                campuses = campuses.forEach((element) => {
                  campusIds.push(parseInt(element));
                });
                res.talentRequisition.campus = campusIds;
              }
              let expectedJoiningDate = this.convert(
                res.talentRequisition.expectedJoiningDate
              );
              if (res.talentRequisition.higherLevelOfEducation == 0) {
                res.talentRequisition.higherLevelOfEducation = null;
              } else {
                res.talentRequisition.higherLevelOfEducation =
                  res.talentRequisition.higherLevelOfEducation;
              }
              res.talentRequisition.expectedJoiningDate = expectedJoiningDate;
              this.jadId = res.talentRequisition.jahId;
              // var JobTitleId = res.talentRequisition.jahJobTitleId?.split(',');
              // if (JobTitleId?.length > 0) {
              //   JobTitleId.forEach((element) => {
              //     JobTitleId = parseInt(element);
              //   });
              //   res.talentRequisition.jahJobTitleId = JobTitleId;
              // }
              res.talentRequisition.jahJobTitleId = res.talentRequisition.jahId;

              if (res.talentRequisition.qualification) {
                let qualificationArray = res.talentRequisition.qualification;
                if (qualificationArray.indexOf(',') > -1) {
                  let arraydoc = qualificationArray.split(',');
                  let numberArray = arraydoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                } else {
                  let singleDoc = qualificationArray.split(' ');
                  let numberArray = singleDoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                }
              }
              this.talentRequisition = res.talentRequisition;
              this.trfJobDescription = res.trfJobDescription;

              if (this.talentRequisition.budOrNonBud) {
                this.talentRequisition.budOrNonBud =
                  +this.talentRequisition.budOrNonBud;
              }
            }, 200);
          }
        }
      });
  }

  scrollToTop(){
    window.scroll(0,0)
  }

  edittrfInfo() {
    this.trfservice
      .GetTalentRequisitionById(parseInt(this.id))
      .subscribe((res: any) => {
        if (res) {
          this.disableEdit=true
          // console.log(res, 'ffffffffffffffffffffffffffff')
          if (this.id) {
            console.log(res.talentRequisition.requester, 'reque');
            var obj = { userId: res.talentRequisition.requester };
            this.GetBrandDetailsByUserId(obj);
            // var countries = this.brandsnewarray.filter(
            //   (x) => x.brandId == res.talentRequisition.brand
            // );
            // console.log(countries,"===========================")
            // if (countries && countries[0]?.country?.length > 0) {
            this.getcountries(res.talentRequisition.brand);
            // }

            // var getCampus = this.trf_Country.filter(
            //   (x) => x.countryId == res.talentRequisition.country
            // );
            // if (
            //   getCampus &&
            //   getCampus[0]?.countryId &&
            //   getCampus[0]?.campus.length > 0
            // ) {
            this.getCampus(res.talentRequisition.country);
            // }

            this.TRFInfoData.jobskill = res.trfJobDescription.trfSkills;
            if (this.TRFInfoData.jobskill) {
              let skills = this.TRFInfoData.jobskill;
              for (let i = 0; i < skills.length; i++) {
                this.GetSkillNameById(skills[i].skillCategory, i);
                let skillName = skills[i].skills;
                if (skillName.indexOf(',') > -1) {
                  let arraydoc = skillName.split(',');
                  let skillnumarray = arraydoc.map(Number);
                  skills[i].skills = skillnumarray;
                } else {
                  let singleDoc = skillName.split(' ');
                  let skillnumarray = singleDoc.map(Number);
                  skills[i].skills = skillnumarray;
                }
              }
            }
            setTimeout(() => {
              var campusIds = [];
              var campuses = res.talentRequisition.campus?.split(',');

              if (campuses?.length > 0) {
                campuses = campuses.forEach((element) => {
                  campusIds.push(parseInt(element));
                });
                res.talentRequisition.campus = campusIds;
              }
              let expectedJoiningDate = this.convert(
                res.talentRequisition.expectedJoiningDate
              );
              console.log(
                res.talentRequisition.higherLevelOfEducation,
                '===============res.talentRequisition.higherLevelOfEducation'
              );
              if (res.talentRequisition.higherLevelOfEducation == 0) {
                res.talentRequisition.higherLevelOfEducation = null;
              } else {
                res.talentRequisition.higherLevelOfEducation =
                  res.talentRequisition.higherLevelOfEducation;
              }
              res.talentRequisition.expectedJoiningDate = expectedJoiningDate;
              // var JobTitleId = res.talentRequisition.jahJobTitleId?.split(',');
              // if (JobTitleId?.length > 0) {
              //   JobTitleId.forEach((element) => {
              //     JobTitleId = parseInt(element);
              //   });
              //   res.talentRequisition.jahJobTitleId = JobTitleId;
              // }

              this.typeOfappointment(res.talentRequisition.typesOfAppointment)

              if (res.talentRequisition.qualification) {
                let qualificationArray = res.talentRequisition.qualification;
                if (qualificationArray.indexOf(',') > -1) {
                  let arraydoc = qualificationArray.split(',');
                  let numberArray = arraydoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                } else {
                  let singleDoc = qualificationArray.split(' ');
                  let numberArray = singleDoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                }
              }

              res.talentRequisition.jahJobTitleId = res.talentRequisition.jahId;
              this.jadId = res.talentRequisition.jahId;

              this.talentRequisition = res.talentRequisition;
              this.trfJobDescription = res.trfJobDescription;

              if (this.talentRequisition.budOrNonBud) {
                this.talentRequisition.budOrNonBud =
                  +this.talentRequisition.budOrNonBud;
              }
            }, 200);
          }
        }
      });
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  }

  GetSkillNameById(event: any, i: any) {
    let skillid = event.skill_CategoryId || event;
    this.commonService.GetSkillNameById(skillid).subscribe(async (res) => {
      let trfSkills: any = this.TRFInfoData.jobskill;
      trfSkills[i].skill_list = res;
    });
  }

  respectiveScreen(data) {
    // if (this.popupError?.length>0) {
    //   this.trfJobTitleMOdal = true;
    if (data == 'basicinfo') {
      // if (this.side_ButtonEnable) {
      this.rafJobTitle = true;
      this.rafJobDescription = false;
      this.rafDescActive = false;
      this.rafBasicActive = true;
      this.rafBasicCheck = true;
      console.log(
        this.TRFInfoData.jobskill,
        '================GetSkillNameById1'
      );
    }
    // }
    // }
    if (data == 'description') {
      console.log(
        this.TRFInfoData.jobskill,
        '================GetSkillNameById2'
      );

      // if (this.perivous_ButtonEnable) {
      this.rafJobTitle = false;
      this.rafJobDescription = true;
      this.rafDescActive = true;
      this.rafBasicActive = false;
      this.rafBasicCheck = true;
    }
  }

  trfNextScreen() {

      this.rafJobTitle = false;
      this.rafJobDescription = true;
      this.rafBasicCheck = true;
      this.rafBasicActive = false;
      this.rafDescCheck = true;
      this.rafDescActive = true;
      this.GetCategoryType();

      console.log(
        this.TRFInfoData.jobskill,
        '================GetSkillNameById4'
      );

  }

  addSkill() {
    console.log(this.TRFInfoData.jobskill, '================GetSkillNameById');
    console.log(this.skill_list, 'skill_listskill_listskill_list');

    let skill_Type: any = [];
    skill_Type = this.skill_list;
    for (let k = 0; k < this.TRFInfoData.jobskill.length; k++) {
      for (let j = 0; j < skill_Type.length; j++) {
        if (
          this.TRFInfoData.jobskill[k] &&
          this.TRFInfoData.jobskill[k].skillCategory
        ) {
          if (
            skill_Type[j].skill_CategoryId ==
            this.TRFInfoData.jobskill[k].skillCategory
          ) {
            var idToRemove = skill_Type[j].skill_CategoryId;
            skill_Type = skill_Type.filter(
              (item) => item.skill_CategoryId !== idToRemove
            );
          }
        }
      }
    }
    //     if (
    //       this.TRFInfoData.jobskill[k] &&
    //       this.TRFInfoData.jobskill[k].skillCategory
    //     )
    //       if (
    //         skill_Type[j].skill_CategoryId ==
    //         this.TRFInfoData.jobskill[k].skillCategory
    //       )
    //         var idToRemove = skill_Type[j].skill_CategoryId;
    //     skill_Type = skill_Type.filter(
    //       (item) => item.skill_CategoryId !== idToRemove
    //     );
    //   }
    // }
    this.skill_list = [];

    let obj = {
      skillCategory: null,
      skills: null,
      skill_list: [],
    };
    this.TRFInfoData.jobskill.push(obj);
    this.skill_list = skill_Type;
  }
  deleteSkill(i) {
    this.TRFInfoData.jobskill.splice(i, 1);
  }

  // addTalentReq(){
  //   this.myService.addTalentReq().subscribe(res=>{
  //     this.addTalentReqArray = res;
  //   })
  // }
  trfBrandd() {
    this.myService.getBrandData().subscribe((res) => {
      this.addTalentReqArray = res;
    });
  }
  trfCountries() {
    this.myService.GetAllCountryDetails().subscribe((res) => {
      this.trfCountriess = res;
    });
  }
  trfCampuss() {
    this.myService.getCampusData().subscribe((res) => {
      this.trfCampuuss = res;
    });
  }
  trfJobTitleData() {
    this.candService.getJobTitleData().subscribe((res) => {
      this.trfJobTitleArray = res;
    });
  }

  trfSkillsCategorry() {
    this.myService.GetSkills_Category().subscribe((res) => {
      this.trfSkkillsCategory = res;
    });
  }
  // trfJobTittlee(){
  //   this.myService.addJobTitle().subscribe(res=>{
  //     this.trfJobTitleArrayy = res;
  //   })
  // }
  trfskills() {
    this.myService.GetJobNames().subscribe((res) => {
      this.trfSkillsss = res;
    });
  }
  trfRequesteerr() {
    this.jobService.GetUsersList().subscribe((res: any) => {
      var trfArray = [];
      for (let i = 0; i < res.length; i++) {
        if (res[i].userStatus == true) {
          trfArray.push(res[i]);
        }
        this.trfrequesterArray = trfArray;
      }
    });
  }

  trfTwoSubmitDisabled: boolean = false;
  showLoader: boolean = false;
  createTrf() {
    this.trfTwoSubmitDisabled = true;
    if (this.descriptionForm.invalid) {
      this.descriptionForm.form.markAllAsTouched();
    }
    if (this.talentRequisition.budOrNonBud) {
      let budgetArray = this.talentRequisition.budOrNonBud;
      let budget = budgetArray.toString();
      this.talentRequisition.budOrNonBud = budget;
    }
    if (this.talentRequisition.qualification) {
      this.talentRequisition.qualification =
        this.talentRequisition.qualification.toString();
    }
    if (this.talentRequisition.higherLevelOfEducation == null) {
      this.talentRequisition.higherLevelOfEducation = 0;
    } else {
      this.talentRequisition.higherLevelOfEducation =
        this.talentRequisition.higherLevelOfEducation;
    }
    // if (this.talentRequisition.brand) {
    //   let res = this.resp;
    //   for (let i = 0; i < res.length; i++) {
    //     this.talentRequisition.brand= res[i].brandId;
    //   }
    //   // this.basicInfoData.country = res.countryId;
    //   // this.basicInfoData.campus = res.campusId;
    // }
    // if (this.talentRequisition.jahJobTitleId) {
    //   let jobTitleArray = this.talentRequisition.jahJobTitleId;
    //   let jobTitle = jobTitleArray.toString();
    //   this.talentRequisition.jahJobTitleId = jobTitle;
    // }
    this.talentRequisition.jahId = this.talentRequisition.jahJobTitleId;
    if (this.talentRequisition.campus) {
      let campusArray = this.talentRequisition.campus;
      let campus = campusArray.toString();
      this.talentRequisition.campus = campus;
    }

    if (this.TRFInfoData.jobskill) {
      let jobsdata = this.TRFInfoData.jobskill;
      for (let i = 0; i < jobsdata.length; i++) {
        if (jobsdata[i].skills != null) {
          var skills = jobsdata[i].skills.toString();
        }

        jobsdata[i].skills = skills;
        if (jobsdata[i] && jobsdata[i].skill_list) {
          delete jobsdata[i].skill_list;
          // delete jobsdata[i].skills
        }
      }
      this.TRFInfoData.jobskill = jobsdata;
      this.trfJobDescription.trfSkills = this.TRFInfoData.jobskill;
    }
    let params = {
      talentRequisition: this.talentRequisition,
      trfJobDescription: this.trfJobDescription,
    };

    if (this.id) {
      params['status'] = 1;
    }
    if (!this.id) {
      params['status'] = 1;
    }
    if (this.id) {
      this.trfservice.createTrfForm(params).subscribe((res) => {
        this.showLoader = true;
        if (res) {
          this.showLoader = false;
          this.trfTwoSubmitDisabled = false;
          // this.router.navigateByUrl('/trf');
          this.notifyService.showSuccess(
            'Thank you !',
            'TRF is Updated Successfully'
          );
          this.basicInfo.form.reset();
          this.descriptionForm.form.reset();
          // window.location.reload();
          this.router.navigateByUrl('/trf');
        }
      });
    }
    if (!this.id) {
      this.trfservice.createTrfForm(params).subscribe((res) => {
        this.showLoader = true;
        if (res) {
          setTimeout(() => {
            this.showLoader = false;
            this.trfTwoSubmitDisabled = false;
            // this.router.navigateByUrl('/trf');
            this.notifyService.showSuccess(
              'Thank you !',
              'TRF is Created Successfully'
            );
            this.basicInfo.form.reset();
            this.descriptionForm.form.reset();
            window.location.reload();
            // this.router.navigateByUrl('/trf');
          }, 3000);
        }
      });
    }
  }
  // hierarchy_job:any
  // GetJobApprovalHierarchy(){
  //   this.jobService.GetJobApprovalHierarchy().subscribe((res:any)=>{
  //     this.hierarchy_job=res
  //   })
  // }
  tahId: any;
  countryTrf: any;
  resp: any;
  jobTrf(event: any) {
    this.talentRequisition.campus = [];
    this.talentRequisition.country = [];
    this.tahId = parseInt(event.jobApprovalHierarchyId);
    this.commonService.GetJahJobtitleData(this.tahId).subscribe((res: any) => {
      this.resp = res;
      for (let i = 0; i < res.length; i++) {
        this.talentRequisition.brand = res[i].brand;
        this.countryTrf = res[i].country;
      }
    });
  }
  jahCountryid: any;
  obj: any;
  finalArrayNew: any = [];
  trfCountry(event: any) {
    this.jahCountryid = event.countryIds;
    this.commonService
      .GetAllCountryDetailsById(this.jahCountryid)
      .subscribe((res) => {
        this.talentRequisition.ctcCode = res[0].currencyCode;
        this.talentRequisition.totalIncurredCostCode = res[0].currencyCode;
      });
    this.commonService.GetJahJobtitleData(this.tahId).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        let value = res[i].campus;
        var finalArrayNew: any = [];
        for (let i = 0; i < value.length; i++) {
          let element = value[i].countryId;
          if (element == this.jahCountryid) {
            this.obj = {
              campusIds: value[i].campusIds,
              campusName: value[i].campusName,
            };

            finalArrayNew.push(this.obj);
          }
        }
        this.finalArrayNew = finalArrayNew;
      }
    });
  }
  uploadedresult: any;
  skill_list: any = [];
  GetCategoryType() {
    this.commonService.GetCategoryType(true).subscribe((res) => {
      this.uploadedresult = res;
      this.skill_list = this.uploadedresult.filter(
        (value: any, index: any, self: any) =>
          self.findIndex(
            (m: any) => m.skill_CategoryId === value.skill_CategoryId
          ) === index
      );
      // this.skill_list = res;
    });
  }
  SkillNameById(event: any, i: any) {
    let jobskills = this.TRFInfoData.jobskill;
    jobskills[i].skills = [];
    let skillid = event.skill_CategoryId || event;
    this.commonService.GetSkillNameById(skillid).subscribe(async (res) => {
      let jobSkill: any = this.TRFInfoData.jobskill;
      jobSkill[i].skill_list = res;
    });
  }
  categories: any;
  getCategories() {
    this.commonService.getCategories(true).subscribe((res: any) => {
      this.categories = res;
    });
  }
  hyring_Type: any;
  gethyringType() {
    this.commonService.gethyringType().subscribe((res) => {
      if (res) {
        this.hyring_Type = res;
      }
    });
  }
  employement_details: any;
  getEmployementType() {
    this.commonService.getEmployementType().subscribe((res) => {
      this.employement_details = res;
    });
  }

  brands: any;
  resVal: any = {};
  brandDetails() {
    this.commonService.getBrandData().subscribe((res) => {
      this.brands = res;
    });
  }

  brandsnewarray: any = [];
  GetBrandDetailsByUserId(val: any) {
    console.log(val, '===sind');
    this.talentRequisition.brand = [];
    this.talentRequisition.country = [];
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];
    this.trfservice.GetBrandDetailsByUserId(val.userId).subscribe((res) => {
      console.log(val, '===saaaaaind');
      this.brandsnewarray = res;
      this.resVal = res;
      // this.brandsnewarray.push(this.resVal);
    });
  }
  trf_Country: any;
  brandId: any;
  getcountries(event: any) {
    this.talentRequisition.country = [];
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];

    this.trfservice.GetCountryCampusByBrandId(event).subscribe((country) => {
      // this.brandsnewarray = []
      // this.resVal = country
      this.trf_Country = country[0].country;
      console.log(this.trf_Country, 'Success');
    });
    // this.brandId = event.brandId
    // if (event != undefined) {
    //   this.trf_Country = event;
    // }
  }
  trfCampus: any;
  countryId: any;
  hierarchy_job: any = [];

  getCampus(event: any) {
    //alert(event)
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];
    this.countryId = event;
    this.commonService
      .GetAllCountryDetailsById(this.countryId)
      .subscribe((res) => {
        // this.talentRequisition.ctcCode = res[0].currencyCode;
        this.talentRequisition.totalIncurredCostCode = res[0].currencyCode;
      });
    // this.trfservice.GetJahTitlebyCountry(this.countryId).subscribe((res)=>{
    //   this.hierarchy_job=res
    // })
    this.getTitle(this.countryId);
    this.commonService.GetcampusByid(this.countryId).subscribe((res) => {
      this.trfCampus = res;
    });
  }

  getTitle(countryId) {
    this.trfservice.GetJahTitlebyCountry(countryId).subscribe((res: any) => {
      console.log(res, 'titlejoblist');
      const element = [];
      // for (let i = 0; i < res.length; i++) {
      //   if (res[i].jobTitleName != null) {
      //     element.push(res[i]);
      //   }
      this.hierarchy_job = res;
      // }
      // if (this.id) {
      //   for (let i = 0; i < res.length; i++) {
      //     if (res[i].jahId==this.jadId) {
      //      element.push(res[i]);
      //     }
      //             this.hierarchy_job = element;

      //     console.log(element,"===============")
      //   }
      // }
    });
  }

  // GetBrandDetailsByUserId(){

  // }
  popupError: any;
  validateJobTitle(event: any) {
    var jahId = event.jahId;
    var jobTitle = event.jobTitleId;
    var brand = this.talentRequisition.brand;
    var campus = this.talentRequisition.campus;
    this.trfservice
      .ValidateJahTrfJobTitleOnCampus(brand, jobTitle, campus, jahId)
      .subscribe((res: any) => {
        this.validateTitle = res;
        var errormessage = [];
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].jobTitleName;
          this.jahId = res[i].jahId;
          var element = res[i].campusName;
          errormessage.push(element);
        }
        if (res.length > 0) {
          this.titleError = this.title;
          this.jahIds = this.jahId;
          this.popupError = errormessage.toString();
          this.trfJobTitleMOdal = true;
        }
      });
    this.ValidateTrfJobtitleOnCampus(jobTitle, campus);
    this.GetJobInfoByJahIdBrandId(jahId,brand)
  }

  GetJobInfoByJahIdBrandId(jahId: any, brand: any) {
    this.trfservice.GetJobInfoByJahIdBrandId(jahId,brand).subscribe((res:any)=>{
      console.log(res,"==========================brand")
      for (let i = 0; i < res.length; i++) {
        this.talentRequisition.category= res[i].jobCategoryId;
        this.talentRequisition.totalExperienceFrom= res[i].totalExperienceFrom;
        this.talentRequisition.totalExperienceTo= res[i].totalExperienceTo;
        this.talentRequisition.higherLevelOfEducation= res[i].higherLevelOfEducationId;
        if (res[i].qualification) {
          let qualificationArray = res[i].qualification;
          if (qualificationArray.indexOf(',') > -1) {
            let arraydoc = qualificationArray.split(',');
            let numberArray = arraydoc.map(Number);
            this.talentRequisition.qualification = numberArray;
          } else {
            let singleDoc = qualificationArray.split(' ');
            let numberArray = singleDoc.map(Number);
            this.talentRequisition.qualification = numberArray;
          }
        }

        this.trfJobDescription.overview=res[i].overview
        this.trfJobDescription.responsibilities=res[i].responsibilities
        this.trfJobDescription.skillsDescription=res[i].skillsDescription
        var skType=res[i].jobskillsinfo
        var skilltype:any=[]
        for (let i = 0; i < skType.length; i++) {
          var obj={
            skillCategory: skType[i].skillCategoryId,
            skills: skType[i].skills
          }
          skilltype.push(obj)
        }
        this.TRFInfoData.jobskill=skilltype
        setTimeout(() => {
          if (this.TRFInfoData.jobskill) {
            let skills = this.TRFInfoData.jobskill;
            for (let i = 0; i < skills.length; i++) {
              this.GetSkillNameById(skills[i].skillCategory, i);
              let skillName = skills[i].skills;
              if (skillName.indexOf(',') > -1) {
                let arraydoc = skillName.split(',');
                let skillnumarray = arraydoc.map(Number);
                skills[i].skills = skillnumarray;
              } else {
                let singleDoc = skillName.split(' ');
                let skillnumarray = singleDoc.map(Number);
                skills[i].skills = skillnumarray;
              }
            }
          }
        }, 400);


      }
    })
  }

  errorjob: any;
  jobRes: any;
  errorJobTitleDisabled: boolean = false;
  ValidateTrfJobtitleOnCampus(jobTitle: any, campus: any) {
    this.trfservice
      .ValidateTrfJobtitleOnCampus(jobTitle, campus)
      .subscribe((res: any) => {
        this.jobRes = res;
        if (res) {
          this.errorjob = 'TR exist';
          this.errorJobTitleDisabled = true;
        } else {
          this.errorjob = '';
          this.errorJobTitleDisabled = false;
        }
      });
  }
  campusDetails(event) {
    // this.talentRequisition.jahJobTitleId = [];
    this.errorjob = '';
  }
  openJobPopPUPP() {
    this.trfJobTitleMOdal = true;
  }
  CloseTrfJobTitlepopUP() {
    this.trfJobTitleMOdal = false;
    this.talentRequisition.jahJobTitleId = [];
    this.errorjob = '';
  }
  commaSeperated(data) {
    // let newLine = data.replace(/,/g, '\n')
    // return  newLine;
    var nameArr = data.split(',');
    var obj = nameArr;
    return obj;
  }

  fromyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];
  toyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];

  totoalExperienc(val: any, item) {
    this.toyears = [];
    if (val) {
      for (let i = 0; i < this.fromyears.length; i++) {
        let numberVal = +val.name;
        let perfVal = +this.fromyears[i].name;
        if (numberVal < perfVal) {
          this.toyears.push(this.fromyears[i]);
        } else {
          // this.preFExpP[i].isDisabled = false;
        }
      }
    }
    item.totalExperienceTo = null;
  }

  getDegree() {
    this.commonService.getDegree().subscribe((res) => {
      if (res) this.degree = res;
    });
  }
  getLevelOfEducation() {
    this.commonService.getLevelOfEducation().subscribe((res) => {
      this.Education_details = res;
    });
  }
  trfSalaryRangeError: boolean = false;
  validSalaryrange(e: any) {
    // console.log(e.target.value, "eventttttttttttttttttttttttt");
    var secInput = e.target.value
    if (parseInt(secInput) < this.talentRequisition.offerSalaryRangeFrom) {
      this.trfSalaryRangeError = true;
      // console.log("trueeeeeeeeeee");
    }  if (parseInt(secInput) > this.talentRequisition.offerSalaryRangeFrom) {
      // console.log("falseeeeeeeeeeee");
      this.trfSalaryRangeError = false;
    }
  }

  showErrorExpectSalary: boolean = false;
  getValueTwo() {
    if (
      this.talentRequisition.offerSalaryRangeFrom >
      this.talentRequisition.offerSalaryRangeTo
    ) {
      this.showErrorExpectSalary = true;
    }
    if (
      this.talentRequisition.offerSalaryRangeFrom <
      this.talentRequisition.offerSalaryRangeTo
    ) {
      this.showErrorExpectSalary = false;
    }
  }

  clearSalaryinputFields(){
    this.talentRequisition.offerSalaryRangeFrom = '';
    this.talentRequisition.offerSalaryRangeTo = '';
    this.showErrorExpectSalary = false;
  }
  newSalaryValidation: boolean = false;
  newSalaryValidationTwo: boolean = false;

  minSalaryCountryValidation() {
    this.talentRequisition.offerSalaryRangeTo=''
    if (this.talentRequisition.ctcCode == 'INR') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 50000) {
        this.newSalaryValidation = true;
        this.newSalaryValidationTwo = false;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }

      // console.log("1");
    }
    if (this.talentRequisition.ctcCode == 'USD') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 5000) {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = true;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }
      // console.log("2");
    }
    if (this.talentRequisition.ctcCode == 'SGD') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 5000) {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = true;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }
      // console.log("3");
    }

    // console.log(this.basicInfoData.expectedSalarySymbol, 'mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm');
  }

  newCurrenyCodeArray:any=[]
  newCurrenyArray:any=[]
  getCurrencyCodes() {
    this.candService.GetAllCountryDetails().subscribe((res: any) => {
      // this.newCurrenyCodeArray=res
      // this.newCurrenyCodeArray = res.filter(country => country.currencyCode === res);
      this.newCurrenyArray=res
      this.newCurrenyCodeArray = this.newCurrenyArray.filter(
        (value: any, index: any, self: any) =>
          self.findIndex(
            (m: any) => m.currencyCode === value.currencyCode
          ) === index
      );

    })
  }

  getAppointmentType() {
    this.commonService.getAppointmentType().subscribe((res) => {
      this.Appointment_data = res;
    });
  }

  disableemployee: boolean = false;
  typeOfappointment(event: any) {
    var ename = event.appointment_Id || event;
    if (ename == 2) {
      this.disableemployee = true;
    } else if (ename == 1) {
      this.disableemployee = false;
    }
  }

  redirectToListing() {
    // window.location.href = '/job-listing';
    window.history.back();
  }

}
