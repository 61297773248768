import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { from } from 'rxjs';
import { ReportsService } from 'src/app/services/reports.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { jwtDecode } from 'jwt-decode';
import { Chart, ChartConfiguration, ChartData, ChartType, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { userService } from 'src/app/services/userservice';
import { HeaderComponent } from 'src/app/layout/header/header.component';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

// import pluginDoughnutlabel from 'chartjs-plugin-doughnutlabel';

// import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

// Chart.register(...registerables);
// Chart.register(ChartDataLabels);

@Component({
  selector: 'app-dashboard-reports',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardReportsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas', { static: false }) canvas: any;
  @ViewChild('canvas2', { static: false }) canvas2: any;
  @ViewChild('canvas3', { static: false }) canvas3: any;
  @ViewChild('chartContainer', { static: false }) chartContainer: any;
  @ViewChild('chartContainer2', { static: false }) chartContainer2: any;
  @ViewChild('chartContainer3', { static: false }) chartContainer3: any;
  @ViewChild('chartContainer4', { static: false }) chartContainer4: any;
  @ViewChild('canvasNew', { static: false }) canvasNew: any;
  @ViewChild('canvasNewTxt', { static: false }) canvasNewTxt: any;
  @ViewChild('chartContainerNew', { static: false }) chartContainerNew: any;

  chart: any;
  preArray: any = [];
  dataNewAca: any = {}
  ngOnDestroy(): void {
    // Ensure the chart is destroyed when the component is destroyed
    // if (this.chart) {
    //   this.chart.destroy();
    // }
    // this.destroyChart();
  }



  ngAfterViewInit() {

  //   var startDate = this.dateRangeFrom;
  //     var endDate = this.dateRangeTo;

  //     if (startDate != '' && startDate != undefined && startDate != null) {
  //       startDate = startDate;
  //     } else {
  //       startDate = '1900-01-01T00:00:00.000Z';
  //     }

  //     if (endDate != '' && endDate != undefined && endDate != null) {
  //       endDate = endDate;
  //     } else {
  //       endDate = '1900-01-01T00:00:00.000Z';
  //     }

  //  var  obj = {
  //     userId: this.userId,
  //     brand: this.brandId,
  //     country: this.countryId,
  //     campus: this.campusId,
  //     jobCategory: this.jobCategory,
  //     department: this.departmentId,
  //     source: this.jobPostId,
  //     fromDate: startDate,
  //     toDate: endDate,
  //   };
  //   console.log('stagesChartContainer:', this.stagesChartContainer);
  //   console.log('stagesCanvas:', this.stagesCanvas);
  //   this.GetStagesOverviewByCountry(obj, 'Brand');
    // setTimeout(() => {
    //   const labels: any[] = [
    //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
    //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
    //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
    //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
    //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'
    //   ];

    //   const datasets: any [] = [
    //     {
    //       label: 'Dataset 1',
    //       data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
    //              12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
    //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //     },
    //     {
    //       label: 'Dataset 2',
    //       data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4,
    //              7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4],
    //       backgroundColor: 'rgba(54, 162, 235, 0.5)',
    //     },
    //     {
    //       label: 'Dataset 3',
    //       data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8,
    //              9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8],
    //       backgroundColor: 'rgba(150, 192, 192, 0.5)',
    //     }
    //   ];

    //   const barThickness = 30;
    //   const barMargin = 20;

    //   const chartWidth = labels.length * (barThickness + barMargin);

    //   this.renderer.setStyle(this.chartContainer.nativeElement, 'width', `${chartWidth}px`);

    //   if (this.canvas?.nativeElement) {
    //     const ctx = this.canvas.nativeElement.getContext('2d');
    //     if (ctx) {
    //       this.chart = new Chart(ctx, {
    //         type: 'bar',
    //         data: {
    //           labels: labels,
    //           datasets: datasets,
    //         },
    //         options: {
    //           maintainAspectRatio: false,
    //           plugins: {
    //             datalabels: {
    //               display: (context: any) => {
    //                 return context.datasetIndex === context.chart.data.datasets.length - 1;
    //               },
    //               anchor: 'end',
    //               align: 'end',
    //               formatter: (value: any, context: any) => {
    //                 if (context.datasetIndex === context.chart.data.datasets.length - 1) {
    //                   const dataIndex = context.dataIndex;
    //                   const total = context.chart.data.datasets
    //                     .map((dataset: any) => dataset.data[dataIndex])
    //                     .reduce((sum: number, currentValue: number) => sum + currentValue, 0);
    //                   return total;
    //                 }
    //                 return null;
    //               },
    //               color: 'black',
    //               font: {
    //                 weight: 'bold'
    //               }
    //             }
    //           },
    //           scales: {
    //             x: {
    //               stacked: true,
    //             },
    //             y: {
    //               stacked: true,
    //               beginAtZero: true,
    //             },
    //           },
    //         },
    //       });
    //     } else {
    //       console.error('Canvas context not available');
    //     }
    //   } else {
    //     console.error('Canvas element not found');
    //   }
    // }, 1500);
  }

  public pbarChartLabels: any;
  public pbarChartType: any = 'bar';
  public pbarChartLegend = true;
  public pbarChartPlugins = [];
  public pbarChartData: any;

  myStackedData: any;
  myStackedOptions: any;

  public pbarChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    plugins: {
      legend: {
        // offset: 20,

        top: 20,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
      title: {
        display: true,
        font: {
          size: 9,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 10,
    // scales: {
    //   xAxes: [{}],
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //         callback: function (label:any, index:any, labels:any) {
    //           return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //         },
    //       },
    //     },
    //   ],
    // },
    // scales: {
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    //   y: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    // tooltips: {
    //   enabled: true,
    //   intersect: true,
    //   titleFontSize: 20,
    //   callbacks: {
    //     label: function (tooltipItem: any, data: any) {
    //       var dataset = data.datasets[tooltipItem.datasetIndex];
    //       var currentValue = dataset.data[tooltipItem.index];
    //       return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //     },
    //   },
    // },
    // plugins: {
    //   datalabels: {
    //     anchor: 'end',
    //     align: 'end',
    //   },
    // },
    // barThickness: 20,
  };

  data: any;
  myoptions: any = {
    maintainAspectRatio: false,
    layout: {
      padding: 20
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 10,
  };

  zeroexists: boolean = false;
  data1: any;
  options1: any;
  data2: any = {};
  options2: any;
  data3: any;
  options3: any;
  dashboardForm: FormGroup;
  numberOfLettersToShow: number = 5;
  loading: boolean = true;

  brandArray: any = [];
  countryArray: any = [];
  result: any = [];
  campusArr: any = [];
  jobCategoryArr: any = [];
  departmentArr: any = [];
  sourceArr: any = [];
  jobPosotionsArr: any = [];
  academicsArr: any = [];
  newReplacementsArr: any = [];
  averageTATArr: any = [];

  brandId: any;
  brandName: any;
  countryId: string = '';
  campusId: string = '';
  jobCategory: string = '';
  departmentId: string = '';
  jobPostId: string = '';
  activeJobs: any;
  pendingJobs: any;
  closedJobs: any;
  totalJobs: any;
  academic: any;
  nonAcademic: any;
  totalCategoryCount: any;
  academicPercentage: any;
  nonAcademicPercentage: any;
  new: any;
  replacement: any;
  newAverageString: any;
  replacementAverageString: any;
  totaltrf: any;
  country: any;
  totalApplicants: any;
  malePercentage: any;
  femalePercentage: any;
  selectednotselectedapplicants: any;
  brandTAT: any;
  notSelectedPercentage: any;
  selectedPercentage: any;
  accepted: any;
  expired: any;
  jobClosed: any;
  acceptedAverage: any;
  expiredAverage: any;
  jobClosedAverage: any;

  brandFirstObj: any = {};
  stagesOverviewObjr: any = {};
  barChartDataobj: any = {};
  statusDistributionObj: any = {};
  selectedApplicantsObj: any = [];
  talentRequisitionObj: any = {};
  mainObject: any = {};
  stageOverview: any = {};
  userDetails: any;
  userId: any;
  dateRangeFrom: any;
  dateRangeTo: any;
  patchesRowArray: any = [];
  selectedcategoryName: any;
  selectedDepartmentName: any;
  selectedstartDate: any;
  selectedCountryName: any;
  selectedCcampusName: any;
  id: any;
  arrayCamp: any = [];
  arayCountry: any = [];
  totalNewPositions: any;
  totalNewPositionsAca:any
  totalReplacementCount: any;
  totalNewCount: any;
  totalReplacementCountAca: any;
  totalNewCountAca: any;
  dataNew: any;
  totalClosedWithApplicant: any;
  totalDirectlyClosedApplicants: any;
  totalClosedJobs: any;
  totalActiveJobs: any;
  index: any;
  activeVacancyCount: any;
  closedVacancyCount: any;
  totalVacancyCount: any;
  academicVacencyCount: any;
  nonAcademicVacencyCount: any;
  academicVacencyPercentage: any;
  nonAcademicVacencyPercentage: any;
  newVacancyPercentage: any;
  newVacancyCount: any;
  replacementVacancyCount: any;
  replacementVacancyPercentage: any;
  sourceHireData: any = [];

  getSum(): number {
    return this.data2.datasets[0].data.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
  }

  constructor(private fb: FormBuilder, public reportsService: ReportsService, private renderer: Renderer2, public userService: userService) {
    this.dashboardForm = this.fb.group({
      dateRangeFrom: ['', Validators.required],
      dateRangeTo: ['', Validators.required],
    });
    this.departmentWise = this.departments.slice();
    this.selectedCountries = this.countries.slice();
    this.selectedCategories = this.categories.slice();
  }

  dashboardLoading : boolean = true;
  dashboardjobStatusArray:string=''
  jobStatusArray:any=[
    {
      id:'',name:'All'
     },
    {
     id:'1',name:'Active'
    },
    {
     id:'2',name:'Closed'
    },
  ]

  ngOnInit() {
    this.dashboardLoading = true;
    this.tabsButtonDisabled = true;
    setTimeout(() => {
      this.dashboardLoading = false;
    }, 5000);
    setTimeout(() => {
      this.tabsButtonDisabled = false;
    }, 2000);
    this.pbarChartData = [
      { data: [7], label: 'A1', backgroundColor: 'rgba(180, 0, 0, 1)' },
      { data: [13], label: 'A2', backgroundColor: 'rgba(33, 81, 255, 1)' },
      { data: [2], label: 'A3', backgroundColor: 'rgba(255, 166, 0, 1)' },
    ];

    this.pbarChartLabels = ['A1', 'A2', 'A3'];
    let userData = this.userService.getUserId();
    this.userDetails = jwtDecode(userData);
    this.userId = this.userDetails.UserId;
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    // this.data1 = {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //   datasets: [
    //     {
    //       label: 'India',
    //       data: [20, 30, 35, 45, 60, 70, 85],
    //       fill: false,
    //       borderColor: documentStyle.getPropertyValue('--blue-500'),
    //     },
    //     {
    //       label: 'Singapore',
    //       data: [28, 40, 48, 59, 76, 87, 91],
    //       fill: false,
    //       borderColor: documentStyle.getPropertyValue('--pink-500'),
    //     },
    //   ],
    // };

    this.options1 = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.6,
      layout: {
        padding: 20
      },
      plugins: {
        legend: {
          labels: {
            boxWidth: 8,
            padding: 30,
            boxHeight: 16,
            borderRadius: 2,
            useBorderRadius: true,
          }
        },
        // tooltip: {
        //   callbacks: {
        //     label: function (context: { label: string; parsed: number; }) {
        //       let label = context.label || '';

        //       if (label) {
        //         label += ': ';
        //       }
        //       if (context.parsed && context.parsed !== 0) {
        //         label += context.parsed.toFixed(2) + '%'; // Append '%' symbol
        //       }

        //       return label;
        //     }
        //   }
        // }
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,

            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            autoSkip: false,

            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    this.options2 = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.6,
      cutout: '70%',
      layout: {
        padding: 20
      },
      plugins: {
        // plugins: {
        datalabels: {
          display: false,
          anchor: 'end',
          align: 'top',
          formatter: (value: any) => value, // Display the value directly on each bar
          color: 'black',
          font: {
            weight: 'normal'
          }
        },
        // }
        legend: {
          display: false,
          align: 'start',
          position: 'bottom',
          labels: {
            boxWidth: 8,
            padding: 30,
            boxHeight: 16,
            borderRadius: 2,
            useBorderRadius: true,
          }
        },
        doughnutlabel: {
          // labels: [
          //   {
          //     text: '220',
          //     font: {
          //       size: '20',
          //       units: 'em',
          //       family: 'Arial, Helvetica, sans-serif',
          //       style: 'italic',
          //       weight: 'bold',
          //     },
          //     color: '#bc2c1a',
          //   },
          // ],
        },
      },
      scales: {
        // x: {
        //   grid: {
        //     display: false
        //   }
        // },
        // y: {
        //   grid: {
        //     display: false
        //   }
        // }
      },
    };

    /*****Page Load API CALL******/
    this.GetBrand('load', 0);
    this.GetSources();
    /*****Page Load API CALL*****/

    // source of hiring
    // const documentStyle = getComputedStyle(document.documentElement);
    // const textColor = documentStyle.getPropertyValue('--text-color');

    // this.data = {
    //     labels: ['A', 'B', 'C'],
    //     datasets: [
    //         {
    //             data: [300, 50, 100],
    //             backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'), documentStyle.getPropertyValue('--green-500')],
    //             hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
    //         }
    //     ]
    // };

    this.options = {
      cutout: '60%',
      layout: {
        padding: 20
      },
      plugins: {
        legend: {
          align: 'start',
          position: 'bottom',
          labels: {
            color: textColor,
            boxWidth: 8,
            padding: 30,
            boxHeight: 16,
            borderRadius: 2,
            useBorderRadius: true,
          }
        },
      },
    };

    // source of hiring

    // const documentStyle = getComputedStyle(document.documentElement);
    //     const textColor = documentStyle.getPropertyValue('--text-color');
    //     const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    //     const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    // this.myStackedData = {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [
    //         {
    //             type: 'bar',
    //             label: 'Dataset 1',
    //             backgroundColor: documentStyle.getPropertyValue('--green-500'),
    //             data: [50, 25, 12, 48, 90, 76, 42]
    //         },
    //         {
    //             type: 'bar',
    //             label: 'Dataset 2',
    //             backgroundColor: documentStyle.getPropertyValue('--black-500'),
    //             data: [3, 3, 3, 3, 3, 3, 3]
    //         },
    //         {
    //             type: 'bar',
    //             label: 'Dataset 3',
    //             backgroundColor: documentStyle.getPropertyValue('--red-500'),
    //             data: [21, 84, 24, 75, 37, 65, 34]
    //         }
    //     ]
    // };

    this.myStackedOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.4,
      layout: {
        padding: 20
      },
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label: function (context: any) {
              if (context.dataset.label === 'BenchMark') {
                return '';
              }
              return context.dataset.label + ': ' + context.parsed.y;
            },
          },
        },
        legend: {
          align: 'start',
          position: 'bottom',
          labels: {
            color: textColor,
            boxWidth: 8,
            padding: 30,
            boxHeight: 16,
            borderRadius: 2,
            useBorderRadius: true,
          }
        },
        // plugins: {
        datalabels: {
          display: false,
        },
        // },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            font: {
              size: 10,
            },
          },
          grid: {
            display: false,
          },
          border: {
            dash: [5, 5],
            display: true
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            stepSize: 20,
          },
          grid: {
            display: true,
          },
          border: {
            dash: [5, 5],
            display: true
          },
        },
      },

      barThickness: 20,
    };
    // this.options = {
    //   plugins: {
    //     tooltip: {
    //       callbacks: {
    //         label: function(context:any) {
    //           let label = context.label || '';
    //           if (label) {
    //             label += ': ';
    //           }
    //           if (context.raw !== null) {
    //             label += context.raw + '%';
    //           }
    //           return label;
    //         }
    //       }
    //     }
    //   }
    // };
  }
  arr: any = [];
  arrName: any = [];
  getCriteriaId(userId: any) {
    this.arr=[]
    this.loading = true;
    this.reportsService
      .GetSaveCriteriaByUserId(userId)
      .subscribe((res: any) => {
        if (res.length > 0) {
          bArr = [];
          this.loading = false;
          for (let i = 0; i < res.length; i++) {
            this.id = res[i].id;
            this.brandUserId(res[i].userid);
            var bArr = res[i].brand;

            for (let x = 0; x < bArr.length; x++) {
              this.arr.push(bArr[x].brandId);
            }
            this.brandId = bArr.map(function (el: any) {
              return el.brandId;
            });
            this.brandId = this.brandId.toString();
            this.brandName = bArr.map(function (el: any) {
              return el.brandName;
            });
            this.brandFirstObj = this.arr;
            this.GetCountryByBrand(this.brandFirstObj, userId);
            if (res[i].country) {
              var country = res[i].country;
              this.arayCountry = [];
              var arayCountryname: any = [];
              for (let j = 0; j < country.length; j++) {
                var element = country[j].countryid;
                this.arayCountry.push(element);
                arayCountryname.push(country[j].countryname);
                this.selectedCountryName = arayCountryname;
                this.dashboardCountryFilterItem = this.arayCountry;
                this.countryId = this.arayCountry.toString();
                this.brandCampusId(this.brandFirstObj, this.arayCountry);
              }
            }

            setTimeout(() => {
              if (res[i].campus) {
                var campus = res[i].campus;
                this.arrayCamp = [];
                var campusnameInfo = [];
                for (let k = 0; k < campus.length; k++) {
                  var campusId = campus[k].campusId;
                  var campusname = campus[k].campusname;
                  this.arrayCamp.push(campusId);
                  campusnameInfo.push(campusname);
                }
                this.dashboardCampusFilterItem = this.arrayCamp;
                this.selectedCcampusName = campusnameInfo;
                this.campusId = this.arrayCamp.toString();
              }

              if (res[i].jobCategory) {
                var jobcategory = res[i].jobCategory;
                var arrayCategory = [];
                var categorynameInfo = [];
                for (let l = 0; l < jobcategory.length; l++) {
                  var jobcategoryId = jobcategory[l].jobcategory;
                  var categoryname = jobcategory[l].categoryname;
                  arrayCategory.push(jobcategoryId);
                  categorynameInfo.push(categoryname);
                }
                this.jobCategoryId(
                  this.brandFirstObj,
                  this.arayCountry,
                  this.arrayCamp
                );

                this.dashboardJobCategoryFilterItem = arrayCategory;
                this.selectedcategoryName = categorynameInfo;
                this.jobCategory = arrayCategory.toString();
                this.departmentInfo(
                  this.brandFirstObj,
                  this.arayCountry,
                  this.arrayCamp,
                  arrayCategory
                );
              }
              if (res[i].jobstatus != null) {
                this.dashboardjobStatusArray = res[i].jobstatus
              }else{
                this.dashboardjobStatusArray = null
              }
              if (res[i].department) {
                var dept = res[i].department;
                var depArray = [];
                var depNameInfo = [];
                for (let m = 0; m < dept.length; m++) {
                  var departmentId = dept[m].department;
                  var depName = dept[m].departmentname;

                  depArray.push(departmentId);
                  depNameInfo.push(depName);
                }
                this.dashboardDepartmentFilterItem = depArray;
                this.selectedDepartmentName = depNameInfo;
                this.departmentId = depArray.toString();
              }

              if (res[i].source) {
                var sourceData = res[i].source;
                var sourceArray = [];
                for (let n = 0; n < sourceData.length; n++) {
                  var sourceInfo = sourceData[n].sourceid;
                  sourceArray.push(sourceInfo);
                }
                this.GetSources();
                this.dashboardSourceFilterItem = sourceArray;
                this.jobPostId = sourceArray.toString();
              }

              this.GetAllFilters('change');
            }, 500);
          }
        } else {
          this.loading = false;
          this.GetAllFilters('load');
        }
      });
  }
  departmentInfo(
    brandFirstObj: any,
    arayCountry: any,
    arrayCamp: any,
    arrayCategory: any
  ) {
    var country = arayCountry.toString();
    var campus = arrayCamp.toString();
    var category = arrayCategory.toString();
    this.reportsService
      .GetDepartmentByCategory(brandFirstObj, country, campus, category)
      .subscribe((result: any) => {
        this.departmentArr = result;
      });
  }
  brandUserId(userId: any) {
    this.reportsService.GetBrandByUserId(userId).subscribe((res: any) => {
      this.brandArray = res;
    });
  }
  jobCategoryId(brandFirstObj: any, arayCountry: any[], arrayCamp: any) {
    var country = arayCountry;
    var campus = arrayCamp;
    this.reportsService
      .GetJobCategoryByCampus(brandFirstObj, country, campus)
      .subscribe((result: any) => {
        this.jobCategoryArr = result;
      });
  }
  brandCampusId(brandFirstObj: any, arayCountry: any) {
    var country = arayCountry.toString();
    this.reportsService
      .GetCampusByCountry(brandFirstObj, country, this.userId)
      .subscribe((result: any) => {
        this.campusArr = [];
        this.jobCategoryArr = [];
        this.departmentArr = [];

        this.dashboardCampusFilterItem = '';
        this.dashboardJobCategoryFilterItem = '';
        this.dashboardDepartmentFilterItem = '';

        result?.forEach((element: any) => {
          var cumpusesasrr = element.campuses;
          cumpusesasrr.forEach((element1: any) => {
            this.campusArr.push(element1);
          });
        });
      });
  }

  /****************GetBrand on Load & Change******************/
  resetTabs() {
    setTimeout(() => {
      const destroyCanvases = () => {
        const canvases = document.querySelectorAll('canvas');
        canvases.forEach(canvas => {
          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          // Additional cleanup if required
        });
      };
    }, 500);

    // Reset "stagesTab"
    document.getElementById('stages-brand-tab')?.click();

    // Reset "globalPositionsTab"
    document.getElementById('positions-brand-tab')?.click();
    document.getElementById('number-tab')?.click();
    document.getElementById('number-tab1')?.click();
    document.getElementById('positins-brand-tab')?.click();

    // Reset "entityTab"
    document.getElementById('entity-brand-tab')?.click();
    document.getElementById('joiners-brand-tab')?.click();
    document.getElementById('number-tab')?.click();
    document.getElementById('numberr-tab')?.click();

    // Reset "myTab"
    this.hrPerformanceVariable1 = true;
    this.hrPerformanceVariable2 = false;
    this.hrPerformanceVariable3 = false;
  }

  GetBrand(type: any, event: any) {
    this.patchesRowArray = [];
    if (type == 'load') {
      this.loading = true;
      this.reportsService
        .GetSaveCriteriaByUserId(this.userId)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.getCriteriaId(this.userId);
          }
        });
    } else {
      // setTimeout(() => {
      this.dashboardCountryFilterItem = '';
      this.countryId = '';
      this.campusId = '';
      this.dashboardCampusFilterItem = '';
      this.dashboardJobCategoryFilterItem = '';
      this.dashboardDepartmentFilterItem = '';
      this.dashboardSourceFilterItem = '';
      (this.jobCategory = ''), (this.departmentId = '');
      this.jobPostId = '';
      (this.selectedCountryName = ''), (this.selectedcategoryName = '');
      this.selectedCcampusName = '';
      (this.selectedDepartmentName = ''),
        // this.resetTabs();
      // this.barChartData3 = [];
      // this.barChartData3OBJ = {};
      // this.barChartDataobj = {};
      // this.data.data = null;
      // this.data1.data = null;
      // this.myStackedData = null;
      this.sourceHireData = [];
      // alert("000000000")
      this.reportsService
        .GetBrandByUserId(this.userId)
        .subscribe((res: any) => {
          if (res) {
            this.brandArray = res;
            var texts = event.map(function (el: any) {
              return el.brandId;
            });
            var textsName = event.map(function (el: any) {
              return el.brandName;
            });
            this.brandId = texts;
            this.zeroexists = this.brandId.some((item: any) => item == 0);
            if (this.zeroexists == true) {
              this.brandFirstObj = [];
              this.brandFirstObj = [this.brandId[this.brandId.length - 1]];
              this.brandId = this.brandId[this.brandId.length - 1].toString();

              this.patchesRowArray = [];
              this.brandName = textsName[textsName.length - 1].toString();
            } else {
              this.brandId = this.brandId.toString();
              this.brandName = event.map(function (el: any) {
                return el.brandName;
              });
            }
            this.GetCountryByBrand(this.brandId, this.userId);
            // this.GetAllFilters('change');
          }
        });
      // }, 800);
    }
  }
  /****************GetBrand on Load & Change******************/

  /****************GetCountryByBrand******************/
  GetCountryByBrand(brandId: any, userId: any) {
    this.reportsService
      .GetCountryByBrand(brandId, userId)
      .subscribe((result: any) => {
        if (result) {
          this.countryArray = [];
          this.countryArray = result;
          this.campusArr = [];
          this.jobCategoryArr = [];
          this.departmentArr = [];
        }
      });
  }
  /****************GetCountryByBrand******************/

  /****************GetCampusByCountry******************/
  GetCountry(event: any) {
    var texts = event.map(function (el: any) {
      return el.countryId;
    });
    this.campusId = '';
    this.dashboardCampusFilterItem = '';
    this.dashboardJobCategoryFilterItem = '';
    this.dashboardDepartmentFilterItem = '';
    (this.jobCategory = ''),
      (this.departmentId = ''),
      (this.jobPostId = ''),
      (this.selectedCountryName = ''),
      (this.selectedcategoryName = ''),
      (this.selectedCcampusName = ''),
      (this.selectedDepartmentName = ''),
      (this.dashboardSourceFilterItem = '');

    this.selectedCountryName = event.map(function (el: any) {
      return el.countryName;
    });

    this.countryId = texts;
    this.countryId = this.countryId.toString();
    this.reportsService
      .GetCampusByCountry(this.brandId, this.countryId, this.userId)
      .subscribe((result: any) => {
        this.campusArr = [];
        this.jobCategoryArr = [];
        this.departmentArr = [];

        this.dashboardCampusFilterItem = '';
        this.dashboardJobCategoryFilterItem = '';
        this.dashboardDepartmentFilterItem = '';

        result?.forEach((element: any) => {
          var cumpusesasrr = element.campuses;
          cumpusesasrr.forEach((element1: any) => {
            this.campusArr.push(element1);
          });
        });
        // this.GetAllFilters('change');
      });
  }
  /****************GetCampusByCountry******************/

  /****************GetCampusByCountry******************/
  GetCampus(event: any) {
    var texts = event.map(function (el: any) {
      return el.campusId;
    });
    this.dashboardJobCategoryFilterItem = '';
    this.dashboardDepartmentFilterItem = '';
    (this.jobCategory = ''),
      (this.departmentId = ''),
      (this.jobPostId = ''),
      (this.selectedcategoryName = ''),
      (this.selectedDepartmentName = ''),
      (this.dashboardSourceFilterItem = '');

    this.selectedCcampusName = event.map(function (el: any) {
      return el.campusName;
    });
    this.campusId = texts;
    this.campusId = this.campusId.toString();
    this.jobCategoryArr = [];
    this.departmentArr = [];

    this.dashboardJobCategoryFilterItem = '';
    this.dashboardDepartmentFilterItem = '';

    this.reportsService
      .GetJobCategoryByCampus(this.brandId, this.countryId, this.campusId)
      .subscribe((result: any) => {
        this.jobCategoryArr = result;
      });
  }
  /****************GetCampusByCountry******************/

  /****************GetCategoryByCampus******************/
  GetCategory(event: any) {
    var texts = event.map(function (el: any) {
      return el.jobCategory;
    });

    this.dashboardDepartmentFilterItem = '';
    this.dashboardSourceFilterItem = '';
    (this.departmentId = ''),
      (this.jobPostId = ''),
      (this.selectedDepartmentName = ''),
      (this.selectedcategoryName = event.map(function (el: any) {
        return el.categoryName;
      }));

    this.jobCategory = texts;
    this.jobCategory = this.jobCategory.toString();
    this.departmentArr = [];

    this.dashboardDepartmentFilterItem = '';

    this.reportsService
      .GetDepartmentByCategory(
        this.brandId,
        this.countryId,
        this.campusId,
        this.jobCategory
      )
      .subscribe((result: any) => {
        this.departmentArr = result;
      });
  }
  /****************GetCategoryByCampus******************/

  /****************GetDepartment******************/
  GetDepartment(event: any) {
    var texts = event.map(function (el: any) {
      return el.departmentId;
    });
    this.dashboardSourceFilterItem = '';
    (this.departmentId = ''),
      (this.jobPostId = ''),
      (this.selectedDepartmentName = event.map(function (el: any) {
        return el.departmentName;
      }));
    this.departmentId = texts;
    this.departmentId = this.departmentId.toString();
  }
  /****************GetDepartment******************/

  /****************GetSources******************/
  GetSources() {
    this.reportsService.GetSources().subscribe((result: any) => {
      this.sourceArr = result;
    });
  }
  GetSourceId(event: any) {
    var texts = event.map(function (el: any) {
      return el.jobPostId;
    });

    this.jobPostId = texts;
    this.jobPostId = this.jobPostId.toString();
  }
  /****************GetSources******************/

  /***************Jobs(Positions)**************/
  skeletonOne : boolean = true;
  GetjobPositions(obj: any) {
    this.reportsService.GetjobPositions(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonOne = false;
      }
      this.jobPosotionsArr = result;
      this.activeJobs = this.jobPosotionsArr.activeJobs;
      this.pendingJobs = this.jobPosotionsArr.pendingJobs;
      this.closedJobs = this.jobPosotionsArr.closedJobs;
      this.totalJobs = this.jobPosotionsArr.totalJobs;
      this.activeVacancyCount = this.jobPosotionsArr.activeVacancyCount;
      this.closedVacancyCount = this.jobPosotionsArr.closedVacancyCount;
      this.totalVacancyCount =
        this.jobPosotionsArr.closedVacancyCount +
        this.jobPosotionsArr.activeVacancyCount;
    });
  }
  /***************Jobs(Positions)**************/

  /*******Academics & Non Academics***********/
  skeletonTwo : boolean = true;
  GetAcademics(obj: any) {
    this.reportsService.GetAcademics(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonTwo = false;
      }
      this.academicsArr = result;
      this.academic = this.academicsArr.academic;
      this.nonAcademic = this.academicsArr.nonAcademic;
      this.totalCategoryCount = this.academicsArr.totalCategoryCount;
      this.academicPercentage = this.academicsArr.academicPercentage;
      this.nonAcademicPercentage = this.academicsArr.nonAcademicPercentage;
      this.academicVacencyCount = this.academicsArr.academicVacencyCount;
      this.nonAcademicVacencyCount = this.academicsArr.nonAcademicVacencyCount;
      this.academicVacencyPercentage =
        this.academicsArr.academicVacencyPercentage;
      this.nonAcademicVacencyPercentage =
        this.academicsArr.nonAcademicVacencyPercentage;
    });
  }
  /*******Academics & Non Academics************/

  /***********New Vs Replacement************/
  skeletonThree : boolean = true;
  GetNewReplacements(obj: any) {
    this.reportsService.GetNewReplacements(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonThree = false;
      }
      this.newReplacementsArr = result;
      this.new = this.newReplacementsArr.new;
      this.replacement = this.newReplacementsArr.replacement;
      this.newAverageString = this.newReplacementsArr.newAverageString;
      this.replacementAverageString =
        this.newReplacementsArr.replacementAverageString;
      this.newVacancyPercentage = this.newReplacementsArr.newVacancyPercentage;
      this.newVacancyCount = this.newReplacementsArr.newVacancyCount;
      this.replacementVacancyCount =
        this.newReplacementsArr.replacementVacancyCount;
      this.replacementVacancyPercentage =
        this.newReplacementsArr.replacementVacancyPercentage;
    });
  }
  /************New Vs Replace****************/

  /***********Selected Applicants************/
  skeletonFour : boolean = true;
  GetSelectedApplicants(obj: any) {
    this.reportsService.GetSelectedApplicants(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonFour = false;
      }
      this.selectedApplicantsObj = result;
      this.totalApplicants = this.selectedApplicantsObj.applicants;
      this.malePercentage = this.selectedApplicantsObj.malePercentage;
      this.femalePercentage = this.selectedApplicantsObj.femalePercentage;
    });
  }
  /************Selected Applicants************/

  /***********Status Distribution************/
  skeletonFive : boolean = true;
  GetStatusDistribution(obj: any) {
    this.reportsService.GetStatusDistribution(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonFive = false;
      }
      this.statusDistributionObj = result;
      this.selectednotselectedapplicants =
        this.statusDistributionObj.applicants;
      this.selectedPercentage = this.statusDistributionObj.selectedPercentage;
      this.notSelectedPercentage =
        this.statusDistributionObj.notSelectedPercentage;
    });
  }
  /************Status Distribution*************/

  /***********Status Distribution************/
  skeletonSix : boolean = true;
  GetTalentRequisition(obj: any) {
    this.reportsService.GetTalentRequisition(obj).subscribe((result: any) => {
      if (result) {
        this.skeletonSix = false;
      }
      this.talentRequisitionObj = result;
      this.accepted = this.talentRequisitionObj.accepted;
      this.expired = this.talentRequisitionObj.expired;
      this.jobClosed = this.talentRequisitionObj.jobClosed;
      this.acceptedAverage = this.talentRequisitionObj.acceptedAverage;
      this.expiredAverage = this.talentRequisitionObj.expiredAverage;
      this.jobClosedAverage = this.talentRequisitionObj.jobClosedAverage;
      this.totaltrf = this.talentRequisitionObj.totaltrf;
    });
  }
  /************Status Distribution*************/

  /***********Stages Overview By Country, Brand, Campus************/
  dataStages: any = {};

  @ViewChild('stagesCanvas', { static: false }) stagesCanvas: any;
  @ViewChild('stagesChartContainer', { static: false }) stagesChartContainer: any;
  @ViewChild('stagesCanvas2', { static: false }) stagesCanvas2: any;
  @ViewChild('stagesChartContainer2', { static: false }) stagesChartContainer2: any;
  @ViewChild('stagesCanvas3', { static: false }) stagesCanvas3: any;
  @ViewChild('stagesChartContainer3', { static: false }) stagesChartContainer3: any;
  @ViewChild('stagesCanvas31', { static: false }) stagesCanvas31: any;
  @ViewChild('stagesChartContainer31', { static: false }) stagesChartContainer31: any;
  @ViewChild('stagesCanvas56', { static: false }) stagesCanvas56: any;
  @ViewChild('stagesChartContainer56', { static: false }) stagesChartContainer56: any;

  anotherChartRendering2: any[] = [];



  chartLoading: boolean = false;
  tabsButtonDisabled: boolean = false;
  stagesChartOne: any;
  GetStagesOverviewByCountry(obj: any, stageType: any) {
    this.stagesOverviewObjr = [];
    if (stageType == 'Brand') {
      this.tabsButtonDisabled = true;
      // if (this.chart) {
      //   this.chart.destroy();
      // }
      this.globalPosloadingTime = true;

      this.barChartData2 = [];
      this.reportsService.GetStagesOverviewbyBrand(obj).subscribe((res: any) => {
        if (res) {
          this.globalPosloadingTime = false;
          setTimeout(() => {
            this.tabsButtonDisabled = false;
          }, 2000);
        }
        var result = res;
        this.stagesOverviewObjr = result;
            var overviewArr = this.stagesOverviewObjr.overview;
            var mainArr = this.stagesOverviewObjr.mainArray;
            var texts = overviewArr.map(function (el: any) {
              return el.country;
            });
            this.country = texts;
            this.barChartLabels = this.country;

        var mainArr = result.mainArray;

        for (let i = 0; i < mainArr.length; i++) {
          this.barChartDataobj = {
            data: mainArr[i].data,
            label: mainArr[i].type,
            backgroundColor: this.barChartDatabgColor[i]
          }
          this.barChartData.push(this.barChartDataobj);
        }
        console.log(this.barChartData, "iiiiiiiiiiiiiioooooooooooooooo");



        setTimeout(() => {
          const labels: any[] = this.barChartLabels;
          const datasets: any[] = this.barChartData.map((dataset: any) => ({
            ...dataset,
            barThickness: 20 // Set the bar thickness to 30px
          }));
          const barThickness = 50;
          const barMargin = 170;
          const chartWidth = labels.length * (barThickness + barMargin);

          this.renderer.setStyle(this.stagesChartContainer?.nativeElement, 'width', `${chartWidth}px`);
          // if (this.chart) {
          //   this.chart.destroy();
          // }
          if (this.stagesCanvas?.nativeElement) {
            const ctx = this.stagesCanvas?.nativeElement.getContext('2d');

            if (ctx) {
              this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                  labels: labels,
                  datasets: datasets,
                },
                options: {
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20
                  },
                  plugins: {
                    legend: {
                      align: 'start',
                      position: 'bottom',
                      labels: {
                        boxWidth: 8,
                        padding: 30,
                        boxHeight: 16,
                        borderRadius: 2,
                        useBorderRadius: true,
                      }
                    },
                    datalabels: {
                      display: true,
                      anchor: 'end',
                      align: 'top',
                      formatter: (value: any) => value, // Display the value directly on each bar
                      color: 'black',
                      font: {
                        size: 9,
                        weight: 'normal'
                      }
                    },
                  },
                  // plugins: {
                  //   datalabels: {
                  //     display: (context: any) => {
                  //       return context.datasetIndex === context.chart.data.datasets.length - 1;
                  //     },
                  //     anchor: 'end',
                  //     align: 'end',
                  //     formatter: (value: any, context: any) => {
                  //       if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                  //         const dataIndex = context.dataIndex;
                  //         const total = context.chart.data.datasets
                  //           .map((dataset: any) => dataset.data[dataIndex])
                  //           .reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                  //         return total;
                  //       }
                  //       return null;
                  //     },
                  //     color: 'black',
                  //     font: {
                  //       weight: 'bold'
                  //     }
                  //   }
                  // },
                  scales: {
                    x: {
                      stacked: false,
                      grid: {
                        drawTicks: false,
                        display: false
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                    y: {
                      stacked: false,
                      // beginAtZero: true,
                      grid: {
                        drawTicks: false,
                        display: true,
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                  },
                },
              });
            } else {
              console.error('Canvas context not available');
            }
          } else {
            console.error('Canvas element not found');
          }
        }, 2000);



      });
      // if (this.chart) {
      //   this.chart.destroy();
      // }


    } else if (stageType == 'Country') {
      // this.destroyChart();
      this.chartLoading = true;
      this.tabsButtonDisabled = true;
      this.barChartData = [];
      this.anotherChartRendering2 = [];
      this.index = 1;
      this.reportsService.GetStagesOverview(obj).subscribe((result: any) => {
        this.anotherChartRendering2 = result.overview;
        if (result) {
          this.chartLoading = false;
          setTimeout(() => {
            this.tabsButtonDisabled = false;
          }, 2000);
        }
        // console.log(result, "::::::::::::::::::");

        setTimeout(() => {
          this.stagesOverviewObjr = result;
          var overviewArr = this.stagesOverviewObjr.overview;
          var mainArr = this.stagesOverviewObjr.mainArray;

          var texts = overviewArr.map(function (el: any) {
            return el.country;
          });
          this.country = texts;
          this.barChartLabels = this.country;

          // const arr = this.country
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");

          // mainArr.forEach((element: any) => {
          //   this.barChartDataobj = {
          //     data: element.data,
          //     label: element.type,
          //     backgroundColor: element.barChartDatabgColor,
          //   };
          //   this.barChartData.push(this.barChartDataobj);
          // });
          for (let i = 0; i < mainArr.length; i++) {
            this.barChartDataobj = {
              data: mainArr[i].data,
              label: mainArr[i].type,
              backgroundColor: this.barChartDatabgColor[i]
            }
            this.barChartData.push(this.barChartDataobj);
          }
          const labels: any[] = this.barChartLabels
          const datasets: any[] = this.barChartData.map((dataset: any) => ({
            ...dataset,
            barThickness: 20 // Set the bar thickness to 30px
          }));
          const barThickness = 50;
          const barMargin = 170;
          const chartWidth = labels.length * (barThickness + barMargin);
          this.renderer.setStyle(this.stagesChartContainer56.nativeElement, 'width', `${chartWidth}px`);
          if (this.stagesCanvas56.nativeElement) {
            const ctx = this.stagesCanvas56.nativeElement.getContext('2d');
            if (ctx) {
              this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                  labels: labels,
                  datasets: datasets,
                },
                options: {
                  maintainAspectRatio: false,
                  layout: {
                    padding: 30
                  },
                  plugins: {

                    legend: {
                      align: 'start',
                      position: 'bottom',
                      fullSize: true,
                      maxWidth: 20,
                      labels: {
                        boxWidth: 8,
                        padding: 30,
                        boxHeight: 16,
                        borderRadius: 2,
                        useBorderRadius: true,
                      }

                    },
                    datalabels: {
                      display: true,
                      anchor: 'end',
                      align: 'top',
                      formatter: (value: any) => value, // Display the value directly on each bar
                      color: 'black',
                      font: {
                        size: 9,
                        weight: 'normal'
                      }
                    },
                  },
                  // plugins: {
                  //   datalabels: {
                  //     display: (context: any) => {
                  //       return context.datasetIndex === context.chart.data.datasets.length - 1;
                  //     },
                  //     anchor: 'end',
                  //     align: 'end',
                  //     formatter: (value: any, context: any) => {
                  //       if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                  //         const dataIndex = context.dataIndex;
                  //         const total = context.chart.data.datasets
                  //           .map((dataset: any) => dataset.data[dataIndex])
                  //           .reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                  //         return total;
                  //       }
                  //       return null;
                  //     },
                  //     color: 'black',
                  //     font: {
                  //       weight: 'bold'
                  //     }
                  //   }
                  // },
                  scales: {
                    x: {
                      stacked: false,
                      grid: {
                        drawTicks: false,
                        display: false
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                    y: {
                      stacked: false,
                      // beginAtZero: true,
                      grid: {
                        drawTicks: false,
                        display: true,
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                  },
                },
              });
            } else {
              console.error('Canvas context not available');
            }
          }
        }, 1000);
      });
    } else if (stageType == 'Campus') {
      // this.destroyChart();
      // alert('Please select');
      this.chartLoading = true;
      this.tabsButtonDisabled = true;
      this.dataStages = {};
      this.index = 2;
      this.barChartData = [];
      this.anotherChartRendering2 = [];
      this.reportsService
        .GetStagesOverviewbyCampus(obj)
        .subscribe((result: any) => {
          if (result) {
            this.chartLoading = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          setTimeout(() => {
            this.stagesOverviewObjr = result;
            this.anotherChartRendering2 = result.overview;
            var overviewArr = this.stagesOverviewObjr.overview;
            var mainArr = this.stagesOverviewObjr.mainArray;

            var texts = overviewArr.map(function (el: any) {
              return el.country;
            });
            this.country = texts;
            this.barChartLabels = this.country;

            // const arr = this.country
            // for (var i = 0; i < arr.length; i++) {
            //   arr[i] = arr[i].toLowerCase()
            // }

            // for (var i = 0; i < arr.length; i++) {
            //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            // }
            // const str2 = arr.join(" ");

            // for (let k = 0; k < mainArr.length; k++) {
            //   this.barChartDataobj = {
            //     data: mainArr[k].data,
            //     label: mainArr[k].type,
            //     // backgroundColor: this.barChartDatabgColor[k],
            //   };
            //   this.barChartData.push(this.barChartDataobj);
            // }
            for (let i = 0; i < mainArr.length; i++) {
              this.barChartDataobj = {
                data: mainArr[i].data,
                label: mainArr[i].type,
                backgroundColor: this.barChartDatabgColor[i]
              }
              this.barChartData.push(this.barChartDataobj);
            }
            // console.log(this.barChartData,'======his.barChartData')
            const labels: any[] = this.barChartLabels
            const datasets: any[] = this.barChartData.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            this.renderer.setStyle(this.stagesChartContainer3.nativeElement, 'width', `${chartWidth}px`);
            if (this.stagesCanvas3?.nativeElement) {
              const ctx = this.stagesCanvas3.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    layout: {
                      padding: 30
                    },
                    maintainAspectRatio: false,
                    //
                    plugins: {

                      legend: {
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value,
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      }
                    },
                    // plugins: {
                    //   datalabels: {
                    //     display: (context: any) => {
                    //       return context.datasetIndex === context.chart.data.datasets.length - 1;
                    //     },
                    //     anchor: 'end',
                    //     align: 'end',
                    //     formatter: (value: any, context: any) => {
                    //       if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                    //         const dataIndex = context.dataIndex;
                    //         const total = context.chart.data.datasets
                    //           .map((dataset: any) => dataset.data[dataIndex])
                    //           .reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                    //         return total;
                    //       }
                    //       return null;
                    //     },
                    //     color: 'black',
                    //     font: {
                    //       weight: 'bold'
                    //     }
                    //   }
                    // },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 1000);
          // this.dataStages = {
          //   labels: texts,
          //   datasets: this.barChartData,
          // };


        });
    }
  }
  /************Stages Overview By Country, Brand, Campus*************/

  /************GetSourceHire*************/
  sourceHire: any = [];
  sourceDataArr: any = [];
  mytotalCount: any;
  myOwnOptions: any = {};
  GetSourceHire(obj: any, sourceType: any) {
    this.tabsButtonDisabled = true;
    this.data = {};
    this.reportsService.GetSourceHire(obj).subscribe((result: any) => {
    if (result) {
      setTimeout(() => {
        this.tabsButtonDisabled = false;
      }, 1000);
    }
      this.mytotalCount = result.totalCount;
      // console.log(this.mytotalCount);

      this.sourceHire = result.sourceCounts;
      var texts = this.sourceHire.map(function (el: any) {
        return el.source;
      });
      var texts1 = this.sourceHire.map(function (el: any) {
        return el.count;
      });
      var texts2 = this.sourceHire.map(function (el: any) {
        return el.percentage;
      });

      if (sourceType == 'Number') {
        // console.log(result, '===============result............................');
        this.barChartLabels1 = [];
        this.barChartData1 = [];
        this.data = {
          labels: texts,
          datasets: [
            {
              data: texts1,
              backgroundColor: ['#8B6FE8', '#EE90FC', '#5FDDFF'],
              hoverBackgroundColor: ['#8B6FE8', '#EE90FC', '#5FDDFF'],
            },
          ],
        };
        this.myOwnOptions = {
          // aspectRatio: 0.6,
          cutout: '70%',
          //   layout:{
          //     padding:15
          //  },
          plugins: {
            legend: {
              align: 'center',
              position: 'bottom',
              labels: {
                boxWidth: 8,
                padding: 15,
                boxHeight: 16,
                borderRadius: 2,
                useBorderRadius: true,
              }
            },
            datalabels: {
              display: true,
              anchor: 'center',
              align: 'center',
              formatter: (value: any) => value, // Display the value directly on each bar
              color: '#ffffff',
              font: {
                size: 9,
                weight: 'normal'
              }
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  let label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.raw !== null) {
                    label += context.raw;
                  }
                  return label;
                },
              },
            },
          },
        };
      }
      if (sourceType == 'Percentage') {
        this.data = {
          labels: texts,
          datasets: [
            {
              data: texts2,
              backgroundColor: ['#8B6FE8', '#EE90FC', '#5FDDFF'],
              hoverBackgroundColor: ['#8B6FE8', '#EE90FC', '#5FDDFF'],
            },
          ],
        };
        this.myOwnOptions = {
          cutout: '70%',
          //   layout:{
          //     padding:15
          //  },
          plugins: {
            // plugins: {
            datalabels: {
              display: true,
              anchor: 'center',
              align: 'center',
              formatter: (value: any) => `${value}%`,
              color: '#ffffff',
              font: {
                size: 9,
                weight: 'normal'
              }
              // }
            },
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                boxWidth: 8,
                padding: 15,
                boxHeight: 16,
                borderRadius: 2,
                useBorderRadius: true,
              }
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  let label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.raw !== null) {
                    label += context.raw + '%';
                  }
                  return label;
                },
              },
            },
          },
        };
      }
    });
  }
  /************GetSourceHire*************/







  /*************GetOpenClosedPositions*********/

  positionsArr: any = [];
  totalOpen: any;
  totalClosed: any;
  totalPositions: any;
  brandPosition: any = [];
  barChartData2OBJ: any = {};
  totalNoApplicants: any;
  totalSelectedApplicants: any;
  anotherChartRendering: any = [];
  globalPosloadingTime: boolean = false;
  GetOpenClosedPositions(obj: any, positionType: any) {

      if (this.chart) {
            this.chart.destroy();
          }
    if (positionType == 'Brand') {
      this.tabsButtonDisabled = true;
      // if (this.chart) {
      //   this.chart.destroy();
      // }
      this.globalPosloadingTime = true;

      this.barChartData2 = [];
      this.reportsService.Getopenclosedglobally(obj).subscribe((res: any) => {
        if (res) {
          this.globalPosloadingTime = false;
          setTimeout(() => {
            this.tabsButtonDisabled = false;
          }, 2000);
        }
        var result = res;
        this.anotherChartRendering = res.response
        // console.log(this.anotherChartRendering.length, "......................mmmmmmmmmmmmmmmmmmmmmm.........");
        this.data2 = {
          type: 'doughnut',
          labels: ['Active Vacancies', 'Closed Vacancies'],
          datasets: [
            {
              // label: 'Data 1',
              data: [
                result.overallStatistics.totalNoOfActivePositions,
                result.overallStatistics.totalNoOfClosedPositions,
              ],
              fill: false,
              backgroundColor: ['#B7E334', '#8B6FE8'],
            },
          ],
        };

        this.totalPositions = result.overallStatistics.totalNoOfPositions;
        this.totalClosedJobs = result.overallStatistics.totalClosedJobs;
        this.totalActiveJobs = result.overallStatistics.totalActiveJobs;
        this.totalClosedWithApplicant = result.overallStatistics.totalClosedWithApplicant;
        this.totalDirectlyClosedApplicants = result.overallStatistics.totalDirectlyClosed;
        this.positionsArr = result.response;
        var postArr = this.positionsArr;
        var texts = postArr.map(function (el: any) {
          return el.BrandName;
        });


        this.brandPosition = texts;
        this.barChartLabels2 = this.brandPosition;

        // const arr = this.brandPosition
        // for (var i = 0; i < arr.length; i++) {
        //   arr[i] = arr[i].toLowerCase()
        // }

        // for (var i = 0; i < arr.length; i++) {
        //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        // }
        // const str2 = arr.join(" ");

        var mainArr = result.mainArray;

        mainArr.forEach((element: any) => {
          if (element.type == 'No. Of Applicants') {
            this.barChartData2OBJ = {
              data: element.data,
              label: 'No. Of Applicants',
              backgroundColor: '#8B6FE8',
            };
          } else if (element.type == 'No. Of Positions') {
            this.barChartData2OBJ = {
              data: element.data,
              label: "No. Of Vacancies",
              backgroundColor: '#B7E334',
            };
          }
          else if (element.type == 'Selected Applicants') {
            this.barChartData2OBJ = {
              data: element.data,
              label: 'Selected Applicants',
              backgroundColor: '#EE90FC',
            };
          }
          this.barChartData2.push(this.barChartData2OBJ);
        });


        setTimeout(() => {
          const labels: any[] = this.brandPosition;
          const datasets: any[] = this.barChartData2.map((dataset: any) => ({
            ...dataset,
            barThickness: 20 // Set the bar thickness to 30px
          }));
          const barThickness = 50;
          const barMargin = 170;
          const chartWidth = labels.length * (barThickness + barMargin);

          this.renderer.setStyle(this.chartContainer?.nativeElement, 'width', `${chartWidth}px`);
          // if (this.chart) {
          //   this.chart.destroy();
          // }
          if (this.canvas?.nativeElement) {
            const ctx = this.canvas?.nativeElement.getContext('2d');

            if (ctx) {
              this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                  labels: labels,
                  datasets: datasets,
                },
                options: {
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20
                  },
                  plugins: {
                    legend: {
                      align: 'start',
                      position: 'bottom',
                      labels: {
                        boxWidth: 8,
                        padding: 30,
                        boxHeight: 16,
                        borderRadius: 2,
                        useBorderRadius: true,
                      }
                    },
                    datalabels: {
                      display: true,
                      anchor: 'end',
                      align: 'top',
                      formatter: (value: any) => value, // Display the value directly on each bar
                      color: 'black',
                      font: {
                        size: 9,
                        weight: 'normal'
                      }
                    },
                  },
                  // plugins: {
                  //   datalabels: {
                  //     display: (context: any) => {
                  //       return context.datasetIndex === context.chart.data.datasets.length - 1;
                  //     },
                  //     anchor: 'end',
                  //     align: 'end',
                  //     formatter: (value: any, context: any) => {
                  //       if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                  //         const dataIndex = context.dataIndex;
                  //         const total = context.chart.data.datasets
                  //           .map((dataset: any) => dataset.data[dataIndex])
                  //           .reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                  //         return total;
                  //       }
                  //       return null;
                  //     },
                  //     color: 'black',
                  //     font: {
                  //       weight: 'bold'
                  //     }
                  //   }
                  // },
                  scales: {
                    x: {
                      stacked: false,
                      grid: {
                        drawTicks: false,
                        display: false
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                    y: {
                      stacked: false,
                      // beginAtZero: true,
                      grid: {
                        drawTicks: false,
                        display: true,
                      },
                      border: {
                        dash: [5, 5],
                        display: true
                      },
                    },
                  },
                },
              });
            } else {
              console.error('Canvas context not available');
            }
          } else {
            console.error('Canvas element not found');
          }
        }, 2000);



      });
      // if (this.chart) {
      //   this.chart.destroy();
      // }

    }
    if (positionType == 'Country') {
      this.globalPosloadingTime = true;
      this.tabsButtonDisabled = true;
      this.anotherChartRendering = [];
      this.barChartData2 = [];
      this.reportsService
        .GetopenclosedgloballyCountry(obj)
        .subscribe((res: any) => {
          if (res) {
            this.globalPosloadingTime = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          console.log(res, '==========Country');
          this.anotherChartRendering = res.response;
          var result = res;
          this.data2 = {
            type: 'doughnut',
            labels: ['Total Applicants', 'Selected Applicants'],
            datasets: [
              {
                label: 'Data 1',
                data: [
                  result.overallStatistics.totalNoOfActivePositions,
                  result.overallStatistics.totalNoOfClosedPositions,
                ],
                fill: false,
                backgroundColor: ['#B7E334', '#8B6FE8'],
              },
            ],
          };
          this.totalPositions = result.overallStatistics.totalNoOfPositions;
          this.totalClosedJobs = result.overallStatistics.totalClosedJobs;
          this.totalActiveJobs = result.overallStatistics.totalActiveJobs;
          this.totalClosedWithApplicant =
            result.overallStatistics.totalClosedWithApplicant;
          this.totalDirectlyClosedApplicants =
            result.overallStatistics.totalDirectlyClosed;
          this.positionsArr = result.response;
          var postArr = this.positionsArr;
          var texts = postArr.map(function (el: any) {
            return el.CountryName;
          });
          this.brandPosition = texts;
          this.barChartLabels2 = this.brandPosition;

          // const arr = this.brandPosition
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");

          var mainArr = result.mainArray;

          mainArr.forEach((element: any) => {
            if (element.type == 'No. Of Applicants') {
              this.barChartData2OBJ = {
                data: element.data,
                label: 'No. Of Applicants',
                backgroundColor: '#8B6FE8',
              };
            } else if (element.type == 'No. Of Positions') {
              this.barChartData2OBJ = {
                data: element.data,
                label: "No. Of Vacancies",
                backgroundColor: '#B7E334',
              };
            }
            else if (element.type == 'Selected Applicants') {
              this.barChartData2OBJ = {
                data: element.data,
                label: 'Selected Applicants',
                backgroundColor: '#EE90FC',
              };
            }
            this.barChartData2.push(this.barChartData2OBJ);
          });

          setTimeout(() => {
            const labels: any[] = this.brandPosition;
            const datasets: any[] = this.barChartData2.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);

            this.renderer.setStyle(this.chartContainer2.nativeElement, 'width', `${chartWidth}px`);

            if (this.canvas2?.nativeElement) {
              const ctx = this.canvas2.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        position: 'bottom',
                        align: 'start',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            } else {
              console.error('Canvas element not found');
            }
          }, 1500);
        });
      // mainArr.forEach((element: any) => {
      //   if (element.type == 'No. Of Applicants') {
      //     this.barChartData2OBJ = {
      //       data: element.data,
      //       label: element.type,
      //       backgroundColor: '#B7E334',
      //       // stack: 'stack1',
      //     };
      //   } else if (element.type == 'No. Of Positions') {
      //     this.barChartData2OBJ = {
      //       data: element.data,
      //       label: "No. Of Vacancies",
      //       backgroundColor: '#F29C49',
      //       // stack: 'stack1',
      //     };
      //   }
      //   else if (element.type == 'Selected Applicants') {
      //     this.barChartData2OBJ = {
      //       data: element.data,
      //       label: element.type,
      //       backgroundColor: '#E4CB4D',
      //       // stack: 'stack1',
      //     };
      //   }

      //   this.barChartData2.push(this.barChartData2OBJ);
      // });

      // if (this.chart) {
      //   this.chart.destroy();
      // }
    }
    if (positionType == 'Campus') {
      this.tabsButtonDisabled = true;
      this.globalPosloadingTime = true;
      this.anotherChartRendering = [];
      this.barChartData2 = [];
      this.reportsService
        .GetopenclosedgloballyCampus(obj)
        .subscribe((res: any) => {
          if (res) {
            this.globalPosloadingTime = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          // console.log(res, "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");

          var result = res;
          this.anotherChartRendering = res.response;
          this.data2 = {
            type: 'doughnut',
            labels: ['Total Applicants', 'Selected Applicants'],
            datasets: [
              {
                label: 'Data 1',
                data: [
                  result.overallStatistics.totalNoOfActivePositions,
                  result.overallStatistics.totalNoOfClosedPositions,
                ],
                fill: false,
                backgroundColor: ['#B7E334', '#8B6FE8'],
              },
            ],
          };
          this.totalPositions = result.overallStatistics.totalNoOfPositions;
          this.totalClosedJobs = result.overallStatistics.totalClosedJobs;
          this.totalActiveJobs = result.overallStatistics.totalActiveJobs;
          this.totalClosedWithApplicant =
            result.overallStatistics.totalClosedWithApplicant;
          this.totalDirectlyClosedApplicants =
            result.overallStatistics.totalDirectlyClosed;
          this.positionsArr = result.response;
          var postArr = this.positionsArr;
          var texts = postArr.map(function (el: any) {
            return el.CampusName;
          });
          this.brandPosition = texts;
          this.barChartLabels2 = this.brandPosition;

          // const arr = this.brandPosition
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");


          var mainArr = result.mainArray;


          mainArr.forEach((element: any) => {
            if (element.type == 'No. Of Applicants') {
              this.barChartData2OBJ = {
                data: element.data,
                label: 'No. Of Applicants',
                backgroundColor: '#8B6FE8',
              };
            } else if (element.type == 'No. Of Positions') {
              this.barChartData2OBJ = {
                data: element.data,
                label: "No. Of Vacancies",
                backgroundColor: '#B7E334',
              };
            }
            else if (element.type == 'Selected Applicants') {
              this.barChartData2OBJ = {
                data: element.data,
                label: 'Selected Applicants',
                backgroundColor: '#EE90FC',
              };
            }
            this.barChartData2.push(this.barChartData2OBJ);
          });

          setTimeout(() => {
            const labels: any[] = this.brandPosition;
            const datasets: any[] = this.barChartData2.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);

            this.renderer.setStyle(this.chartContainer3.nativeElement, 'width', `${chartWidth}px`);

            if (this.canvas3?.nativeElement) {
              const ctx = this.canvas3.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            } else {
              console.error('Canvas element not found');
            }
          }, 1500);
          // mainArr.forEach((element: any) => {
          //   if (element.type == 'No. Of Applicants') {
          //     this.barChartData2OBJ = {
          //       data: element.data,
          //       label: element.type,
          //       backgroundColor: '#B7E334',
          //       // stack: 'stack1',
          //     };
          //   } else if (element.type == 'No. Of Positions') {
          //     this.barChartData2OBJ = {
          //       data: element.data,
          //       label: "No. Of Vacancies",
          //       backgroundColor: '#F29C49',
          //       // stack: 'stack1',
          //     };
          //   }
          //   else if (element.type == 'Selected Applicants') {
          //     this.barChartData2OBJ = {
          //       data: element.data,
          //       label: element.type,
          //       backgroundColor: '#E4CB4D',
          //       // stack: 'stack1',
          //     };
          //   }

          //   this.barChartData2.push(this.barChartData2OBJ);
          // });
        });
      // if (this.chart) {
      //   this.chart.destroy();
      // }
    }

    //   var result={
    //     "response": [
    //     {
    //       "BrandId": 1,
    //       "BrandName": "GIIS",
    //       "TotalJobIdCount": 10,
    //       "TotalNoOfApplicantId": 73,
    //       "TotalNoOfSelectedApplicantId": 2,
    //       "TotalNoOfPositions": 146,
    //       "ClosedWithoutApplicants": 8,
    //       "ManualClosedApplicantIdCount": 2,
    //       "ManualClosedJobIdCount": 2,
    //       "DirectClosedWithApplicantId": 0,
    //       "DirectClosedJobIdCount": 0
    //     }
    //   ],
    //   "overallStatistics": {
    //     "totalNoOfPositions": 146,
    //     "totalActiveJobs": 10,
    //     "totalClosedJobs": 8,
    //     "totalDirectlyClosedApplicants": 0,
    //     "totalClosedWithApplicant": 2
    //   },
    //   "mainArray": [
    //     {
    //       "type": "No. Of Applicants",
    //       "data": [
    //         73,65
    //       ]
    //     },
    //     {
    //       "type": "Selected Applicants",
    //       "data": [
    //         2,34
    //       ]
    //     },
    //     {
    //       "type": "No. Of Vacancies",
    //       "data": [
    //         146,33
    //       ]
    //     }
    //   ]
    // }

    //   // if (positionType == 'Brand') {
    //     this.barChartData2 = [];
    //     this.barChartData2OBJ = {};
    //     this.reportsService
    //       .Getopenclosedglobally(obj)
    //       .subscribe((result: any) => {
    //         console.log(result,"=============")
    //         this.data2 = {
    //           type: 'doughnut',
    //           labels: ['Total Applicants', 'Selected Applicants'],
    //           datasets: [
    //             {
    //               label: 'Data 1',
    //               data: [result.totalApplicants,result.totalSelectedApplicants],
    //               fill: false,
    //               backgroundColor: ['#B7E334', '#8B6FE8'],
    //             },
    //           ],
    //         };

    //          this.totalNoApplicants = result.totalApplicants;
    //          this.totalSelectedApplicants = result.totalSelectedApplicants;
    //          this.totalPositions = result.totalPositions;
    //         // this.positionsArr = result.overview;
    //         // var postArr = this.positionsArr;
    //         // var texts = postArr.map(function (el: any) {
    //         //   return el.country;
    //         // });
    //         // this.brandPosition = texts;
    //         // this.barChartLabels2 = this.brandPosition;
    //         // var mainArr = result.mainArray;
    //         // mainArr.forEach((element: any) => {
    //         //   if (element.type == 'Open') {
    //         //     this.barChartData2OBJ = {
    //         //       data: element.data,
    //         //       label: element.type,
    //         //       backgroundColor: '#B7E334',
    //         //     };
    //         //   } else {
    //         //     this.barChartData2OBJ = {
    //         //       data: element.data,
    //         //       label: element.type,
    //         //       backgroundColor: '#8B6FE8',
    //         //     };
    //         //   }
    //         //   this.barChartData2.push(this.barChartData2OBJ);
    //         // });
    //       });
    //   // }
  }

  // GetOpenClosedPositions(obj: any, positionType: any) {
  //   if (positionType == 'Brand') {
  //     this.barChartData2 = [];
  //     this.barChartData2OBJ = {};
  //     this.reportsService
  //       .GetOpenClosedPositionsBrandWise(obj)
  //       .subscribe((result: any) => {
  //         this.data2 = {
  //           type: 'doughnut',
  //           labels: ['Open', 'Closed'],
  //           datasets: [
  //             {
  //               label: 'Data 1',
  //               data: [result.totalOpen, result.totalClosed],
  //               fill: false,
  //               backgroundColor: ['#B7E334', '#8B6FE8'],
  //             },
  //           ],
  //         };

  //         this.totalOpen = result.totalOpen;
  //         this.totalClosed = result.totalClosed;
  //         this.totalPositions = this.totalOpen + this.totalClosed;
  //         this.positionsArr = result.overview;
  //         var postArr = this.positionsArr;
  //         var texts = postArr.map(function (el: any) {
  //           return el.country;
  //         });
  //         this.brandPosition = texts;
  //         this.barChartLabels2 = this.brandPosition;
  //         var mainArr = result.mainArray;
  //         mainArr.forEach((element: any) => {
  //           if (element.type == 'Open') {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#B7E334',
  //             };
  //           } else {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#8B6FE8',
  //             };
  //           }
  //           this.barChartData2.push(this.barChartData2OBJ);
  //         });
  //       });
  //   } else if (positionType == 'Country') {
  //     this.barChartData2 = [];
  //     this.barChartData2OBJ = {};
  //     this.reportsService
  //       .GetOpenClosedPositionsCountryWise(obj)
  //       .subscribe((result: any) => {
  //         this.data2 = {
  //           type: 'doughnut',
  //           labels: ['Open', 'Closed'],
  //           datasets: [
  //             {
  //               label: 'Data 1',
  //               data: [result.totalOpen, result.totalClosed],
  //               fill: false,
  //               backgroundColor: ['#B7E334', '#8B6FE8'],
  //             },
  //           ],
  //         };

  //         console.log(this.data2, '==this.data2');
  //         this.totalOpen = result.totalOpen;
  //         this.totalClosed = result.totalClosed;
  //         this.totalPositions = this.totalOpen + this.totalClosed;
  //         this.positionsArr = result.overview;
  //         var postArr = this.positionsArr;
  //         var texts = postArr.map(function (el: any) {
  //           return el.country;
  //         });
  //         this.brandPosition = texts;
  //         this.barChartLabels2 = this.brandPosition;
  //         var mainArr = result.mainArray;
  //         mainArr.forEach((element: any) => {
  //           if (element.type == 'Open') {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#B7E334',
  //             };
  //           } else {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#8B6FE8',
  //             };
  //           }
  //           this.barChartData2.push(this.barChartData2OBJ);
  //         });
  //       });
  //   } else if (positionType == 'Campus') {
  //     this.barChartData2 = [];
  //     this.barChartData2OBJ = {};
  //     this.reportsService
  //       .GetOpenClosedPositionsCampusWise(obj)
  //       .subscribe((result: any) => {
  //         this.data2 = {
  //           type: 'doughnut',
  //           labels: ['Open', 'Closed'],
  //           datasets: [
  //             {
  //               label: 'Data 1',
  //               data: [result.totalOpen, result.totalClosed],
  //               fill: false,
  //               backgroundColor: ['#B7E334', '#8B6FE8'],
  //             },
  //           ],
  //         };

  //         this.totalOpen = result.totalOpen;
  //         this.totalClosed = result.totalClosed;
  //         this.totalPositions = this.totalOpen + this.totalClosed;
  //         this.positionsArr = result.overview;
  //         var postArr = this.positionsArr;
  //         var texts = postArr.map(function (el: any) {
  //           return el.country;
  //         });
  //         this.brandPosition = texts;
  //         this.barChartLabels2 = this.brandPosition;
  //         var mainArr = result.mainArray;
  //         mainArr.forEach((element: any) => {
  //           if (element.type == 'Open') {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#B7E334',
  //             };
  //           } else {
  //             this.barChartData2OBJ = {
  //               data: element.data,
  //               label: element.type,
  //               backgroundColor: '#8B6FE8',
  //             };
  //           }
  //           this.barChartData2.push(this.barChartData2OBJ);
  //         });
  //       });
  //   }
  // }
  /*************GetOpenClosedPositions*********/

  /*************GetNewReplacement*********/
  entityArr: any = [];
  brandEntity: any;
  barChartData3OBJ: any = {};
  anotherChartRenderingNew: any = [];
  @ViewChild('canvasNew2', { static: false }) canvasNew2: any;
  @ViewChild('chartContainerNew2', { static: false }) chartContainerNew2: any;
  @ViewChild('canvasNew3', { static: false }) canvasNew3: any;
  @ViewChild('chartContainerNew3', { static: false }) chartContainerNew3: any;
  @ViewChild('canvasTAT', { static: false }) canvasTAT: any;
  @ViewChild('chartContainerTAT', { static: false }) chartContainerTAT: any;
  newReplaceLoadingTime: boolean = false;

  GetNewReplacementEntity(obj: any, entityType: any) {
    this.tabsButtonDisabled = true;
    if (entityType == 'Brand') {
      this.newReplaceLoadingTime = true;
      this.anotherChartRenderingNew = [];
      this.barChartData3 = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByBrand(obj)
        .subscribe((result: any) => {
          if (result) {
            this.newReplaceLoadingTime = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNew = result.overview;
          // console.log(result.overview, "::::::::::::::::::::::::");

          this.entityArr = result.overview;
          var entArr = this.entityArr;
          var texts = entArr.map(function (el: any) {
            return el.brand;
          });

          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;

          // const arr = this.brandEntity
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");


          var mainArr = result.mainArray;
          this.dataNew = {
            type: 'doughnut',
            labels: ['New', 'Replacement'],
            datasets: [
              {
                // label: 'Data 1',
                data: [result.totalNewCount, result.totalReplacementCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositions = result.overallcount;
          this.totalNewCount = result.totalNewCount;
          this.totalReplacementCount = result.totalReplacementCount;
          mainArr.forEach((element: any) => {
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);
          });
          console.log(this.barChartData3,'this.barChartData3this.barChartData3this.barChartData3')

          setTimeout(() => {
            const labels: any[] = this.barChartLabels3
            const datasets: any[] = this.barChartData3.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;

            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            console.log(chartWidth)

            this.renderer.setStyle(this.chartContainerNew.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNew?.nativeElement) {
              const ctx = this.canvasNew.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        display: true,
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 2000);

        });
    } else if (entityType == 'Country') {
      this.newReplaceLoadingTime = true;
      this.barChartData3 = [];
      this.anotherChartRenderingNew = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByCountry(obj)
        .subscribe((result: any) => {
          if (result) {
            this.newReplaceLoadingTime = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNew = result.overview;
          this.entityArr = result.overview;
          var entArr = this.entityArr;
          var texts = entArr.map(function (el: any) {
            return el.country;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });

          this.dataNew = {
            type: 'doughnut',
            labels: ['New', 'Replacement'],
            datasets: [
              {
                label: 'Data 1',
                data: [result.totalNewCount, result.totalReplacementCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositions = result.overallcount;
          this.totalNewCount = result.totalNewCount;
          this.totalReplacementCount = result.totalReplacementCount;
          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;

          // const arr = this.brandEntity
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");


          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);
          });
          setTimeout(() => {
            const labels: any[] = this.barChartLabels3
            const datasets: any[] = this.barChartData3.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            this.renderer.setStyle(this.chartContainerNew2.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNew2?.nativeElement) {
              const ctx = this.canvasNew2.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        display: true,
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 2000);
        });
    } else if (entityType == 'Campus') {
      this.newReplaceLoadingTime = true;
      this.barChartData3 = [];
      this.anotherChartRenderingNew = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByCampus(obj)
        .subscribe((result: any) => {
          if (result) {
            this.newReplaceLoadingTime = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNew = result.overview;
          // console.log(result.overview, ":::::::::::::::::::;;;;;;;;;;;;;;;;;;;;");

          this.entityArr = result.overview;
          var entArr = this.entityArr;
          this.dataNew = {
            type: 'doughnut',
            labels: ['New', 'Replacement'],
            datasets: [
              {
                // label: 'Data 1',
                data: [result.totalNewCount, result.totalReplacementCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositions = result.overallcount;
          this.totalNewCount = result.totalNewCount;
          this.totalReplacementCount = result.totalReplacementCount;
          var texts = entArr.map(function (el: any) {
            return el.campus;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });
          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;

          // const arr = this.brandEntity
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");




          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            // console.log(element, '==element');
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);

          });
          setTimeout(() => {
            const labels: any[] = this.barChartLabels3
            const datasets: any[] = this.barChartData3.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            this.renderer.setStyle(this.chartContainerNew3.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNew3?.nativeElement) {
              const ctx = this.canvasNew3.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 2000);
        });
    }
  }
  /*************GetNewReplacement*********/












  /*************GetAcademicNonAcademic*********/
  barChartLabels3Aca:any=[]
  entityArrAca: any = [];
  brandEntityAca: any;
  barChartData3OBJAca: any = {};
  anotherChartRenderingNewAca: any = [];
  @ViewChild('canvasNew2Aca', { static: false }) canvasNew2Aca: any;
  @ViewChild('chartContainerNew2Aca', { static: false }) chartContainerNew2Aca: any;
  @ViewChild('canvasNew3Aca', { static: false }) canvasNew3Aca: any;
  @ViewChild('chartContainerNew3Aca', { static: false }) chartContainerNew3Aca: any;
  @ViewChild('canvasTATAca', { static: false }) canvasTATAca: any;
  @ViewChild('chartContainerTATAca', { static: false }) chartContainerTATAca: any;
  newReplaceLoadingTimeAca: boolean = false;

  GetAcademicNonAcademic(obj: any, entityType: any) {
     this.tabsButtonDisabled = true;
     if (entityType == 'Brand') {
       this.newReplaceLoadingTimeAca = true;
       this.anotherChartRenderingNewAca = [];
       this.barChartData3Aca = [];
       this.barChartData3OBJAca = {};
       this.reportsService.GetAcademicNonAcademicBrand(obj).subscribe((result: any) => {
           if (result) {
            this.newReplaceLoadingTimeAca = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNewAca = result.overview;
          this.entityArrAca = result.overview;
          var entArr = this.entityArrAca;
          var texts = entArr.map(function (el: any) {
            return el.brand;
          });
          this.brandEntityAca = texts;
          this.barChartLabels3Aca = this.brandEntityAca;
          var mainArr = result.mainArray;
          console.log(mainArr,'==mainArr')
          this.dataNewAca = {
            type: 'doughnut',
            labels: ['Academic', 'Non-Academic'],
            datasets: [
              {
                // label: 'Data 1',
                data: [result.totalAcademicCount, result.totalNonAcademicCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositionsAca = result.overallcount;
          this.totalNewCountAca = result.totalAcademicCount;
          this.totalReplacementCountAca = result.totalNonAcademicCount;
          mainArr.forEach((element: any) => {
            if (element.type == 'Academic') {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3Aca.push(this.barChartData3OBJAca);
          });
          console.log(this.barChartData3Aca,'this.barChartData3this.barChartData3this.barChartData3')

          setTimeout(() => {
            const labels: any[] = this.barChartLabels3Aca
            const datasets: any[] = this.barChartData3Aca.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;

            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            console.log(chartWidth)

            this.renderer.setStyle(this.chartContainerNew.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNewTxt?.nativeElement) {
              const ctx = this.canvasNewTxt.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        display: true,
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
           }, 2000);
     });
     } else if (entityType == 'Country') {
      this.newReplaceLoadingTimeAca = true;
      this.barChartData3Aca = [];
      this.anotherChartRenderingNewAca = [];
      this.barChartData3OBJAca = {};
      this.reportsService
        .GetAcademicNonAcademicCountry(obj)
        .subscribe((result: any) => {
          if (result) {
            this.newReplaceLoadingTimeAca = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNewAca = result.overview;
          this.entityArrAca = result.overview;
          var entArr = this.entityArrAca;
          var texts = entArr.map(function (el: any) {
            return el.country;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });

          this.dataNewAca = {
            type: 'doughnut',
            labels: ['Academic', 'Non-Academic'],
            datasets: [
              {
                label: 'Data 1',
                data: [result.totalAcademicCount, result.totalNonAcademicCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositionsAca = result.overallcount;
          this.totalNewCountAca = result.totalAcademicCount;
          this.totalReplacementCountAca = result.totalNonAcademicCount;
          this.brandEntityAca = texts;
          this.barChartLabels3Aca = this.brandEntityAca;

          // const arr = this.brandEntityAca
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");


          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'Academic') {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3Aca.push(this.barChartData3OBJAca);

          });
          console.log(this.barChartData3Aca,'==this.barChartData3Acammm')
          setTimeout(() => {
            const labels: any[] = this.barChartLabels3Aca
            const datasets: any[] = this.barChartData3Aca.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            this.renderer.setStyle(this.chartContainerNew2Aca.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNew2Aca?.nativeElement) {
              const ctx = this.canvasNew2Aca.nativeElement.getContext('2d');
              if (ctx) {console.log(ctx,'ctx')
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        display: true,
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
                console.log(this.chart,'==this.chart')
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 2000);
        });
     } else if (entityType == 'Campus') {
      this.newReplaceLoadingTimeAca = true;
      this.barChartData3Aca = [];
      this.anotherChartRenderingNewAca = [];
      this.barChartData3OBJAca = {};
      this.reportsService
        .GetAcademicNonAcademicCampus(obj)
        .subscribe((result: any) => {
          if (result) {
            this.newReplaceLoadingTimeAca = false;
            setTimeout(() => {
              this.tabsButtonDisabled = false;
            }, 2000);
          }
          this.anotherChartRenderingNewAca = result.overview;
          // console.log(result.overview, ":::::::::::::::::::;;;;;;;;;;;;;;;;;;;;");

          this.entityArrAca = result.overview;
          var entArr = this.entityArrAca;
          this.dataNewAca = {
            type: 'doughnut',
            labels: ['Academic', 'Non-Academic'],
            datasets: [
              {
                // label: 'Data 1',
                data: [result.totalAcademicCount, result.totalNonAcademicCount],
                fill: false,
                backgroundColor: ['#EAB308', '#22C55E'],
              },
            ],
          };

          this.totalNewPositionsAca = result.overallcount;
          this.totalNewCountAca = result.totalAcademicCount;
          this.totalReplacementCountAca = result.totalNonAcademicCount;
          var texts = entArr.map(function (el: any) {
            return el.campus;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });
          this.brandEntityAca = texts;
          this.barChartLabels3Aca = this.brandEntityAca;

          // const arr = this.brandEntityAca
          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].toLowerCase()
          // }

          // for (var i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          // }
          // const str2 = arr.join(" ");


          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            // console.log(element, '==element');
            if (element.type == 'Academic') {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EAB308',
              };
            } else {
              this.barChartData3OBJAca = {
                data: element.data,
                label: element.type,
                backgroundColor: '#22C55E',
              };
            }
            this.barChartData3Aca.push(this.barChartData3OBJAca);

          });
          setTimeout(() => {
            const labels: any[] = this.barChartLabels3Aca
            const datasets: any[] = this.barChartData3Aca.map((dataset: any) => ({
              ...dataset,
              barThickness: 20 // Set the bar thickness to 30px
            }));
            const barThickness = 50;
            const barMargin = 170;
            const chartWidth = labels.length * (barThickness + barMargin);
            this.renderer.setStyle(this.chartContainerNew3Aca.nativeElement, 'width', `${chartWidth}px`);
            if (this.canvasNew3Aca?.nativeElement) {
              const ctx = this.canvasNew3Aca.nativeElement.getContext('2d');
              if (ctx) {
                this.chart = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: labels,
                    datasets: datasets,
                  },
                  options: {
                    maintainAspectRatio: false,
                    layout: {
                      padding: 20
                    },
                    plugins: {
                      legend: {
                        align: 'start',
                        position: 'bottom',
                        labels: {
                          boxWidth: 8,
                          padding: 30,
                          boxHeight: 16,
                          borderRadius: 2,
                          useBorderRadius: true,
                        }
                      },
                      datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        formatter: (value: any) => value, // Display the value directly on each bar
                        color: 'black',
                        font: {
                          size: 9,
                          weight: 'normal'
                        }
                      },
                    },
                    scales: {
                      x: {
                        stacked: false,
                        grid: {
                          drawTicks: false,
                          display: false
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                      y: {
                        stacked: false,
                        // beginAtZero: true,
                        grid: {
                          drawTicks: false,
                          display: true,
                        },
                        border: {
                          dash: [5, 5],
                          display: true
                        },
                      },
                    },
                  },
                });
              } else {
                console.error('Canvas context not available');
              }
            }
          }, 2000);
        });
     }
  }
  /*************GetAcademicNonAcademic*********/




  /*************GetAverageTAT*********/
  brandInfo: any = []; mainArrayTAT: any = []; TATbarChartDatabgColor: any = ['#008000', '#D3D3D3', '#FF0000', '#B7E334']; color: any
  barChartDataobjTAT: any = {}; barChartDataTAT: any = []
  GetAverageTAT(obj: any, tatType: any) {
    if (tatType == 'Brand') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATBrand(obj).subscribe((result: any) => {
        // console.log(result, '=======result');
        this.brandInfo = result.brandInfo;
        this.mainArrayTAT = result.mainArray;


        var texts = this.brandInfo.map(function (el: any) {
          return el.brandname;
        });
        var texts1 = this.brandInfo.map(function (el: any) {
          return el.noofapplication;
        });

        // this.brandTAT = texts;
        // this.barChartLabels4 = this.brandTAT;
        // var tatOBJ = {
        //   data: texts1,
        //   label: 'Sources',
        //   backgroundColor: '#8B6FE8',
        // };

        // this.myStackedData.push(tatOBJ);
        this.barChartDataTAT=[]
        // console.log(this.mainArrayTAT,'==this.mainArrayTAT')
        for (let k = 0; k < this.mainArrayTAT.length; k++) {
          this.barChartDataobjTAT = {
            data: this.mainArrayTAT[k].data,
            label: this.mainArrayTAT[k].type,
            backgroundColor: this.TATbarChartDatabgColor[k],
          };
          this.barChartDataTAT.push(this.barChartDataobjTAT);
        }

        this.myStackedData = {
          labels: texts,
          datasets: this.barChartDataTAT,
        };
        //  console.log(this.barChartData4,'==this.barChartDataTAT')

      });
    } else if (tatType == 'Country') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATCountry(obj).subscribe((result: any) => {
        this.averageTATArr = result;
        var tatArr = this.averageTATArr;
        var texts = tatArr.map(function (el: any) {
          return el.countryname;
        });
        var texts1 = tatArr.map(function (el: any) {
          return el.noofapplication;
        });
        this.brandTAT = texts;
        this.barChartLabels4 = this.brandTAT;
        var tatOBJ = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartData4.push(tatOBJ);
      });
    } else if (tatType == 'Campus') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATCampus(obj).subscribe((result: any) => {
        this.averageTATArr = result;
        var tatArr = this.averageTATArr;
        var texts = tatArr.map(function (el: any) {
          return el.campusname;
        });
        var texts1 = tatArr.map(function (el: any) {
          return el.noofapplication;
        });
        this.brandTAT = texts;
        this.barChartLabels4 = this.brandTAT;
        var tatOBJ = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartData4.push(tatOBJ);
      });
    }
  }
  /*************GetAverageTAT*********/

  /****************GetAllFilters******************/
  fromDate: any;
  GetAllFilters(type: any) {
    var obj = {};
    if (type == 'load') {
      obj = {
        userId: this.userId,
        brand: this.brandId,
        country: '',
        campus: '',
        jobCategory: '',
        department: '',
        source: this.jobPostId,
        jobStatus:'',
        fromDate: '1900-01-01T00:00:00.000Z',
        toDate: '1900-01-01T00:00:00.000Z',

      };
    } else {
      var startDate = this.dateRangeFrom;
      var endDate = this.dateRangeTo;

      if(this.dashboardjobStatusArray==null)
      {
        this.dashboardjobStatusArray = ''
      }else{
        this.dashboardjobStatusArray = this.dashboardjobStatusArray
      }

      if (startDate != '' && startDate != undefined && startDate != null) {
        startDate = startDate;
      } else {
        startDate = '1900-01-01T00:00:00.000Z';
      }

      if (endDate != '' && endDate != undefined && endDate != null) {
        endDate = endDate;
      } else {
        endDate = '1900-01-01T00:00:00.000Z';
      }
      obj = {
        userId: this.userId,
        brand: this.brandId,
        country: this.countryId,
        campus: this.campusId,
        jobCategory: this.jobCategory,
        department: this.departmentId,
        source: this.jobPostId,
        fromDate: startDate,
        toDate: endDate,
        jobStatus:this.dashboardjobStatusArray
      };
    }

    // var objbrand={}
    this.patchesRowArray = [];

    var objbrand = { patchName: 'School: ' + this.brandName };
    this.patchesRowArray.push(objbrand);

    if (
      this.selectedCountryName != '' &&
      this.selectedCountryName != undefined &&
      this.selectedCountryName != null
    ) {
      var objcountry = { patchName: 'Country: ' + this.selectedCountryName };
      this.patchesRowArray.push(objcountry);
    }

    if (
      this.selectedCcampusName != '' &&
      this.selectedCcampusName != undefined &&
      this.selectedCcampusName != null
    ) {
      var objcampus = { patchName: 'Campus: ' + this.selectedCcampusName };
      this.patchesRowArray.push(objcampus);
    }
    if (
      this.selectedcategoryName != '' &&
      this.selectedcategoryName != undefined &&
      this.selectedcategoryName != null
    ) {
      var objcategoryName = {
        patchName: 'Category: ' + this.selectedcategoryName,
      };
      this.patchesRowArray.push(objcategoryName);
    }

    if (
      this.selectedDepartmentName != '' &&
      this.selectedDepartmentName != undefined &&
      this.selectedDepartmentName != null
    ) {
      var objDepartmentName = {
        patchName: 'Department: ' + this.selectedDepartmentName,
      };
      this.patchesRowArray.push(objDepartmentName);
    }
setTimeout(() => {


    this.mainObject = obj;
    this.GetjobPositions(obj);
    this.GetAcademics(obj);
    this.GetNewReplacements(obj);
    this.GetSelectedApplicants(obj);
    this.GetStatusDistribution(obj);
    this.GetTalentRequisition(obj);

    this.GetStagesOverviewByCountry(obj, 'Brand');
    this.GetSourceHire(obj, 'Number');
    this.GetSourceOfHireApplicantsCount(obj, 'Number');
    this.GetOpenClosedPositions(obj, 'Brand');
    this.GetNewReplacementEntity(obj, 'Brand');
    this.GetAcademicNonAcademic(obj, 'Brand');
    this.GetAverageTAT(obj, 'Brand');
    this.GetGlobalJoiners(obj);
    this.getHRReportBrand(obj, 'Brand');
    // this.hrPerformanceReportCountry(obj, 'Country');
  }, 3000);
  }

  /****************GetAllFilters******************/

  dashboardSchoolFilterItem: any;
  dashboardSchoolFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardCountryFilterItem: any;
  dashboardCountryFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardCampusFilterItem: any;
  dashboardCampusFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardJobCategoryFilterItem: any;
  dashboardJobCategoryFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardDepartmentFilterItem: any;
  dashboardDepartmentFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardJobTitleFilterItem: any;
  dashboardJobTitleFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardSourceFilterItem: any;
  dashboardSourceFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  // dashboard-stages-chart starts

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    plugins: {
      datalabels: false,
      tooltip: {
        backgroundColor: '#ffffff'
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    scales: {
      x: {
        stacked: false,

        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,
  };

  public barChartLabels = [];

  public barChartType: any = 'bar';

  public barChartLegend = true;

  barChartDatabgColor: any = [
    '#8B6FE8',
    '#EE90FC',
    '#9FEDFF',
    '#B7E334',
    '#E4CB4D',
    '#337357',
    '#EB5146',
  ];
  public barChartData: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //   label: 'New',
    //    backgroundColor: '#EB5146'
    // },
    // {
    //   data: [10, 60, 10, 10, 40],
    //   label: 'Screening',
    //   backgroundColor: '#E4CB4D',
    // },
    // {
    //   data: [60, 60, 60, 60, 40],
    //   label: 'Interview',
    //   backgroundColor: '#B7E334',
    // },
    // {
    //   data: [40, 20, 20, 40, 40],
    //   label: 'Shortlisted',
    //   backgroundColor: '#EE90FC',
    // },
    // {
    //   data: [60, 60, 60, 60, 60],
    //   label: 'Offered',
    //   backgroundColor: '#8B6FE8',
    // },
    // {
    //   data: [40, 10, 20, 20, 20],
    //   label: 'Onboarded',
    //   backgroundColor: '#9FEDFF',
    // },
  ];
  // dashboard-stages-chart ends

  // dasahboard-sourceOfHire-chart starts
  public barChartOptions1 = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20
    },
    scales: {
      // x: {},
      x: {
        stacked: true,

        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -5,

        font: {
          size: 9,
        },
        formatter: function (value: number): string {
          return value.toLocaleString();
        },
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },

    barThickness: 20,
  };

  public barChartLabels1: any = [
    // 'Job Portal',
    // 'Employee Referral',
    // 'IJP',
    // 'Advertisement',
    // 'Walk-In',
    // 'Re-Hire',
    // 'Upwork',
    // 'Consult',
    // 'Naukri',
    // 'Indeed',
    // 'Hirist',
  ];
  // public barChartLabels10: any = []
  public barChartPlugins = [pluginDataLabels];
  public barChartType1: any = 'bar';

  public barChartLegend1 = true;

  public barChartData1: any = [
    // {
    //   data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
    //   label: 'Sources',
    //   backgroundColor: '#8B6FE8',
    // },
  ];
  // public barChartData10: any = []
  public barChartOptionsPercentage = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    scales: {
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -5,

        font: {
          size: 9,
        },
        formatter: function (value: number): string {
          return value.toLocaleString() + '%';
        },
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    // barThickness: 20,
  };
  public barChartOptionsPercentage1 = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    scales: {
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -5,

        font: {
          size: 9,
        },
        formatter: function (value: number): string {
          return value.toLocaleString() + '%';
        },
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    // barThickness: 20,
  };

  public barChartLabelsPercentage = [
    'Job Portal',
    'Employee Referral',
    'IJP',
    'Advertisement',
    'Walk-In',
    'Re-Hire',
    'Upwork',
    'Consult',
    'Naukri',
    'Indeed',
    'Hirist',
  ];
  public barChartLabelsPercentage1: any = [];
  public barChartPlugins1 = [pluginDataLabels];
  public barChartTypePercentage: any = 'bar';

  public barChartLegendPercentage = true;

  public barChartDataPercentage = [
    {
      data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
      label: 'Sources',
      backgroundColor: '#8B6FE8',
    },
  ];
  public barChartDataPercentage1 = [
    {
      data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
      label: 'Sources',
      backgroundColor: '#8B6FE8',
    },
  ];
  // dasahboard-sourceOfHire-chart ends

  public barChartOptions2 = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    plugins: {
      datalabels: false,
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 9,
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,
  };

  public barChartLabels2 = [
    // 'Marketing Manager',
    // 'Jr. Teacher',
    // 'Sr. Teacher',
    // 'HR Executive',
    // 'Korea Dwight',
  ];

  public barChartType2: any = 'bar';

  public barChartLegend2 = true;
  NewReplacementbarChartDatabgColor: any = ['#8B6FE8', '#EE90FC'];

  openClosedBgColor: any = ['#8B6FE8', '#B7E334'];
  public barChartData2: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //   label: 'Total Applications',
    //   backgroundColor: '#EB5146',
    // },
    // {
    //   data: [100, 30, 10, 10, 20],
    //   label: 'Selected 1',
    //   backgroundColor: '#E4CB4D',
    //   // stack: 'stack1'
    // },
    // {
    //   data: [10, 30, 10, 10, 20],
    //   label: 'Selected 2',
    //   backgroundColor: '#F29C49',
    //   // stack: 'stack1'
    // }
  ];
  // dashboard-positionsGlobal-chart ends

  // dashboard-entityBreakup-chart starts

  public barChartOptions3 = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    plugins: {
      datalabels: false,
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },

    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,

    // responsive: true,
    // scales: {
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    //   y: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    //   // x: { stacked: true },
    //   // y: { stacked: true },
    //   // maintainAspectRatio: false,
    // },
    // plugins: {
    //   legend: {
    //     display: true,
    //     align: 'center',
    //     position: 'bottom',
    //   },
    //   datalabels: {
    //     anchor: 'end',
    //     align: 'end',
    //   },
    // },
    // barThickness: 20,
  };

  public barChartLabels3 = [
    'GIIS',
    'Global',
    'OWIS Osaka',
    'OWIS Singapore',
    'OWIS Punngol',
  ];

  public barChartType3: any = 'bar';

  public barChartLegend3 = true;
  public barChartData3Aca:any=[]
  public barChartData3: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //    label: 'New',
    //     backgroundColor: '#8B6FE8' },
    // {
    //   data: [10, 60, 10, 10, 40],
    //   label: 'Replacement',
    //   backgroundColor: '#EE90FC',
    // },
  ];
  // dashboard-entityBreakup-chart ends

  // dashboard-averageTat-chart start

  public barChartOptions4 = {
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },
    responsive: true,
    maintainAspectRatio: false,
    // plugins: {
    //   // legend: {
    //   //   labels: {
    //   //     display: true,
    //   //     font: {
    //   //       size: 9,
    //   //     }
    //   //   }
    //   // },
    //   title: {
    //     display: true,
    //     font: {
    //       size: 9,
    //     }
    //   }
    //   },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    //  barThickness: 20,

    plugins: {
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'end',
        offset: -5,

        font: {
          size: 9,
        },
        formatter: function (value: number): string {
          return value.toLocaleString();
        },
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    barThickness: 20,
  };

  public barChartLabels4: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  public barChartType4: any = 'bar';

  public barChartLegend4 = true;

  public barChartData4: any = [
    {
      data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
      label: 'Sources A',
      backgroundColor: '#8B6FE8',
    },
  ];




  barChartOptions5: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,

          font: {
            size: 9,
          },
        },
      },
    },
    barThickness: 20,
    plugins: {
      datalabels: {
        display: false,
      }
    }
  };
  barChartLabels5: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  barChartType5: string = 'bar';
  barChartLegend5: boolean = true;

  barChartData5: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', backgroundColor: 'red' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', backgroundColor: 'grey' },
    { data: [35, 25, 35, 40, 60, 50, 70], label: 'Series C', backgroundColor: 'blue' }
  ];




  public pbarChartOptions10 = {
    devicePixelRatio: 4,
    layout: {
      padding: 20
    },

    responsive: true,
    maintainAspectRatio: false,
    // plugins: {
    //   // legend: {
    //   //   labels: {
    //   //     display: true,
    //   //     font: {
    //   //       size: 9,
    //   //     }
    //   //   }
    //   // },
    //   title: {
    //     display: true,
    //     font: {
    //       size: 9,
    //     }
    //   }
    //   },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 9,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,

          font: {
            size: 9,
          },
        },
      },
    },
    //  barThickness: 20,

    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -5,

        font: {
          size: 9,
        },
        formatter: function (value: number): string {
          return value.toLocaleString();
        },
      },
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 8,
          padding: 30,
          boxHeight: 16,
          borderRadius: 2,
          useBorderRadius: true,
        }
      },
    },
    // barThickness: 20,
  };

  public barChartLabels10: any = [
    // 'Dwight',
    // 'GIIS',
    // 'Glendale',
    // 'Harrods',
    // 'OWIS India',
    // 'OWIS Riyadh',
    // 'Dwight',
    // 'OWIS India',
    // 'OWIS Riyadh',
    // 'Dwight',
    // 'GIIS',
  ];

  public barChartType10: any = 'bar';

  public barChartLegend10 = true;

  public barChartData10: any = [
    // {
    //   data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
    //   label: 'Sources',
    //   backgroundColor: '#8B6FE8',
    // },
  ];
  // dashboard-averageTat-chart ends

  // data: any;

  options: any;

  selectedCategories: any[] = [];

  categories: any[] = [
    { name: 'New', key: 'A' },
    { name: 'Screening', key: 'M' },
    { name: 'Interview', key: 'P' },
    { name: 'Pre-Offer', key: 'R' },
    { name: 'Offer', key: 'X' },
    { name: 'Post-Offer', key: 'Y' },
  ];
  selectedCountries: any[] = [];

  countries: any[] = [
    { name: 'India', key: 'IN' },
    { name: 'Singapore', key: 'SN' },
    { name: 'OWIS', key: 'OW' },
    { name: 'UAE', key: 'UAE' },
    { name: 'Malaysia', key: 'ML' },
    { name: 'Japan', key: 'JA' },
    { name: 'Cambodia', key: 'CM' },
    { name: 'Dwight', key: 'DW' },
    { name: 'Regent', key: 'RE' },
    { name: 'OWIS Riyadh', key: 'OWR' },
    { name: 'Philippines', key: 'PH' },
    { name: 'OWIS India', key: 'OWI' },
    { name: 'Glendale', key: 'GD' },
  ];
  // departmentWise: boolean = true;
  departmentWise: any[] = [];

  departments: any[] = [
    { name: 'New', key: 'NW' },
    { name: 'Replacement', key: 'RP' },
  ];

  dashboardTable: any = [
    {
      entity: 'Giis',
      jan: '01',
      feb: '02',
      march: '03',
      apr: '04',
      may: '05',
      june: '06',
      july: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12',
      total: '15',
      average: '22',
    },
    {
      entity: 'Dwight',
      jan: '01',
      feb: '02',
      march: '03',
      apr: '04',
      may: '05',
      june: '06',
      july: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12',
      total: '15',
      average: '22',
    },
    {
      entity: 'Harrods',
      jan: '01',
      feb: '02',
      march: '03',
      apr: '04',
      may: '05',
      june: '06',
      july: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12',
      total: '15',
      average: '22',
    },
    {
      entity: 'OWIS Singapore',
      jan: '01',
      feb: '02',
      march: '03',
      apr: '04',
      may: '05',
      june: '06',
      july: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12',
      total: '15',
      average: '22',
    },
    {
      entity: 'OWIS India',
      jan: '01',
      feb: '02',
      march: '03',
      apr: '04',
      may: '05',
      june: '06',
      july: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12',
      total: '15',
      average: '22',
    },
  ];
  activeAccordionIndex: number = -1;
  savefilterCriteria() {

    if (this.dashboardCountryFilterItem!=undefined) {
      this.dashboardCountryFilterItem = this.dashboardCountryFilterItem.toString()
    }else{
      this.dashboardCountryFilterItem = ''
    }
    if (this.dashboardCampusFilterItem!=undefined) {
      this.dashboardCampusFilterItem = this.dashboardCampusFilterItem.toString()
    }else{
      this.dashboardCampusFilterItem = ''
    }
    if (this.dashboardJobCategoryFilterItem!=undefined) {
      this.dashboardJobCategoryFilterItem = this.dashboardJobCategoryFilterItem.toString()
    }else{
      this.dashboardJobCategoryFilterItem = ''
    }
    if (this.dashboardDepartmentFilterItem!=undefined) {
      this.dashboardDepartmentFilterItem = this.dashboardDepartmentFilterItem.toString()
    }else{
      this.dashboardDepartmentFilterItem = ''
    }
    if (this.dashboardSourceFilterItem) {
      this.dashboardSourceFilterItem = this.dashboardSourceFilterItem.toString()
    } else {
      this.dashboardSourceFilterItem = ''
    }
    if (this.dashboardjobStatusArray!=undefined || this.dashboardjobStatusArray!=null) {
      this.dashboardjobStatusArray = this.dashboardjobStatusArray.toString()
    }else{
      this.dashboardjobStatusArray = ''
    }

    var obj = {
      id: 0,
      userId: this.userId,
      brand: this.brandFirstObj.toString(),
      country: this.dashboardCountryFilterItem,
      campus: this.dashboardCampusFilterItem,
      jobCategory: this.dashboardJobCategoryFilterItem,
      department: this.dashboardDepartmentFilterItem,
      source: this.dashboardSourceFilterItem,
      fromDate: this.dateRangeFrom,
      toDate: this.dateRangeTo,
      status: true,
      jobStatus:this.dashboardjobStatusArray
    };
    // var id = this.id;
    if (this.id > 0) {
      obj['id'] = this.id;
    }

    this.reportsService.Getjobfiltersfigma(obj).subscribe((res: any) => {
      this.resetTabs();
      this.id = 0;
      this.getCriteriaId(this.userId)
    });
    // this.setActiveTab('Brand');


    this.activeAccordionIndex = -1;
  }
  // activeTab: string = 'Brand';
  // setActiveTab(tabName: string) {
  //   this.activeTab = tabName;
  //   this.updateTabUI();
  // }
  // updateTabUI() {
  //   // Deactivate all tabs
  //   const tabs = document.querySelectorAll('.nav-link');
  //   tabs.forEach(tab => {
  //     tab.classList.remove('active');
  //     tab.setAttribute('aria-selected', 'false');
  //   });

  //   // Activate the current tab
  //   const activeTabButton = document.getElementById(`stages-${this.activeTab.toLowerCase()}-tab`);
  //   if (activeTabButton) {
  //     activeTabButton.classList.add('active');
  //     activeTabButton.setAttribute('aria-selected', 'true');
  //   }
  // }
  resetCriteria() {
    // this.dashboardCountryFilterItem='',
    // this.dashboardCampusFilterItem='',
    // this.dashboardJobCategoryFilterItem='',
    // this.dashboardDepartmentFilterItem='',
    // this.dashboardSourceFilterItem='',
    this.dashboardCountryFilterItem = '';
    this.countryId = '';
    (this.campusId = ''), (this.dashboardCampusFilterItem = '');
    this.dashboardJobCategoryFilterItem = '';
    this.dashboardDepartmentFilterItem = '';
    this.dashboardSourceFilterItem = '';
    (this.jobCategory = ''),
      (this.departmentId = ''),
      (this.jobPostId = ''),
      (this.selectedCountryName = ''),
      (this.selectedcategoryName = ''),
      (this.selectedCcampusName = ''),
      (this.selectedDepartmentName = ''),
      (this.dateRangeFrom = ''),
      (this.dateRangeTo = ''),
      this.GetAllFilters('load');
  }
  removeSinglePatch(patchName: any) {
    const nameSelected = patchName.split(':')[0];
    // console.log(nameSelected, '=====================nameSelected');
    if (nameSelected == 'Country') {
      this.dashboardCountryFilterItem = '';
      this.countryId = '';
      this.campusId = '';
      this.dashboardCampusFilterItem = '';
      this.dashboardJobCategoryFilterItem = '';
      this.dashboardDepartmentFilterItem = '';
      this.dashboardSourceFilterItem = '';
      (this.jobCategory = ''),
        (this.departmentId = ''),
        (this.jobPostId = ''),
        (this.selectedCountryName = ''),
        (this.selectedcategoryName = ''),
        (this.selectedCcampusName = ''),
        (this.selectedDepartmentName = ''),
        (this.campusArr = []);
      this.jobCategoryArr = [];
      this.departmentArr = [];
      this.GetAllFilters('change');
    }
    if (nameSelected == 'Campus') {
      (this.selectedCcampusName = ''), (this.dashboardCampusFilterItem = '');
      this.campusId = '';
      this.dashboardJobCategoryFilterItem = '';
      this.dashboardDepartmentFilterItem = '';
      (this.jobCategory = ''),
        (this.departmentId = ''),
        (this.jobPostId = ''),
        (this.selectedcategoryName = ''),
        (this.selectedDepartmentName = ''),
        (this.dashboardSourceFilterItem = '');
      this.GetAllFilters('change');
      this.jobCategoryArr = [];
      this.departmentArr = [];
    }
    if (nameSelected == 'Category') {
      (this.selectedcategoryName = ''),
        (this.dashboardJobCategoryFilterItem = '');
      this.dashboardDepartmentFilterItem = '';
      this.dashboardSourceFilterItem = '';
      (this.selectedDepartmentName = ''),
        (this.departmentId = ''),
        (this.jobCategory = ''),
        (this.jobPostId = '');
      this.departmentArr = [];
      this.GetAllFilters('change');
    }
    if (nameSelected == 'Department') {
      this.dashboardDepartmentFilterItem = '';
      this.dashboardSourceFilterItem = '';
      (this.selectedDepartmentName = ''),
        (this.departmentId = ''),
        (this.jobPostId = '');
      this.GetAllFilters('change');
    }
  }
  setdateInfo(dateRangeFrom: any) {
    this.selectedstartDate = new Date(dateRangeFrom);
    this.dateRangeTo = '';
  }

  globleArray: any = [];overallJoinerCountsByMonth:any={}
  joinersBrand: boolean = true;
  joinersCountry: boolean = false;
  joinersCampus: boolean = false;
  loadingTime: boolean = false;
  globalJoinersTotalCOunt: any;
  GetGlobalJoiners(obj: any) {
    this.loadingTime = true;
    this.reportsService.GetGlobalJoiners(obj).subscribe((res: any) => {
      this.globleArray = res.brands;
      console.log(res, "ppppppppppppppppp00000000000000000000000000000000000000");
      this.globalJoinersTotalCOunt = res.overallTotalCount;

      this.overallJoinerCountsByMonth = res.overallJoinerCountsByMonth

      if (res) {
        this.loadingTime = false;
      }
    });
    this.joinersBrand = true;
    this.joinersCountry = false;
    this.joinersCampus = false;
  }

  GetGlobalJoinersByCountry(obj: any) {
    this.globleArray = [];
    this.globalJoinersTotalCOunt = null;
    this.loadingTime = true;
    this.reportsService.GetGlobalJoinersByCountry(obj).subscribe((res: any) => {
      this.globleArray = res.country;
      this.overallJoinerCountsByMonth = res.overallJoinerCountsByMonth
      this.globalJoinersTotalCOunt = res.overallTotalCount;
      console.log(res);
      if (res) {
        this.loadingTime = false;
      }
    });
    this.joinersBrand = false;
    this.joinersCountry = true;
    this.joinersCampus = false;
  }

  GetGlobalJoinersByCampus(obj: any) {
    this.globalJoinersTotalCOunt = null;
    this.globleArray = [];
    this.loadingTime = true;
    this.reportsService.GetGlobalJoinersByCampus(obj).subscribe((res: any) => {
      this.globleArray = res.campus;
      this.overallJoinerCountsByMonth = res.overallJoinerCountsByMonth
      this.globalJoinersTotalCOunt = res.overallTotalCount;
      console.log(res);
      if (res) {
        this.loadingTime = false;
      }
    });
    this.joinersBrand = false;
    this.joinersCountry = false;
    this.joinersCampus = true;
  }

  public brandNew: any;
  public schoolPreOffer: any;
  OFFERcount:any;Selectedcount:any;Onboardedcount:any;OfferRejectedcount:any;RejectedbyHRcount:any
        ActiveJobscount:any;ClosedJobscount:any;Jobsclosedwithoutapplicantcount:any;NoOfVacanciescount:any
        NoOfClosedVacanciescount:any;totalJobscount:any;totalApplicantscount:any
  HRPerformanceArray: any[] = [];
  HRPerformanceArray1: any[] = [];screeningcount:any;preoffercount:any
  HRPerformanceArray2: any[] = [];newcount:any;interviewcount:any
  HRPerformanceArray3: any[] = [];
  myLoadingTime: boolean = false;
  getHRReportBrand(obj: any, type: any) {
    this.myLoadingTime = true;
    this.preArray=[]
    if (type == 'Brand') {
      this.reportsService.hrPerformanceReport(obj).subscribe((res: any) => {

        // var array:any={
        //   ActiveJobs:res.response.ActiveJobs,
        //   PREOFFER:res.response['PRE-OFFER'],
        // }
        var dataAeey = res.response;
        this.newcount = res.overallCounts.NEW
        this.screeningcount = res.overallCounts.SCREENING
        this.interviewcount = res.overallCounts.INTERVIEW
        this.preoffercount = res.overallCounts['PRE-OFFER']
        this.OFFERcount = res.overallCounts.OFFER
        this.Selectedcount = res.overallCounts.Selected
        this.Onboardedcount = res.overallCounts.Onboarded
        this.OfferRejectedcount = res.overallCounts.OfferRejected
        this.RejectedbyHRcount = res.overallCounts.RejectedbyHR
        this.ActiveJobscount = res.overallCounts.ActiveJobs
        this.ClosedJobscount = res.overallCounts.ClosedJobs
        this.Jobsclosedwithoutapplicantcount = res.overallCounts.Jobsclosedwithoutapplicant
        this.NoOfVacanciescount = res.overallCounts.NoOfVacancies
        this.NoOfClosedVacanciescount = res.overallCounts.NoOfClosedVacancies
        this.totalJobscount = res.overallCounts.TotalJobs
        this.totalApplicantscount = res.overallCounts.TotalApplicants



        for (let i = 0; i < dataAeey.length; i++) {
          const element = dataAeey[i]
          var array: any = {
            Country: dataAeey[i].Country,
            BrandId: dataAeey[i].BrandId,
            BrandName: dataAeey[i].BrandName,
            NEW: dataAeey[i].NEW,
            SCREENING: dataAeey[i].SCREENING,
            INTERVIEW: dataAeey[i].INTERVIEW,
            PREOFFER: dataAeey[i]['PRE-OFFER'],
            OFFER: dataAeey[i].OFFER,
            Selected: dataAeey[i].Selected,
            Onboarded: dataAeey[i].Onboarded,
            OfferRejected: dataAeey[i].OfferRejected,
            RejectedbyHR: dataAeey[i].RejectedbyHR,
            ActiveJobs: dataAeey[i].ActiveJobs,
            ClosedJobs: dataAeey[i].ClosedJobs,
            Jobsclosedwithoutapplicant: dataAeey[i].Jobsclosedwithoutapplicant,
            NoOfVacancies: dataAeey[i].NoOfVacancies,
            NoOfClosedVacancies: dataAeey[i].NoOfClosedVacancies,
            TotalJobs: dataAeey[i].TotalJobs,
            TotalApplicants: dataAeey[i].TotalApplicants,

          }
          this.preArray.push(array)
        }
        this.HRPerformanceArray = this.preArray;
        this.schoolPreOffer = res.response;
         console.log(this.HRPerformanceArray, "bbbbbbbbbbbbbbbbbbbbbb");

        if (res) {
          this.myLoadingTime = false;
        }
      });
    }
  }

  // hrPerformanceReportCountry(obj:any, type :any){
  //   if(type == 'Country'){
  //     this.reportsService.hrPerformanceReportCountry(obj).subscribe((res: any)=>{
  //       if (res) {
  //         this.HRPerformanceArray2 = res;
  //         console.log(this.HRPerformanceArray2);
  //       }
  //     })
  //   }
  // }

  hrPerformanceVariable1: boolean = true;
  hrPerformanceVariable2: boolean = false;
  hrPerformanceVariable3: boolean = false;
  hrBrand(data: any) {
    this.preArray = []
    var startDate = this.dateRangeFrom;
    var endDate = this.dateRangeTo;
    var obj = {
      userId: this.userId,
      brand: this.brandId,
      country: this.countryId,
      campus: this.campusId,
      jobCategory: this.jobCategory,
      department: this.departmentId,
      source: this.jobPostId,
      fromDate: startDate,
      toDate: endDate,
    };
    if (data == '1') {
      this.myLoadingTime = true;
      this.hrPerformanceVariable1 = true;
      this.hrPerformanceVariable2 = false;
      this.hrPerformanceVariable3 = false;
      this.HRPerformanceArray = [];
      this.reportsService.hrPerformanceReport(obj).subscribe((res: any) => {
        if (res) {
          var dataAeey = res.response

          this.newcount = res.overallCounts.NEW
        this.screeningcount = res.overallCounts.SCREENING
        this.interviewcount = res.overallCounts.INTERVIEW
        this.preoffercount = res.overallCounts['PRE-OFFER']
        this.OFFERcount = res.overallCounts.OFFER
        this.Selectedcount = res.overallCounts.Selected
        this.Onboardedcount = res.overallCounts.Onboarded
        this.OfferRejectedcount = res.overallCounts.OfferRejected
        this.RejectedbyHRcount = res.overallCounts.RejectedbyHR
        this.ActiveJobscount = res.overallCounts.ActiveJobs
        this.ClosedJobscount = res.overallCounts.ClosedJobs
        this.Jobsclosedwithoutapplicantcount = res.overallCounts.Jobsclosedwithoutapplicant
        this.NoOfVacanciescount = res.overallCounts.NoOfVacancies
        this.NoOfClosedVacanciescount = res.overallCounts.NoOfClosedVacancies
        this.totalJobscount = res.overallCounts.TotalJobs
        this.totalApplicantscount = res.overallCounts.TotalApplicants

          for (let i = 0; i < dataAeey.length; i++) {
            const element = dataAeey[i]
            var array: any = {
              Country: dataAeey[i].Country,
              BrandId: dataAeey[i].BrandId,
              BrandName: dataAeey[i].BrandName,
              NEW: dataAeey[i].NEW,
              SCREENING: dataAeey[i].SCREENING,
              INTERVIEW: dataAeey[i].INTERVIEW,
              PREOFFER: dataAeey[i]['PRE-OFFER'],
              OFFER: dataAeey[i].OFFER,
              Selected: dataAeey[i].Selected,
              Onboarded: dataAeey[i].Onboarded,
              OfferRejected: dataAeey[i].OfferRejected,
              RejectedbyHR: dataAeey[i].RejectedbyHR,
              ActiveJobs: dataAeey[i].ActiveJobs,
              ClosedJobs: dataAeey[i].ClosedJobs,
              Jobsclosedwithoutapplicant: dataAeey[i].Jobsclosedwithoutapplicant,
              NoOfVacancies: dataAeey[i].NoOfVacancies,
              NoOfClosedVacancies: dataAeey[i].NoOfClosedVacancies,
              TotalJobs: dataAeey[i].TotalJobs,
              TotalApplicants: dataAeey[i].TotalApplicants,

            }
            this.preArray.push(array)
          }
          this.myLoadingTime = false;
          this.HRPerformanceArray1 = res.response;
          this.HRPerformanceArray = this.preArray;
        }
      });
    }
    if (data == '2') {
      this.myLoadingTime = true;
      this.hrPerformanceVariable1 = false;
      this.hrPerformanceVariable2 = true;
      this.hrPerformanceVariable3 = false;
      this.HRPerformanceArray = [];
      this.reportsService
        .hrPerformanceReportCountry(obj)
        .subscribe((res: any) => {
          if (res) {
            var dataAeey = res.response

            this.newcount = res.overallCounts.NEW
        this.screeningcount = res.overallCounts.SCREENING
        this.interviewcount = res.overallCounts.INTERVIEW
        this.preoffercount = res.overallCounts['PRE-OFFER']
        this.OFFERcount = res.overallCounts.OFFER
        this.Selectedcount = res.overallCounts.Selected
        this.Onboardedcount = res.overallCounts.Onboarded
        this.OfferRejectedcount = res.overallCounts.OfferRejected
        this.RejectedbyHRcount = res.overallCounts.RejectedbyHR
        this.ActiveJobscount = res.overallCounts.ActiveJobs
        this.ClosedJobscount = res.overallCounts.ClosedJobs
        this.Jobsclosedwithoutapplicantcount = res.overallCounts.Jobsclosedwithoutapplicant
        this.NoOfVacanciescount = res.overallCounts.NoOfVacancies
        this.NoOfClosedVacanciescount = res.overallCounts.NoOfClosedVacancies
        this.totalJobscount = res.overallCounts.TotalJobs
        this.totalApplicantscount = res.overallCounts.TotalApplicants
            for (let i = 0; i < dataAeey.length; i++) {
              const element = dataAeey[i]
              var array: any = {
                Country: dataAeey[i].Country,
                BrandId: dataAeey[i].BrandId,
                BrandName: dataAeey[i].BrandName,
                NEW: dataAeey[i].NEW,
                SCREENING: dataAeey[i].SCREENING,
                INTERVIEW: dataAeey[i].INTERVIEW,
                PREOFFER: dataAeey[i]['PRE-OFFER'],
                OFFER: dataAeey[i].OFFER,
                Selected: dataAeey[i].Selected,
                Onboarded: dataAeey[i].Onboarded,
                OfferRejected: dataAeey[i].OfferRejected,
                RejectedbyHR: dataAeey[i].RejectedbyHR,
                ActiveJobs: dataAeey[i].ActiveJobs,
                ClosedJobs: dataAeey[i].ClosedJobs,
                Jobsclosedwithoutapplicant: dataAeey[i].Jobsclosedwithoutapplicant,
                NoOfVacancies: dataAeey[i].NoOfVacancies,
                NoOfClosedVacancies: dataAeey[i].NoOfClosedVacancies,
                TotalJobs: dataAeey[i].TotalJobs,
                TotalApplicants: dataAeey[i].TotalApplicants,

              }
              this.preArray.push(array)
            }
            // this.HRPerformanceArray = this.preArray;
            this.schoolPreOffer = res.response;
            // this.HRPerformanceArray2 = res;
            this.myLoadingTime = false;
            this.HRPerformanceArray2 = res.response;
            this.HRPerformanceArray = this.preArray;
          }
        });
    }
    if (data == '3') {
      this.myLoadingTime = true;
      this.hrPerformanceVariable1 = false;
      this.hrPerformanceVariable2 = false;
      this.hrPerformanceVariable3 = true;
      this.HRPerformanceArray = [];
      this.reportsService
        .hrPerformanceReportCampus(obj)
        .subscribe((res: any) => {
          if (res) {
            var dataAeey = res.response
            this.newcount = res.overallCounts.NEW
        this.screeningcount = res.overallCounts.SCREENING
        this.interviewcount = res.overallCounts.INTERVIEW
        this.preoffercount = res.overallCounts['PRE-OFFER']
        this.OFFERcount = res.overallCounts.OFFER
        this.Selectedcount = res.overallCounts.Selected
        this.Onboardedcount = res.overallCounts.Onboarded
        this.OfferRejectedcount = res.overallCounts.OfferRejected
        this.RejectedbyHRcount = res.overallCounts.RejectedbyHR
        this.ActiveJobscount = res.overallCounts.ActiveJobs
        this.ClosedJobscount = res.overallCounts.ClosedJobs
        this.Jobsclosedwithoutapplicantcount = res.overallCounts.Jobsclosedwithoutapplicant
        this.NoOfVacanciescount = res.overallCounts.NoOfVacancies
        this.NoOfClosedVacanciescount = res.overallCounts.NoOfClosedVacancies
        this.totalJobscount = res.overallCounts.TotalJobs
        this.totalApplicantscount = res.overallCounts.TotalApplicants
            for (let i = 0; i < dataAeey.length; i++) {
              const element = dataAeey[i]
              var array: any = {
                CampusName: dataAeey[i].CampusName,
                BrandId: dataAeey[i].BrandId,
                BrandName: dataAeey[i].BrandName,
                NEW: dataAeey[i].NEW,
                SCREENING: dataAeey[i].SCREENING,
                INTERVIEW: dataAeey[i].INTERVIEW,
                PREOFFER: dataAeey[i]['PRE-OFFER'],
                OFFER: dataAeey[i].OFFER,
                Selected: dataAeey[i].Selected,
                Onboarded: dataAeey[i].Onboarded,
                OfferRejected: dataAeey[i].OfferRejected,
                RejectedbyHR: dataAeey[i].RejectedbyHR,
                ActiveJobs: dataAeey[i].ActiveJobs,
                ClosedJobs: dataAeey[i].ClosedJobs,
                Jobsclosedwithoutapplicant: dataAeey[i].Jobsclosedwithoutapplicant,
                NoOfVacancies: dataAeey[i].NoOfVacancies,
                NoOfClosedVacancies: dataAeey[i].NoOfClosedVacancies,
                TotalJobs: dataAeey[i].TotalJobs,
                TotalApplicants: dataAeey[i].TotalApplicants,

              }
              this.preArray.push(array)
            }
            // this.HRPerformanceArray = this.preArray;
            this.schoolPreOffer = res.response;
            // this.HRPerformanceArray3 = res;
            this.myLoadingTime = false;
            this.HRPerformanceArray3 = res.response;
            this.HRPerformanceArray = this.preArray;
          }
        });
    }
  }
  myTotalCount2: any;
  myOwnOptions2: any = {};
  GetSourceOfHireApplicantsCount(obj: any, sourceType: any) {
    this.tabsButtonDisabled = true;
    this.data1 = {};
    this.reportsService
      .GetSourceOfHireApplicantsCount(obj)
      .subscribe((result: any) => {
        // console.log(result, '===============result');
        if (result) {
          setTimeout(() => {
            this.tabsButtonDisabled = false;
          }, 1000);
        }
        this.myTotalCount2 = result.totalCount;
        this.sourceHireData = result.sourceCounts;

        var texts = this.sourceHireData.map(function (el: any) {
          return el.source;
        });
        var texts1 = this.sourceHireData.map(function (el: any) {
          return el.count;
        });
        var texts2 = this.sourceHireData.map(function (el: any) {
          return el.percentage;
        });
        console.log(this.myTotalCount2, 'oneeeeeeeeeee');

        if (sourceType == 'Number') {
          console.log(this.myTotalCount2, 'twoooooooooo');
          this.barChartLabels10 = [];
          this.barChartData10 = [];
          this.data1 = {
            labels: texts,
            datasets: [
              {
                data: texts1,
                backgroundColor: ['#B7E334', '#EE90FC', '#5FDDFF', '#8B6FE8', '#82ABA1'],
                hoverBackgroundColor: [
                  '#B7E334', '#EE90FC', '#5FDDFF', '#8B6FE8', '#82ABA1'
                ],
              },
            ],
          };
          this.myOwnOptions2 = {
            // aspectRatio: 0.6,
            cutout: '70%',
            //   layout:{
            //     padding:12
            //  },
            plugins: {
              // plugins: {
              legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                  boxWidth: 8,
                  padding: 15,
                  boxHeight: 16,
                  borderRadius: 2,
                  useBorderRadius: true,
                }
              },
              datalabels: {
                display: true,
                anchor: 'center',
                align: 'center',
                formatter: (value: any) => value, // Display the value directly on each bar
                color: '#ffffff',
                font: {
                  size: 9,
                  weight: 'normal'
                }
                // }
              },
              tooltip: {
                callbacks: {
                  label: function (context: any) {
                    let label = context.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.raw !== null) {
                      label += context.raw;
                    }
                    return label;
                  },
                },
              },
            },
          };
        }
        if (sourceType == 'Percentage') {
          console.log(this.myTotalCount2, 'threeeeeeeeeeeeeeeeee');
          this.barChartLabels10 = [];
          this.barChartData10 = [];
          if (sourceType == 'Percentage') {
            this.data1 = {
              labels: texts,
              datasets: [
                {
                  data: texts2,
                  backgroundColor: ['#B7E334', '#EE90FC', '#5FDDFF', '#8B6FE8', '#82ABA1'],
                  hoverBackgroundColor: [
                    '#B7E334', '#EE90FC', '#5FDDFF', '#8B6FE8', '#82ABA1'
                  ],
                },
              ],
            };
          }
          this.myOwnOptions2 = {
            cutout: '70%',
            //   layout:{
            //     padding:12
            //  },
            plugins: {
              // plugins: {
              legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                  boxWidth: 8,
                  padding: 15,
                  boxHeight: 16,
                  borderRadius: 2,
                  useBorderRadius: true,
                }
              },
              datalabels: {
                display: true,
                anchor: 'center',
                align: 'center',
                formatter: (value: any) => `${value}%`,
                color: '#ffffff',
                font: {
                  size: 9,
                  weight: 'normal'
                }
                // }
              },
              tooltip: {
                callbacks: {
                  label: function (context: any) {
                    let label = context.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.raw !== null) {
                      label += context.raw + '%';
                    }
                    return label;
                  },
                },
              },
            },
          };
        }
      });
  }

  // destroyChart() {
  //   if (this.chart) {
  //     this.chart.destroy();
  //     this.chart = null;
  //   }
  // }

  // const containerBody = document.querySelector('containerBody');
  // const totalLabels(i) = mychart.data.barChartLabels.length;
  // if(totalLabels > 5){
  //   const newWidth = 1150 +((totalLabels - 5 )* 30);
  //   containerBody.style.width = `${newWidth}px`;
  // }

  stagesActiveTab: string = 'Brand';

  setActiveTab(tab: string) {
    this.stagesActiveTab = tab;
    this.GetStagesOverviewByCountry(this.mainObject, tab);
  }

  sourceHireActiveTab: string = 'Number';
  setHireActiveTab(tab: string) {
    this.sourceHireActiveTab = tab;
    this.GetSourceHire(this.mainObject, tab);
  }

  sourceHireActiveTab1: string = 'Number';
  setHireActiveTab1(tab: string) {
    this.sourceHireActiveTab1 = tab;
    this.GetSourceOfHireApplicantsCount(this.mainObject, tab);
  }

  positionsActiveTab: string = 'Brand';
  setPositionsActiveTab(tab: string) {
    this.positionsActiveTab = tab;
    this.GetOpenClosedPositions(this.mainObject, tab);
  }

  entityActiveTabAca: string = 'Brand'

  setEntityActiveTabAca(tab1: string) {
    this.entityActiveTabAca = tab1;
    this.GetAcademicNonAcademic(this.mainObject, tab1);
  }
  entityActiveTab: string = 'Brand';
  setEntityActiveTab(tab: string) {
    this.entityActiveTab = tab;
    this.GetNewReplacementEntity(this.mainObject, tab);
  }


  joinerActiveTab: string = 'Brand';

  setJoinerActiveTab(tab: string, method: string) {
    this.joinerActiveTab = tab;
    if (method === 'GetGlobalJoiners') {
      this.GetGlobalJoiners(this.mainObject);
    } else if (method === 'GetGlobalJoinersByCountry') {
      this.GetGlobalJoinersByCountry(this.mainObject);
    } else if (method === 'GetGlobalJoinersByCampus') {
      this.GetGlobalJoinersByCampus(this.mainObject);
    }
  }
}
