import { Router } from '@angular/router';
import { Component, Injector, OnInit, ViewChild, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { jobService } from 'src/app/services/jobservice';
import { commonService } from 'src/app/services/commonService';
import { NgForm } from '@angular/forms';
import { manageService } from '../services/managesetting.service';
import { AppComponentBase } from '../shared/app.component-base';
import { NotificationService } from '../services/notification.service';
import { userService } from '../services/userservice';
import { TrfService } from '../services/trf.service';
import { jwtDecode } from 'jwt-decode';


@Component({
  selector: 'app-jah',
  templateUrl: './jah.component.html',
  styleUrls: ['./jah.component.scss'],
})
export class JAHComponent extends AppComponentBase implements OnInit {
  @ViewChild('jahForm', { static: false }) jahForm: NgForm;
  jahDataa: any = {};
  jahObj: any = {};
  item: any;
  JAHTableArray: any = [];
  JahSearchh: any;
  jahLEvell: any;
  jahSubbLEvell: any;
  JAHLevels = false;
  blueCrossMark = false;
  showRecods = false;
  showLoader: boolean = true;

  editDisable = false;

  selectedJAHBrand: any;
  JAHBrand = [
    { id: 1, name: 'Brand 1' },
    { id: 2, name: 'Brand 2' },
    { id: 3, name: 'Brand 3' },
    { id: 4, name: 'Brand 4' },
  ];
  selectedJAHCountry: any;
  JAHCountry = [
    { id: 1, name: 'Country 1' },
    { id: 2, name: 'Country 2' },
    { id: 3, name: 'Country 3' },
    { id: 4, name: 'Country 4' },
  ];
  selectedJAHCampus: any;
  JAHCampus = [
    { id: 1, name: 'Campus 1' },
    { id: 2, name: 'Campus 2' },
    { id: 3, name: 'Campus 3' },
    { id: 4, name: 'Campus 4' },
  ];
  jobTitleId: any;
  JAHApproverJobTitle = [
    { id: 1, name: 'Title 1' },
    { id: 2, name: 'Title 2' },
    { id: 3, name: 'Title 3' },
    { id: 4, name: 'Title 4' },
  ];
  userId: any;
  approverListName = [
    { id: 1, name: 'Approver 1' },
    { id: 2, name: 'Name 2' },
    { id: 3, name: 'Uday Kumar' },
    { id: 4, name: 'Approver 4' },
  ];
  selectedJAHJobTitle: any;
  JAHJobTitle = [
    { id: 1, name: 'Telugu Teacher' },
    { id: 2, name: 'Maths Teacher' },
    { id: 3, name: 'English Teacher' },
    { id: 4, name: 'Physics Teacher' },
    { id: 5, name: 'Teacher' },
  ];
  jahData: any;
  jobTitle: any;
  approverList: any;
  userList: any;
  hierarchy: any = [];
  objectJob: any = [];
  value: any = [];

  campus_list: any;
  countryI: any;
  approverError = true;
  id: any;
  jobTitleName: any;
  userData: any;
  constructor(
    public jobService: jobService,
    public commonService: commonService,
    public router: Router,
    public trfservice: TrfService,

    public manageService: manageService,
    private notifyService: NotificationService,
    public userService: userService,
    private renderer: Renderer2,
    injector: Injector
  ) {
    super(injector);
    this.jahDataa = {};
    this.jahDataa.approverLists = [
      {
        jobTitleId: null,
        userId: null,
      },
    ];
  }

  paginationPageSize: number = 10;
  currentPage: number = 0;
  noOfPages: number = 0;
  v1: number;
  v2: number;
  pageNo: number = 0;
  pageRecords: number;
  totalRecords: number = 0;

  ngOnInit() {
    this.GetJobTitlewithlevels();
    this.GetUsersList();
    this.GetJobsLevelsData();
    this.GetJahPagination();
    this.GetMasterJobs()
    // console.log(this.hierarchy, "...........");

    // console.log(item.jobApprovalHierarchyName, "..............................")

    let userData = this.userService.getUserId();
    // this.userId = userData.userId;
    //alert(this.userId)
    this.userData = jwtDecode(userData)
    this.userId = this.userData.UserId

    this.GetCountryCampusByBrandId(this.userId)
  }
  GetUsersList() {
    this.jobService.GetUsersList().subscribe((res: any) => {
      // this.userList=res
      var jahArray = [];
      for (let i = 0; i < res.length; i++) {
        if (res[i].userStatus == true) {
          jahArray.push(res[i]);
        }
        this.userList = jahArray;
      }
    });
  }

  countryData: any;
  GetJobTitlewithlevels() {
    this.jobService.GetJobTitlewithlevels().subscribe((res: any) => {
      this.jahData = res;
    });
  }
  dataBrand: any;
  countryList: any = [];
  GetJobsByBrandId(event: any) {
    this.dataBrand = event;
    this.selectedJAHCountry = null;
    this.selectedJAHCampus = null;
    this.selectedJAHJobTitle = null;
    this.jahDataa.approverLists = [
      {
        jobTitleId: null,
        userId: null,
      },
    ]; // this.jobTitleId=null
    this.jobService.GetJobTitlewithlevels().subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        // console.log(res[i].brandId==this.dataBrand)
        if (res[i].brandId == this.dataBrand) {
          this.countryList = res[i].country;
        }
      }
    });
    // this.GetMasterJobs(this.dataBrand);
    this.approver(this.dataBrand);
  }

  GetMasterJobs() {
    this.selectedJAHCountry = null;
    this.selectedJAHCampus = null;
    // this.selectedJAHJobTitle=null
    this.commonService.GetJobNames().subscribe((res: any) => {
      this.jobTitle = res;
    });
  }

  jahJob: any;
  levelup(event: any) {
    this.selectedJAHCountry = null;
    this.selectedJAHCampus = null;
    // this.jahJob=event.jobTitleId
    // this.jahLEvell=event.level,
    // this.jahSubbLEvell=event.subLevel
  }
  levelInfo: any;
  GetJobsLevelsData() {
    this.manageService.GetJobsLevelsData().subscribe((res) => {
      this.levelInfo = res;
    });
  }

  getCampusInfo(event: any) {
    this.selectedJAHCampus = null;
    // this.jobTitleId=null
    this.jahDataa.approverLists = [
      {
        jobTitleId: null,
        userId: null,
      },
    ];
    this.JAHLevels = false;
    var country_Info: any = [];
    for (let i = 0; i < event.length; i++) {
      country_Info.push(event[i].countryId);
    }
    let country = country_Info;
    this.countryI = country;
    if (country.length > 0) {
      this.commonService.GetcampusByid(country).subscribe((res: any) => {
        this.campus_list = res;
      });
    }
    this.approver(this.dataBrand);
  }

  campusIndoData(selectedJAHCountry) {
    this.commonService
      .GetcampusByid(selectedJAHCountry)
      .subscribe((res: any) => {
        this.campus_list = res;
      });
  }

  approver(brandId: any) {
    this.approverList = [];
    // if (countryId.length > 0) {
      this.jobService
        .GetUserListByBrandCountryCampus(brandId)
        .subscribe((res: any) => {
          this.approverList = res;
        });
    // }
  }

  valnewarra: any; newbrandarray = []
  GetCountryCampusByBrandId(val) {

    this.trfservice.GetBrandDetailsByUserId(parseInt(val))
      .subscribe((res: any) => {
        this.newbrandarray = res
        this.valnewarra = res
        // this.newbrandarray.push(this.valnewarra);


      })

  }

  addFieldss() {
    var list: any = [];
    list = this.approverList;
    for (let i = 0; i < this.jahDataa.approverLists.length; i++) {
      var userId = this.jahDataa.approverLists[i].userId;
      for (let k = 0; k < userId.length; k++) {
        // console.log(userId[k])
        for (let j = 0; j < list.length; j++) {
          if (list[j].userId == userId[k]) {
            var idToRemove = list[j].userId;
            list = list.filter((item) => item.userId !== idToRemove);
            // console.log(list)
          }
        }
      }
    }
    this.approverList = [];
    let obj = {
      jobTitleId: null,
      userId: null,
      approverList: [],
    };

    this.jahDataa.approverLists.push(obj);
    this.approverList = list;
    this.JAHLevels = true;
  }
  deleteFieldss(i) {
    console.log(this.jahDataa.approverLists);
    this.jahDataa.approverLists.splice(i, 1);
  }

  deleteJaHTableRow(i: any) {
    this.JAHTableArray.splice(i, 1);
  }
  jobSearch() {
    this.pageNo=0
    console.log(this.JahSearchh,"================")
    if (this.JahSearchh && this.JahSearchh.length >= 2) {
      this.hierarchy=[]
      this.searchJah()
    }else{
      this.searchJah()
    }
    if (this.JahSearchh?.length > 0) {
      this.blueCrossMark = true;
      this.showRecods = true;
    } else {
      this.blueCrossMark = false;
      this.showRecods = false;
    }
    // let pagination = this.paginationPageSize;
    // let pageNumber = this.pageNo;
    // this.jobService.JobApprovalHierarchySearch(data,this.userId,pageNumber,pagination).subscribe((res:any)=>{

    // })

    // let newHierachyList = []
    // if (data.currentTarget.value) {

    //   let searchValue = data.currentTarget.value;
    //   if (searchValue.length >= 2) {
    //     var list = this.hierarchy.forEach(x => {
    //       let jobTitle: any = x.jobTitle;
    //       let title = jobTitle.slice(0, searchValue.length)
    //       if (title === searchValue) {
    //         newHierachyList.push(x)
    //       }
    //     }
    //     )
    //   } else {
    //     this.GetJahPagination();
    //   }

    //   if (newHierachyList.length > 0) {
    //     this.hierarchyLength = newHierachyList.length;
    //     this.hierarchy = newHierachyList
    //   }
    //   if (newHierachyList.length == 0) {
    //     this.GetJahPagination();

    //   }

    // } else {
    //   this.GetJahPagination();
    // }
  }
  searchJah() {
    let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.hierarchy=[]
    this.showLoader = true;
    this.jobService.JobApprovalHierarchySearch(this.JahSearchh,this.userId,pageNumber,pagination).subscribe((res:any)=>{
      if (res) {
        this.showLoader = false;
        this.hierarchy = res;
      //   this.hierarchyLength = res.length
      // }, 2000);
      if (res[0] && res[0].totalCount) {
        this.totalRecords = res[0].totalCount;
      } else {
        this.totalRecords = 0;
      }
      this.noOfRecords(this.pageNo);
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
      }
     })
  }

  jahhCancel() {
    this.jahDataa.approverLists = [];
    this.JAHLevels = false;
    let obj = {
      jobTitleId: null,
      userId: null,
    };
    this.jahDataa.approverLists.push(obj);
    this.editDisable = false;
    this.id = null;
    this.jahForm.form.reset();
  }

  showCross() {
    if (this.JahSearchh?.length > 0) {
      this.blueCrossMark = true;
      this.showRecods = true;
    } else {
      this.blueCrossMark = false;
      this.showRecods = false;
    }
  }
  removeValue() {
    this.JahSearchh = '';
    this.blueCrossMark = false;
    this.GetJahPagination();
    this.hierarchyLength = this.hierarchy.length;
  }

  // commaSeperated(data){
  // return data.replace(/,/g, ' \n');
  // var nameArr = data.split(',');
  // var obj=nameArr.join('\n');
  // return obj;
  // console.log(obj, "...............");
  // }
  commaSeperated(data) {
    // let newLine = data.replace(/,/g, '\n')
    // return  newLine;
    var nameArr = data.split(',');
    var obj = nameArr;
    return obj;
  }

  getcomma(data) {
    var arrayObj: any = [];
    // var newString = data.split(',')
    const modifiedArray = this.JAHTableArray.replace(/,/g, 'n');
    // console.log(modifiedArray, "//////////////////////////////////////")

    // var nameArr = data.split(',');
    // var obj=nameArr.join('\n')
    // arrayObj.push(obj)
    // return arrayObj
  }

  // disableJahSubmit(){
  //   if(this.selectedJAHBrand=''){

  //   }
  // }

  GetJobApprovalHierarchy(){
    this.jobService.GetJobApprovalHierarchy(this.userId).subscribe((res: any) => {
    this.hierarchy = res;
    });
  }

  hierarchyLength: any
  GetJahPagination() {
    let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.jobService.GetJahPagination(this.userId,pageNumber,pagination).subscribe((res: any) => {
      // setTimeout(() => {
      //   this.showLoader = false;
      if (res) {
        this.hierarchy = res;
      //   this.hierarchyLength = res.length
      // }, 2000);
      if (res[0] && res[0].totalCount) {
        this.totalRecords = res[0].totalCount;
      } else {
        this.totalRecords = 0;
      }
      this.noOfRecords(this.pageNo);
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
      }
    });
  }


  noOfRecords(currentPage) {
    this.v1 = currentPage * this.paginationPageSize + 1;
    this.v2 = (currentPage + 1) * this.paginationPageSize;
    this.pageNo = currentPage;
    // tslint:disable-next-line:max-line-length
    this.pageRecords =
      this.totalRecords % this.paginationPageSize === 0
        ? this.totalRecords / this.paginationPageSize
        : Math.floor(this.totalRecords / this.paginationPageSize) + 1;

    if (this.totalRecords == 0) {
      this.v1 = 0;
    }
    if (this.totalRecords < this.v2) {
      this.v2 = this.totalRecords;
    }
  }
  paginationButtonDisabled: boolean =false;
  previous() {
    this.pageNo = --this.pageNo;
    this.paginationButtonDisabled = true;
      setTimeout(() => {
        this.paginationButtonDisabled = false;
      }, 1000);
    if (this.JahSearchh!=undefined) {
      this.searchJah()
    }else{
      this.GetJahPagination();
    }
  }
  next() {
    this.pageNo++;
    this.paginationButtonDisabled = true;
      setTimeout(() => {
        this.paginationButtonDisabled = false;
      }, 1000);
    if (this.JahSearchh!=undefined) {
      this.searchJah()
    }else{
      this.GetJahPagination();
    }
    //  this.noOfRecords(this.pageNo);
  }

  popup: any;
  public isDisabled: boolean = false;
  submitJAH() {
    this.pageNo=0
    window.scrollTo(0, 0);
    if (this.jahForm.form) {
      if (this.id) {
        this.isDisabled = true;
        var arrayinfo = [];
        var arrayList = this.objectValue;
        for (let i = 0; i < arrayList.length; i++) {
          let arayobj = {
            approverListId: arrayList[i].approverListId,
            jobApprovalHierarchyId: arrayList[i].jobApprovalHierarchyId,
            jobTitleId: arrayList[i].jobTitleId,
            userId: arrayList[i].userId.toString(),
          };
          arrayinfo.push(arayobj);
        }
        let valueData = {
          brand: this.selectedJAHBrand,
          country: this.selectedJAHCountry?.toString(),
          campus: this.selectedJAHCampus?.toString(),
          approverLists: arrayinfo,
        };
        let params = {
          jobApprovalHierarchyId: this.id,
          jobTitle: this.selectedJAHJobTitle?.toString(),
          jahJobtitleDatas: valueData,
        };
        if (this.campusinfo?.length > 0) {
          this.popup = this.campusinfo.toString();
          this.JahSubmitMOdal = true;
        } else {
          this.jobService
            .AddJobApprovalHierarchy(params)
            .subscribe((res: any) => {
              this.GetMasterJobs()
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 200);
              // console.log(res, "..........////////////...................");
              setTimeout(() => {
                if (res) {
                  this.notifyService.showSuccess(
                    '',
                    'JAH is Updated Successfully'
                  );
                }
              }, 1000);

              this.isDisabled = false;
              (this.selectedJAHBrand = null),
                (this.selectedJAHCountry = null),
                (this.selectedJAHCampus = null),
                (this.selectedJAHJobTitle = null),
                (this.jobTitle = []);
              this.countryList = [];
              this.campus_list = [];
              this.approverList = [];
              this.objectJob = [];
              this.value = this.jahDataa.approverLists;
              for (let i = 0; i < this.value.length; i++) {
                this.jahDataa.approverLists[i].jobTitleId = [];
                this.jahDataa.approverLists[i].userId = [];
              }
              this.jahDataa.approverLists = [];
              this.JAHLevels = false;
              let obj = {
                jobTitleId: null,
                userId: null,
              };

              this.jahDataa.approverLists.push(obj);
              let validationDescription = this.jahForm.form.controls;
              for (let keyVar in validationDescription) {
                validationDescription[keyVar].markAsDirty();
                validationDescription[keyVar].clearValidators();
              }
              this.GetJahPagination();
            });
        }
      }
      if (!this.id) {
        this.isDisabled = true;
        this.objectJob = [];
        var data = this.jahDataa.approverLists;
        for (let i = 0; i < data.length; i++) {
          delete data[i].approverList;
          let obj = {
            jobTitleId: data[i].jobTitleId,
            userId: data[i].userId.toString(),
          };
          this.objectJob.push(obj);
        }
        let valueObj = {
          brand: this.selectedJAHBrand,
          country: this.selectedJAHCountry?.toString(),
          campus: this.selectedJAHCampus?.toString(),
          approverLists: this.objectJob,
        };

        let params = {
          jobTitle: this.selectedJAHJobTitle?.toString(),
          jahJobtitleDatas: valueObj,
          status: true,
        };

        if (this.campusinfo?.length > 0) {
          this.popup = this.campusinfo.toString();
          this.JahSubmitMOdal = true;
        } else {
          this.jobService
            .AddJobApprovalHierarchy(params)
            .subscribe((res: any) => {
              this.isDisabled = false;
              this.GetMasterJobs()
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 200);
              setTimeout(() => {
                if (res) {
                  this.notifyService.showSuccess(
                    '',
                    'JAH is Created Successfully'
                  );
                }
              }, 1000);
              (this.selectedJAHBrand = null),
                (this.selectedJAHCountry = null),
                (this.selectedJAHCampus = null),
                (this.selectedJAHJobTitle = null),
                (this.jobTitle = []);
              this.countryList = [];
              this.campus_list = [];
              this.approverList = [];
              this.objectJob = [];
              this.value = this.jahDataa.approverLists;
              for (let i = 0; i < this.value.length; i++) {
                this.jahDataa.approverLists[i].jobTitleId = [];
                this.jahDataa.approverLists[i].userId = [];
              }
              this.jahDataa.approverLists = [];
              this.JAHLevels = false;
              let obj = {
                jobTitleId: null,
                userId: null,
              };

              this.jahDataa.approverLists.push(obj);
              let validationDescription = this.jahForm.form.controls;
              for (let keyVar in validationDescription) {
                validationDescription[keyVar].markAsDirty();
                validationDescription[keyVar].clearValidators();
              }
              this.GetJahPagination();
            });
          // setTimeout(() => {
          //   if (this.id == true) {
          //     this.notifyService.showSuccess(
          //       '',
          //       'JAH is created Successfully'
          //     );
          //   }
          // }, 1000);
        }
      }

      this.approverError = false;
      this.editDisable = false;
    }
  }

  showJobStatus(data) { }
  JahSubmitMOdal = false;
  showJahPopup() {
    // this.JahSubmitMOdal = true;
  }
  JAHModalClose() {
    this.JahSubmitMOdal = false;
    this.isDisabled = false;
  }
  campusinfo: any;
  jahVid:any
  campusValidate(selectedJAHCampus: any) {
    var brand = this.selectedJAHBrand;
    var campus = selectedJAHCampus?.toString();
    var jobTitle = this.selectedJAHJobTitle?.toString();
    if (campus != '') {
      if (!this.id) {
         this.jahVid=0
      }else{
         this.jahVid=this.jobApprovalHierarchyId
      }
      this.jobService
        .ValidateJahJobTitleOnCampusjobtitle(brand, jobTitle, campus,this.jahVid)
        .subscribe((res: any) => {
          this.campusinfo = [];
          for (let i = 0; i < res.length; i++) {
            this.jobTitleName = res[i].jobTitleName;
            const element = res[i].campusName;
            this.campusinfo.push(element);
          }
        });
    }
  }

  showAllKebabMenu(i: any) {
    document.getElementById(`allKeebabbMenuu${i}`).style.display = 'block';
  }

  noAllKebabMenu(i: any) {
    document.getElementById(`allKeebabbMenuu${i}`).style.display = 'none';
    // console.log('clickedddd....');

  }
  
  currentOpenedMenu: HTMLElement | null = null;
  documentClickListener: () => void;
  ngAfterViewInit() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event: Event) => {
        this.onDocumentClick(event);
      });
    }
  }

  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the kebab menu
    const clickedInside = targetElement.closest('.all__kebab__menuu') || targetElement.closest('.three-dots-button');
    if (!clickedInside) {
      this.hideAllKebabMenu();
    }
  }

  hideAllKebabMenu() {
    if (this.currentOpenedMenu) {
      this.currentOpenedMenu.style.display = 'none';
      this.currentOpenedMenu = null;
    }
  }

  ngOnDestroy() {
    // Remove the document click listener when the component is destroyed
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  showAllKebabMenuu(i: number) {
    const element = document.getElementById(`aallKeebabbMenuu${i}`);
    if (element) {
      if (this.currentOpenedMenu && this.currentOpenedMenu !== element) {
        this.currentOpenedMenu.style.display = 'none';
      }
      element.style.display = 'block';
      this.currentOpenedMenu = element;
    }
  }

  noAllKebabMenuu(i: number) {
    const element = document.getElementById(`aallKeebabbMenuu${i}`);
    if (element) {
      element.style.display = 'none';
    }
  }
  responce: any;
  valueInfo: any;
  MainArr: any = [];
  objectValue: any = [];
  jobApprovalHierarchyId:any
  editJahbyId(id: any) {
    this.editDisable = true;
    this.id = id;
    this.jahDataa.approverLists = [];
    this.objectValue = [];
    let obj = {
      jobTitleId: null,
      userId: null,
    };
    this.jahDataa.approverLists.push(obj);
    this.commonService
      .GetJobApprovalHierarchyById(this.id)
      .subscribe((res: any) => {
        var jobHierarchy = res.jobApprovalHierarchy;
        // this.responce=jobHierarchy.jahJobtitleDatas.campus
        this.jobApprovalHierarchyId=jobHierarchy.jobApprovalHierarchyId
        this.selectedJAHBrand = jobHierarchy.jahJobtitleDatas.brand;
        var countries = this.jahData.filter(
          (x) => x.brandId == jobHierarchy.jahJobtitleDatas.brand
        );
        if (countries && countries[0].brandId) {
          this.GetJobsByBrandId(countries[0].brandId);
        }
        var jobTitleIds = [];
        var jobtitleId = jobHierarchy.jobTitle?.split(',');

        if (jobtitleId?.length > 0) {
          jobtitleId = jobtitleId.forEach((element) => {
            jobTitleIds.push(parseInt(element));
          });
          this.selectedJAHJobTitle = jobTitleIds;
        }
        var countryIds = [];
        var countryId = jobHierarchy.jahJobtitleDatas.country?.split(',');

        if (countryId.length > 0) {
          countryId = countryId.forEach((element) => {
            countryIds.push(parseInt(element));
          });
          this.selectedJAHCountry = countryIds;
        }
        this.campusIndoData(this.selectedJAHCountry);
        var campusIds = [];
        var campusId = jobHierarchy.jahJobtitleDatas.campus?.split(',');

        if (campusId.length > 0) {
          campusId = campusId.forEach((element) => {
            campusIds.push(parseInt(element));
          });
          this.selectedJAHCampus = campusIds;
        }
        this.approver(this.selectedJAHBrand);
        var data = jobHierarchy.jahJobtitleDatas.approverLists;
        for (let i = 0; i < data.length; i++) {
          var userDataInfo = [];
          var userInfo = data[i].userId.split(',');
          if (userInfo.length > 0) {
            userInfo = userInfo.forEach((element) => {
              userDataInfo.push(parseInt(element));
            });
            this.valueInfo = userDataInfo;
          }
          let obj = {
            jobTitleId: parseInt(data[i].jobTitleId),
            userId: this.valueInfo,
          };
          this.objectValue.push(obj);
        }
        this.jahDataa.approverLists = this.objectValue;
        this.JAHLevels = true;
        // var tempObj={
        //   jobtitle : this.selectedJAHJobTitle,
        //   campus : this.selectedJAHCampus
        // }
        // this.MainArr.push(tempObj)
        // console.log(this.MainArr)
      });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  showLoadeerr = false;
  deActivateButton = false;

  activeJahbyId(id, status) {

    this.deActivateButton = true;

    setTimeout(() => {
      this.deActivateButton = false;
    }, 3000);

    this.showLoadeerr = true;

    this.showLoadeerr = false;
    this.jobService.DeleteJah(id, status).subscribe((res: any) => {
      this.GetJahPagination();
      setTimeout(() => {
        if (status == true) {
          this.notifyService.showSuccess(
            '',
            'JAH is activated Successfully'
          );
        }
        else {
          this.notifyService.showSuccess(
            '',
            'JAH is de-activated Successfully'
          );
        }
      }, 1000);
    });

    // document.getElementById(`allKeebabbMenuu`).style.display = 'none';
  }

  showEligibilityInfo() {
    document.getElementById(`eligibilityinfoVisible`).style.display = 'block';
  }
  showEligibilityInvisible() {
    document.getElementById(`eligibilityinfoVisible`).style.display = 'none';
  }
  listData: any
  listUsers(data) {
    var list = data.split(',')
    if (list.length == 1) {
      this.listData = ''
    } else {
      var len = list.length - 1
      this.listData = "+" + len
    }
    return list[0]
  }

}
